import { useQuery } from "@apollo/client";
import {
  CircularProgress,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { OpenInNewOutlined } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import React from "react";
import { FETCH_FORMS } from "./events.graphql";
import moment from "moment";
import { useAuthedAxios } from "../../../../util/axios";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import UENDialog from "../../../../components/UENDialog/UENDialog";

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function Forms() {
  const [showDialog, setShowDialog] = React.useState(false);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const currentMember = useSelector((state) => state.auth.user.member);
  const organizationID = currentMember.organization;

  const { loading, data, error } = useQuery(FETCH_FORMS, {
    variables: {
      endDate: moment().toISOString().split("T")[0],
    },
  });

  const [{ data: organization }] = useAuthedAxios(
    `/organizations/${organizationID}`
  );

  const onViewClick = (slug) => {
    if (!organization) {
      enqueueSnackbar(
        "There was some internal server error while processing your request. Please try again later!",
        {
          variant: "error",
          autoHideDuration: 1000,
        }
      );
    } else {
      if (!organization.company_registration_no) {
        setShowDialog(true);
      } else window.open(`/form/${slug}`, "_blank");
    }
  };

  return (
    <>
      <div style={{ margin: "10px 0px" }}>
        {loading && (
          <div style={{ padding: "20px 0", textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </div>
        )}
        {!loading && error && (
          <Alert severity="error">
            There was some internal server error while fetching the forms.
            Please try again later!
          </Alert>
        )}
        {data && data.forms.length === 0 && (
          <Alert severity="info">No forms found!</Alert>
        )}
        {data && data.forms.length > 0 && (
          <TableContainer component={Paper} elevation={0}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell width={"10%"}>ID</StyledTableCell>
                  <StyledTableCell width={"40%"}>Title</StyledTableCell>
                  <StyledTableCell width={"20%"}>Start Date</StyledTableCell>
                  <StyledTableCell width={"20%"}>End Date</StyledTableCell>
                  <StyledTableCell>Register Here</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.forms.map((form, index) => (
                  <StyledTableRow key={form.id}>
                    <StyledTableCell component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell>{form.title}</StyledTableCell>
                    <StyledTableCell>
                      {moment(form.start_date).format("DD MMMM YYYY")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {moment(form.end_date).format("DD MMMM YYYY")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        color="secondary"
                        aria-label="view form"
                        onClick={() => onViewClick(form.slug)}
                      >
                        <OpenInNewOutlined />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <UENDialog open={showDialog} onClose={() => setShowDialog(false)} />
    </>
  );
}

export default Forms;
