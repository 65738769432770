import { gql } from "@apollo/client";

export const FETCH_HIGHTLIGHT_FORMS = gql`
  query ($isHighlight: String) {
    forms: formBuilders(
      where: { status: "Publish", isHighlight: $isHighlight }
    ) {
      id
      title
      slug
      start_date
      end_date
    }
  }
`;
