import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Grid,
  Paper,
  LinearProgress
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import urlMetadata from "url-metadata";

import { usePublicAxios } from "../../../../util/axios";

import PollContent from "./PollContent";
import FormRenderer from "./FormRenderer/renderer";
// import PollMetadata from "./PollMetadata";
import Login from "../../../Auth/Login/login";

function PollDisplay() {
  const { slug } = useParams();
  const user = useSelector((state) => state.auth.user);
  const [isPollSubmitted, setIsPollSubmitted] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [metaData, setMetaData] = useState(null);

  const isPollSubmittedFromLocalSlug = localStorage.getItem(`${slug}`);

  const [{ loading: loadingPoll, data: pollData, error: errorPoll }, getPoll] =
    usePublicAxios(
      {
        url: `/poll-builders/getPoll/${slug}`,
        method: "GET"
      },
      {
        manual: true
      }
    );

  useEffect(() => {
    getPoll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPollSubmitted]);

  useEffect(() => {
    getMetaDataFromURL();
    if (pollData && user) {
      checkPollSubmission();
    }
    if (user) {
      checkPollSubmission();
    }
    if (pollData) setTotalCount(getTotalCount());
    if (
      pollData &&
      pollData.available_to === "public" &&
      !user &&
      isPollSubmittedFromLocalSlug
    ) {
      setIsPollSubmitted(true);
      setTotalCount(getTotalCount());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollData, isPollSubmitted, isPollSubmittedFromLocalSlug]);

  const checkPollSubmission = () => {
    if (pollData && pollData.submissions.length > 0) {
      pollData.submissions.map((item) =>
        parseInt(item.member) === user.member.id
          ? setIsPollSubmitted(true)
          : null
      );
      // for(var i=0; i<pollData.submissions.length; i++){
      //     if(parseInt(pollData.submissions[i]) === user.member.id){
      //         setIsPollSubmitted(true)
      //         break;
      //     }

      // }
    }
  };

  function getCount(id) {
    let count = 0;
    pollData &&
      pollData.submissions.length &&
      pollData.submissions.map((subm) =>
        subm.submission[id] ? count++ : null
      );

    return count;
  }

  function getTotalCount() {
    let totalCount = 0;
    pollData.form.map(({ id }) => (totalCount += getCount(id)));

    return totalCount;
  }

  function getMetaDataFromURL() {
    if (pollData && pollData.description) {
      const urlRegex = /(https?:\/\/[^ ]*)/;

      const inputString = pollData.description;
      const url = inputString.match(urlRegex) && inputString.match(urlRegex)[1];
      const config = {
        headers: { "Access-Control-Allow-Origin": "*" }
      };
      url &&
        urlMetadata(url, config).then(
          function (metadata) {
            // success handler
            setMetaData(metadata);
          },
          function (error) {
            // failure handler
            console.log(error);
          }
        );
    }
  }

  function getPollSubmission() {
    setIsPollSubmitted(true);
  }
  return (
    <>
      {/* <PollMetadata pollData={pollData} /> */}
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ padding: "3rem" }}
      >
        <Grid
          item
          sm={8}
          xs={12}
          md={5}
          style={{
            textAlign: "center"
          }}
        >
          {pollData && user && (
            <>
              <img src="/images/logo.png" alt="ASME Logo" width={"100px"} />
              <br />
              <br />
            </>
          )}
          <h2 style={{ marginBottom: "0", fontSize: "xx-large" }}>
            ASME BUSINESS POLLS
          </h2>
          <br />
          {!loadingPoll && (errorPoll || !pollData) && (
            <>
              <Alert severity="error">Poll Not Available</Alert>
              <br />
            </>
          )}
          <Paper elevation={3} style={{ padding: "10px 30px" }}>
            {loadingPoll && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress color="secondary" />
              </div>
            )}
            {pollData && (
              <div style={{ textAlign: "left" }}>
                <PollContent pollData={pollData} metaData={metaData} />
                {isPollSubmitted ? (
                  <Grid container>
                    <form style={{ width: "100%" }}>
                      {pollData.form.map((item) => (
                        <Grid
                          item
                          xs={12}
                          key={item.id}
                          style={{ padding: "10px 0", position: "relative" }}
                        >
                          {item.settings.title}
                          <LinearProgress
                            style={{
                              marginRight: "1rem"
                            }}
                            variant="determinate"
                            value={
                              totalCount
                                ? (getCount(item.id) * 100) / totalCount
                                : 0
                            }
                          />
                          <span className="poll-percentage">
                            {totalCount
                              ? parseInt((getCount(item.id) * 100) / totalCount)
                              : 0}
                            %
                          </span>
                        </Grid>
                      ))}
                    </form>
                  </Grid>
                ) : (
                  pollData.form && (
                    <FormRenderer
                      form={pollData.form}
                      id={pollData.id}
                      isPoll={true}
                      allowMultipleSelect={pollData.allow_multiple_select}
                      getPollSubmission={getPollSubmission}
                      isPublicPoll={
                        pollData.available_to === "public" ? true : false
                      }
                    />
                  )
                )}
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>

      {pollData && !user && (
        <div style={{ alignItems: "center" }}>
          <Login isPoll />
        </div>
      )}
    </>
  );
}

export default PollDisplay;
