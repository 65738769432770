import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  TextField,
  makeStyles,
  IconButton,
  Select as MSelect,
  InputLabel,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
  FormGroup,
  Button,
  Modal,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Link,
  Backdrop,
  Snackbar,
  Paper
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { MOBILE_BREAKPOINT } from "../../../app/constants";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { Controller, useForm } from "react-hook-form";
import { components } from "react-select";
import IHLData from "./IHL/IHL.json";

import { MultipleSelect } from "react-select-material-ui";
import { DatePicker } from "@material-ui/pickers";
import { axios, usePublicAxios } from "../../../util/axios";
import useAxios from "axios-hooks";
import Welcome from "../Welcome/Welcome";
import { Alert } from "@material-ui/lab";
import { useLocation } from "react-router-dom";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import InfoIcon from "@material-ui/icons/Info";

const queryString = require("query-string");

const groupedIndustryData = Object.keys(IHLData.industry).map((group) => ({
  label: group,
  options: IHLData.industry[group].map((industry) => ({
    value: group + " | " + industry,
    label: industry
  }))
}));

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "80%",
    marginLeft: 0
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  paper: {
    position: "absolute",
    // width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    border: "none"
  },
  membershipPaper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flex: 1,
    backgroundColor: "#002E5D",
    borderRadius: 0
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

function getModalStyle() {
  const top = 15;
  // const left = 50;

  return {
    top: `${top}%`,
    margin: "auto",
    // left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
    outline: "none"
  };
}

function Registration() {
  const { search } = useLocation();
  const classes = useStyles();
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(size.width <= MOBILE_BREAKPOINT);
  const [modalStyle] = React.useState(getModalStyle);
  const { enqueueSnackbar } = useSnackbar();

  const queryParams = queryString.parse(search);

  const [organizationName, setOrganizationName] = useState(
    queryParams.org || ""
  );
  const [yearOfIncorporation, setYearOfIncorporation] = useState(
    queryParams.year ? new Date(queryParams.year) : null
  );
  const [isModalVisible, setModalVisibility] = useState(false);
  const [isPlanModalVisible, setPlanModalVisibility] = useState(false);
  const [isCompanySelected, setIsCompanySelected] = useState(false);
  const [hasOrganizationInformation, setHasOrganizationInformation] =
    useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [showRegistrationError, setShowRegistrationError] = useState(false);

  const { handleSubmit, register, errors, setError, control, setValue, reset } =
    useForm({
      defaultValues: {
        salutation: queryParams.salutation
          ? queryParams.salutation.substring(
              0,
              queryParams.salutation.length - 1
            )
          : "Dr",
        citizenship: queryParams.citizenship
          ? queryParams.citizenship
          : "Singaporean",
        annual_turnover: queryParams.annual_turnover,
        legal_structure: queryParams.legal_structure,
        nature_of_business: queryParams.business_nature,
        ownership_type: queryParams.ownership_type,
        paid_up_capital: queryParams.paid_up_capital,
        staff_strength: queryParams.staff_strength
      }
    });

  const [{ data, loading }, fetchOrganization] = useAxios(
    {
      baseURL: "https://bizsearch.asme.org.sg"
    },
    { manual: true }
  );

  const [{ data: companyData, loading: loadingCompany }, fetchCompanyDetails] =
    useAxios(
      {
        baseURL: "https://bizsearch.asme.org.sg"
      },
      { manual: true }
    );

  const [
    { data: registerOrgData, loading: registering, error: registrationError },
    registerOrganization
  ] = usePublicAxios("/organizations/register", { manual: true });

  const { data: memberships } = useSWR("/memberships", (url) =>
    axios(url).then((res) => res.data)
  );

  useEffect(() => {
    setIsMobile(size.width <= MOBILE_BREAKPOINT);
  }, [size.width]);

  useEffect(() => {
    if (companyData) {
      setFormValues(companyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData]);

  useEffect(() => {
    if (registrationError) {
      setShowRegistrationError(true);
    }
  }, [registrationError]);

  const onSubmit = (data) => {
    if (!data.c1) {
      setError("c1", { type: "focus" }, { shouldFocus: true });
      return document.getElementById("TandC").scrollIntoView();
    }

    if (
      !data?.industry_classification ||
      !data?.industry_classification?.length
    ) {
      setError(
        "industry_classification",
        { type: "focus" },
        { shouldFocus: true }
      );
      return document.getElementById("industryClassification").scrollIntoView();
    }

    if (!selectedPlan)
      return enqueueSnackbar("Please select a membership plan", {
        variant: "error"
      });
    if (selectedPlan !== "Associate") {
      if (data.staff_strength > 200) {
        if (data.annual_turnover > 100000000)
          return setPlanModalVisibility(true);
      }
      if (data.annual_turnover > 100000000) {
        if (data.staff_strength > 200) return setPlanModalVisibility(true);
      }
    }

    const formData = {
      ...data,
      name: organizationName,
      year_of_incorporation:
        yearOfIncorporation && yearOfIncorporation instanceof Date
          ? yearOfIncorporation.toISOString()
          : null,
      registration_type: companyData ? companyData.Registration_Type || "" : "",
      status: companyData ? companyData.Status || "" : "",
      membership_type: selectedPlan //TODO: add Select Plan option in Registration Page
    };

    const {
      salutation,
      designation,
      first_name,
      last_name,
      email,
      mobile_no,
      citizenship,
      ...organization
    } = formData;

    const member = {
      salutation,
      designation,
      first_name,
      last_name,
      email,
      mobile_no,
      citizenship,
      source: queryParams.src ? queryParams.src : ""
    };

    if (!registerOrgData)
      registerOrganization({
        method: "POST",
        data: {
          member,
          organization,
          referral: queryParams.referral ? queryParams.referral : undefined
        }
      });
  };

  const handleClose = () => {
    setModalVisibility(false);
  };

  const handleClosePlanModal = () => {
    setPlanModalVisibility(false);
  };

  const onSearch = () => {
    // Open Popup Modal
    setModalVisibility(true);
    fetchOrganization({
      url: `/api/BusinessDirectories/search/${organizationName}`
    });
  };

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ? (
            <ArrowDropUpIcon
              style={{ color: "rgba(0, 0, 0, 0.54)", marginRight: "-8px" }}
            />
          ) : (
            <ArrowDropDownOutlinedIcon
              style={{ color: "rgba(0, 0, 0, 0.54)", marginRight: "-8px" }}
            />
          )}
        </components.DropdownIndicator>
      )
    );
  };

  const setFormValues = (data) => {
    const date = data.Date_Incorporation_Registration
      ? data.Date_Incorporation_Registration.split(" ").slice(0, 3).join(" ")
      : null;

    setYearOfIncorporation(date ? new Date(date) : null);

    if (data.name) {
      setOrganizationName(data.name);
    }

    setValue("company_registration_no", data.RegistrationNo || "");
    setValue("website", data.Website || "");
    setValue(
      "principal_business_activity",
      data.Principal_Business_Activity || ""
    );
    setValue(
      "secondary_business_activity",
      data.Secondary_Business_Activity || ""
    );
    setValue("company_description", data.Description || "");

    setValue("address", data.Registered_Address || "");
    setValue("phone", data.Contact_Number || "");
    // setValue("email", data.Email_Address || "");
  };

  const onCompanySelect = (company) => {
    const { id } = company;

    setModalVisibility(false);
    setIsCompanySelected(true);
    setHasOrganizationInformation(true);

    fetchCompanyDetails({
      url: `/api/BusinessDirectories/search/${id}/detail`
    });
  };

  const onCompanyNotInList = () => {
    setModalVisibility(false);
    setIsCompanySelected(false);
    reset();
    setYearOfIncorporation(null);
    setHasOrganizationInformation(true);
  };

  const renderPaidMemberships = (memberships) => {
    return (
      memberships &&
      memberships.length > 0 &&
      memberships
        .filter((membership) => membership.name !== "Lite")
        .sort((a, b) => a.annual_price - b.annual_price)
    );
  };

  return (
    <div>
      {!registerOrgData && (
        <>
          <Container maxWidth="md" style={{ marginTop: "30px" }}>
            <Header isMobile={isMobile} title="Membership Application Form" />
            {/* Form */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <h2>Organisation Information</h2>
              <Grid
                style={{
                  marginBottom: "30px"
                }}
                item
                xs={12}
                sm={6}
              >
                <Grid container alignItems="flex-end">
                  <TextField
                    className={classes.formControl}
                    label="Organisation Name"
                    InputProps={{
                      onChange: (e) => setOrganizationName(e.target.value)
                    }}
                    value={organizationName}
                    required
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        // Do code here
                        ev.preventDefault();
                        onSearch();
                      }
                    }}
                  />
                  <Button
                    title={
                      !organizationName
                        ? "Please type organziation name first!"
                        : "Search Organization"
                    }
                    disabled={!organizationName}
                    onClick={onSearch}
                    variant="contained"
                    color="secondary"
                  >
                    {/* <Search /> */}
                    Next
                  </Button>
                </Grid>
              </Grid>

              <Grid>
                <Grid container spacing={3}>
                  <Grid lg={6} sm={12} xs={12} item>
                    <TextField
                      InputLabelProps={{
                        className:
                          companyData &&
                          companyData.RegistrationNo &&
                          isCompanySelected
                            ? "MuiInputLabel-shrink"
                            : ""
                      }}
                      inputRef={register({
                        required: "UEN cannot be empty!",
                        pattern: {
                          value: /^[A-Z0-9]*$/,
                          message:
                            "Please enter a valid company registration number!"
                        }
                      })}
                      name="company_registration_no"
                      label={"Company Registration No. (UEN)*"}
                      error={
                        errors.company_registration_no &&
                        errors.company_registration_no.message
                          ? true
                          : false
                      }
                      helperText={
                        errors.company_registration_no
                          ? errors.company_registration_no.message
                          : null
                      }
                      style={{ width: "90%" }}
                      defaultValue={queryParams.uen}
                    />
                  </Grid>
                  <Grid lg={6} sm={12} xs={12} item>
                    <DatePicker
                      onChange={(date) => setYearOfIncorporation(date)}
                      value={yearOfIncorporation}
                      label="Year of Incorporation"
                      style={{ width: "90%" }}
                      format="DD MMMM YYYY"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid lg={6} sm={12} xs={12} item>
                    <TextField
                      inputRef={register({
                        required: "Address cannot be empty!",
                        min: {
                          value: 1,
                          message: "Please enter a valid Address"
                        }
                      })}
                      InputLabelProps={{
                        className:
                          companyData &&
                          companyData.Registered_Address &&
                          isCompanySelected
                            ? "MuiInputLabel-shrink"
                            : ""
                      }}
                      name="address"
                      label="Address"
                      multiline
                      required
                      rows={4}
                      rowsMax={4}
                      error={
                        errors.address && errors.address.message ? true : false
                      }
                      helperText={
                        errors.address ? errors.address.message : null
                      }
                      style={{ width: "90%" }}
                      defaultValue={queryParams.address}
                    />
                  </Grid>
                  <Grid lg={6} sm={12} xs={12} item>
                    <TextField
                      inputRef={register({
                        pattern: {
                          value:
                            /^([6|8|9]\d{7}|65[6|8|9]\d{7}|\+65[6|8|9]\d{7}|\+65-[6|8|9]\d{7})$/,
                          message: "Please enter a valid phone number!"
                        }
                      })}
                      InputLabelProps={{
                        className:
                          companyData &&
                          companyData.Contact_Number &&
                          isCompanySelected
                            ? "MuiInputLabel-shrink"
                            : ""
                      }}
                      name="phone"
                      label="Phone"
                      error={
                        errors.phone && errors.phone.message ? true : false
                      }
                      helperText={errors.phone ? errors.phone.message : null}
                      style={{ width: "90%" }}
                      defaultValue={queryParams.phone}
                    />

                    <TextField
                      InputLabelProps={{
                        className:
                          companyData && companyData.staff_strength
                            ? "MuiInputLabel-shrink"
                            : ""
                      }}
                      inputRef={register({
                        required: "Staff Strength cannot be empty!",
                        min: {
                          value: 1,
                          message: "Please enter a valid Staff Strength"
                        }
                      })}
                      name="staff_strength"
                      label={"Staff Strength*"}
                      error={
                        errors.staff_strength && errors.staff_strength.message
                          ? true
                          : false
                      }
                      helperText={
                        errors.staff_strength
                          ? errors.staff_strength.message
                          : null
                      }
                      style={{ width: "90%" }}
                      type="number"
                      defaultValue={queryParams.staff_strength}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid lg={6} sm={12} xs={12} item>
                    <TextField
                      InputLabelProps={{
                        className:
                          companyData && companyData.annual_turnover
                            ? "MuiInputLabel-shrink"
                            : ""
                      }}
                      inputRef={register({
                        required: "Annual Turn Over cannot be empty!",
                        min: {
                          value: 1,
                          message: "Please enter a valid SGD amount!"
                        }
                      })}
                      name="annual_turnover"
                      label={"Annual Turn Over (SGD)*"}
                      error={
                        errors.annual_turnover && errors.annual_turnover.message
                          ? true
                          : false
                      }
                      helperText={
                        errors.annual_turnover
                          ? errors.annual_turnover.message
                          : null
                      }
                      style={{ width: "90%" }}
                      type="number"
                      defaultValue={queryParams.annual_turnover}
                    />
                  </Grid>
                  <Grid lg={6} sm={12} xs={12} item>
                    <Controller
                      control={control}
                      name="paid_up_capital"
                      defaultValue=""
                      rules={{
                        pattern: {
                          value: /^$|^[0-9]+(\.[0-9][0-9]){0,1}$/,
                          message: "Please enter a valid SGD amount!"
                        },
                        min: {
                          value: 1,
                          message: "Please enter a valid SGD amount!"
                        }
                      }}
                      render={({ onChange, onBlur, name }) => (
                        <TextField
                          name={name}
                          label="Paid Up Capital (SGD)"
                          error={
                            errors.paid_up_capital && errors.paid_up_capital
                              ? true
                              : false
                          }
                          helperText={
                            errors.paid_up_capital
                              ? errors.paid_up_capital.message
                              : null
                          }
                          inputProps={{
                            step: "1"
                          }}
                          type="number"
                          onChange={(e) => onChange(e.target.value)}
                          onBlur={onBlur}
                          style={{ width: "90%" }}
                          defaultValue={queryParams.paid_up_capital}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid lg={6} sm={12} xs={12} item>
                    <TextField
                      inputRef={register({
                        pattern: {
                          value:
                            /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                          message: "Please enter a valid website!"
                        }
                      })}
                      InputLabelProps={{
                        className:
                          companyData &&
                          companyData.Website &&
                          isCompanySelected
                            ? "MuiInputLabel-shrink"
                            : ""
                      }}
                      name="website"
                      label="Website"
                      error={errors.website && errors.website ? true : false}
                      helperText={
                        errors.website ? errors.website.message : null
                      }
                      style={{ width: "90%" }}
                      defaultValue={queryParams.website}
                    />
                  </Grid>
                  <Grid lg={6} sm={12} xs={12} item>
                    <TextField
                      inputRef={register({
                        pattern: {
                          value:
                            /^(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?$/,
                          message: "Please enter a valid facebook url!"
                        }
                      })}
                      name="facebook"
                      label="Facebook"
                      error={
                        errors.facebook && errors.facebook.message
                          ? true
                          : false
                      }
                      helperText={
                        errors.facebook ? errors.facebook.message : null
                      }
                      style={{ width: "90%" }}
                      defaultValue={queryParams.facebook}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid lg={6} sm={12} xs={12} item>
                    <FormControl style={{ width: "90%" }}>
                      <InputLabel id="legal_structure_of_company_label">
                        Legal Structure of Company
                      </InputLabel>
                      <Controller
                        control={control}
                        name="legal_structure"
                        render={({ onChange, value, name }) => (
                          <MSelect
                            labelId="legal_structure_of_company_label"
                            name={name}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            defaultValue={queryParams.legal_structure}
                          >
                            <MenuItem value={"Sole Proprietorship"}>
                              Sole Proprietorship
                            </MenuItem>
                            <MenuItem value={"Partnership"}>
                              Partnership
                            </MenuItem>
                            <MenuItem value={"Limited Liability Partnership"}>
                              Limited Liability Partnership
                            </MenuItem>
                            <MenuItem value={"Private Limited"}>
                              Private Limited
                            </MenuItem>
                          </MSelect>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid lg={6} sm={12} xs={12} item>
                    <FormControl style={{ width: "90%" }}>
                      <InputLabel id="ownership_type_label">
                        Ownership Type
                      </InputLabel>
                      <Controller
                        control={control}
                        name="ownership_type"
                        render={({ onChange, value, name }) => (
                          <MSelect
                            name={name}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            defaultValue={queryParams.ownership_type}
                          >
                            <MenuItem
                              value={
                                "Local (at 30% local shareholding or more)"
                              }
                            >
                              Local (at 30% local shareholding or more)
                            </MenuItem>
                            <MenuItem
                              value={
                                "Foreign (less than 30% local shareholding)"
                              }
                            >
                              Foreign (less than 30% local shareholding)
                            </MenuItem>
                            <MenuItem value={"Joint-Venture"}>
                              Joint-Venture
                            </MenuItem>
                          </MSelect>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid lg={6} sm={12} xs={12} item>
                    <FormControl style={{ width: "90%" }}>
                      <InputLabel id="nature_of_business_label">
                        Nature of Business
                      </InputLabel>
                      <Controller
                        control={control}
                        name="nature_of_business"
                        render={({ onChange, value, name }) => (
                          <MSelect
                            name={name}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            defaultValue={queryParams.business_nature}
                          >
                            <MenuItem value={"Business-to-Customer"}>
                              Business-to-Customer
                            </MenuItem>
                            <MenuItem value={"Business-to-Business"}>
                              Business-to-Business
                            </MenuItem>
                            <MenuItem value={"Both"}>Both</MenuItem>
                          </MSelect>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid lg={6} sm={12} xs={12} item id="industryClassification">
                    <FormControl
                      className="MuiInput-underline"
                      style={{ width: "90%" }}
                    >
                      <Controller
                        control={control}
                        name="industry_classification"
                        render={({ onChange, value, name }) => (
                          <MultipleSelect
                            label="Industry Classification*"
                            options={groupedIndustryData}
                            name={name}
                            onChange={onChange}
                            value={value}
                            SelectProps={{
                              msgNoOptionsAvailable:
                                "All Industries are selected",
                              msgNoOptionsMatchFilter:
                                "No industry found with the current search!",
                              components: { DropdownIndicator },
                              styles: {
                                indicatorSeparator: () => {},
                                dropdownIndicator: (defaultStyles) => ({
                                  ...defaultStyles
                                  // borderBottom: "solid 0.2px rgba(0,0,0,0.54)",
                                }),
                                control: (styles) => ({
                                  ...styles,
                                  border: "none",
                                  boxShadow: "none"
                                })
                              }
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    {errors.industry_classification && (
                      <p className="MuiFormHelperText-root Mui-error primary">
                        Please add your industry classification.
                      </p>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid lg={6} sm={12} xs={12} item>
                    <TextField
                      InputLabelProps={{
                        className:
                          companyData &&
                          companyData.Principal_Business_Activity &&
                          isCompanySelected
                            ? "MuiInputLabel-shrink"
                            : ""
                      }}
                      inputRef={register({})}
                      name="principal_business_activity"
                      label="Principal Business Activity"
                      error={
                        errors.principal_business_activity &&
                        errors.principal_business_activity
                          ? true
                          : false
                      }
                      style={{ width: "90%" }}
                      defaultValue={queryParams.principal_business_activity}
                    />
                  </Grid>
                  <Grid lg={6} sm={12} xs={12} item>
                    <TextField
                      InputLabelProps={{
                        className:
                          companyData &&
                          companyData.Secondary_Business_Activity &&
                          isCompanySelected
                            ? "MuiInputLabel-shrink"
                            : ""
                      }}
                      inputRef={register({})}
                      name="secondary_business_activity"
                      label="Secondary Business Activity"
                      error={
                        errors.secondary_business_activity &&
                        errors.secondary_business_activity
                          ? true
                          : false
                      }
                      style={{ width: "90%" }}
                      defaultValue={queryParams.secondary_business_activity}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid lg={6} sm={12} xs={12} item>
                    <TextField
                      InputLabelProps={{
                        className:
                          companyData &&
                          companyData.Description &&
                          isCompanySelected
                            ? "MuiInputLabel-shrink"
                            : ""
                      }}
                      inputRef={register({})}
                      name="company_description"
                      label="Company Description (Profile)"
                      multiline
                      rows={4}
                      rowsMax={4}
                      error={
                        errors.company_description &&
                        errors.company_description.message
                          ? true
                          : false
                      }
                      style={{ width: "90%" }}
                      defaultValue={queryParams.company_description}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <>
                <h2>Contact Person Information</h2>
                <Grid container spacing={3}>
                  <Grid lg={6} sm={12} xs={12} item>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Salutation*</FormLabel>
                      <Controller
                        control={control}
                        name="salutation"
                        render={({ onChange, value, name }) => (
                          <RadioGroup
                            row
                            aria-label="position"
                            name={name}
                            defaultValue={
                              queryParams.salutation
                                ? queryParams.salutation.substring(
                                    0,
                                    queryParams.salutation.length - 1
                                  )
                                : "Dr"
                            }
                            onChange={(e, v) => onChange(v)}
                            value={value}
                          >
                            <FormControlLabel
                              value="Dr"
                              control={<Radio color="secondary" size="small" />}
                              label="Dr."
                            />
                            <FormControlLabel
                              value="Mr"
                              control={<Radio color="secondary" size="small" />}
                              label="Mr."
                            />
                            <FormControlLabel
                              value="Ms"
                              control={<Radio color="secondary" size="small" />}
                              label="Ms."
                            />
                            <FormControlLabel
                              value="Mrs"
                              control={<Radio color="secondary" size="small" />}
                              label="Mrs."
                            />
                            <FormControlLabel
                              value="Mdm"
                              control={<Radio color="secondary" size="small" />}
                              label="Mdm."
                            />
                          </RadioGroup>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid lg={6} sm={12} xs={12} item>
                    <TextField
                      inputRef={register({
                        required: "This field cannot be empty!"
                      })}
                      name="designation"
                      label="Job Title*"
                      error={
                        errors.designation && errors.designation.message
                          ? true
                          : false
                      }
                      helperText={
                        errors.designation && errors.designation.message
                          ? errors.designation.message
                          : null
                      }
                      style={{ width: "90%" }}
                      defaultValue={queryParams.designation}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid lg={6} sm={12} xs={12} item>
                    <TextField
                      inputRef={register({
                        required: "This field cannot be empty!"
                      })}
                      name="first_name"
                      label="First Name*"
                      error={
                        errors.first_name && errors.first_name.message
                          ? true
                          : false
                      }
                      helperText={
                        errors.first_name && errors.first_name.message
                          ? errors.first_name.message
                          : null
                      }
                      style={{ width: "90%" }}
                      defaultValue={queryParams.first_name}
                    />
                  </Grid>
                  <Grid lg={6} sm={12} xs={12} item>
                    <TextField
                      inputRef={register({
                        required: "This field cannot be empty!"
                      })}
                      name="last_name"
                      label="Last Name*"
                      error={
                        errors.last_name && errors.last_name.message
                          ? true
                          : false
                      }
                      helperText={
                        errors.last_name && errors.last_name.message
                          ? errors.last_name.message
                          : null
                      }
                      style={{ width: "90%" }}
                      defaultValue={queryParams.last_name}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid lg={6} sm={12} xs={12} item>
                    <TextField
                      inputRef={register({
                        required: "This field cannot be empty!",
                        pattern: {
                          value:
                            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          message: "Please enter a valid email address!"
                        }
                      })}
                      // InputLabelProps={{
                      //   className:
                      //     companyData &&
                      //     companyData.Email_Address &&
                      //     isCompanySelected
                      //       ? "MuiInputLabel-shrink"
                      //       : "",
                      // }}
                      name="email"
                      label="Email Address*"
                      error={
                        errors.email && errors.email.message ? true : false
                      }
                      helperText={
                        errors.email && errors.email.message
                          ? errors.email.message
                          : null
                      }
                      style={{ width: "90%" }}
                      defaultValue={queryParams.email}
                    />
                  </Grid>
                  <Grid lg={6} sm={12} xs={12} item>
                    <TextField
                      inputRef={register({
                        required: "This field cannot be empty!",
                        pattern: {
                          value:
                            /^([6|8|9]\d{7}|65[6|8|9]\d{7}|\+65[6|8|9]\d{7}|\+65-[6|8|9]\d{7})$/,
                          message: "Please enter a valid mobile number!"
                        }
                      })}
                      name="mobile_no"
                      label="Mobile No*"
                      error={
                        errors.mobile_no && errors.mobile_no.message
                          ? true
                          : false
                      }
                      helperText={
                        errors.mobile_no ? errors.mobile_no.message : null
                      }
                      style={{ width: "90%" }}
                      defaultValue={queryParams.mobile}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid lg={6} sm={12} xs={12} item>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Citizenship*</FormLabel>
                      <Controller
                        control={control}
                        name="citizenship"
                        render={({ onChange, value, name }) => (
                          <RadioGroup
                            row
                            aria-label="citizenship"
                            name={name}
                            defaultValue={
                              queryParams.citizenship
                                ? queryParams.citizenship
                                : "Singaporean"
                            }
                            onChange={(e, v) => onChange(v)}
                            value={value}
                          >
                            <FormControlLabel
                              value="Singaporean"
                              control={<Radio color="secondary" size="small" />}
                              label="Singaporean"
                            />
                            <FormControlLabel
                              value="Permanent Resident (PR)"
                              control={<Radio color="secondary" size="small" />}
                              label="Permanent Resident (PR)"
                            />
                            <FormControlLabel
                              value="Foreigner"
                              control={<Radio color="secondary" size="small" />}
                              label="Foreigner"
                            />
                          </RadioGroup>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  id="TandC"
                  style={{
                    backgroundColor: "lightgray",
                    paddingLeft: "20px",
                    paddingTop: "1px",
                    paddingBottom: "10px",
                    marginTop: "20px",
                    borderRadius: "5px"
                  }}
                >
                  <h2>Terms and Conditions</h2>
                  {errors.c1 && (
                    <p className="MuiFormHelperText-root Mui-error primary">
                      You need to agree to all the terms in order to proceed.
                    </p>
                  )}
                  <Grid container spacing={3}>
                    <Grid item>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value="end"
                            style={{ display: "table" }}
                            control={
                              <div style={{ display: "table-cell" }}>
                                <Controller
                                  name="c1"
                                  control={control}
                                  defaultValue={false}
                                  // rules={{ required: true }}
                                  render={(props) => (
                                    <Checkbox
                                      color="secondary"
                                      size="small"
                                      onChange={(e) =>
                                        props.onChange(e.target.checked)
                                      }
                                      checked={props.value}
                                    />
                                  )} // props contains: onChange, onBlur and value
                                />
                              </div>
                            }
                            label={
                              <span style={{ fontSize: "12px" }}>
                                I hereby confirm and certify that the above
                                information is true to the best of my knowledge.
                                I have read the{" "}
                                <Link
                                  href="https://admin.asme.org.sg/uploads/Details_of_Lite_membership_317d24ccb3.pdf"
                                  target="_blank"
                                  color="secondary"
                                >
                                  details
                                </Link>{" "}
                                of Lite membership and agree to be bound by the
                                decision of ASME concerning my application for
                                Lite Membership. (A copy of the constitution
                                would be provided on request.)
                              </span>
                            }
                            labelPlacement="end"
                          />
                          <br />
                          <FormControlLabel
                            value="end"
                            style={{ display: "table" }}
                            control={
                              <div
                                style={{
                                  display: "table-cell",
                                  width: "37.99px "
                                }}
                              ></div>
                            }
                            label={
                              <span style={{ fontSize: "12px" }}>
                                I would like to receive information, news,
                                promotions, and programme and event updates from
                                ASME, its subsidiaries, affiliates or any other
                                party acting on behalf of ASME.
                              </span>
                            }
                            labelPlacement="end"
                          />
                          <br />
                          <FormControlLabel
                            value="end"
                            style={{ display: "table" }}
                            control={
                              <div
                                style={{
                                  display: "table-cell",
                                  width: "37.99px "
                                }}
                              ></div>
                            }
                            label={
                              <span style={{ fontSize: "12px" }}>
                                I understand my business data may be shared with
                                different departments within ASME and with third
                                parties such as advertising agencies that assist
                                ASME with promotional, marketing and survey
                                activities. I am aware that ASME imposes
                                obligations on any external organisations with
                                which they share my data to maintain the
                                integrity of that information.
                              </span>
                            }
                            labelPlacement="end"
                          />
                          <br />
                          <FormControlLabel
                            value="end"
                            style={{ display: "table" }}
                            control={
                              <div
                                style={{
                                  display: "table-cell",
                                  width: "37.99px "
                                }}
                              ></div>
                            }
                            label={
                              <span style={{ fontSize: "12px" }}>
                                Upon submitting this application form, I agree
                                to give ASME permission to use my business data
                                for the purpose of ASME’s membership programme,
                                and any other related purposes that will assist
                                ASME to do so in accordance with the Personal
                                Data Protection Act 2012 and ASME’s{" "}
                                <Link
                                  href="https://asme.org.sg/PrivacyStatement/"
                                  target="_blank"
                                  color="secondary"
                                >
                                  Data Protection Policy
                                </Link>
                                .
                              </span>
                            }
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  style={{
                    paddingTop: "1rem",
                    paddingBottom: "1rem"
                  }}
                  container
                  justify="center"
                  spacing={2}
                >
                  {renderPaidMemberships(memberships)?.map((membership) => (
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "column"
                      }}
                      key={membership.id}
                      item
                      xs
                    >
                      {membership.name === "Platinum" ? (
                        <div
                          style={{
                            height: "2.5rem",
                            backgroundColor: "#FB9134",
                            color: "#fff",
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderTopLeftRadius: "4px",
                            borderTopRightRadius: "4px"
                          }}
                        >
                          Popular
                        </div>
                      ) : (
                        <div style={{ height: "2.5rem" }}></div>
                      )}
                      <Paper className={classes.membershipPaper}>
                        <h2
                          style={{
                            color: "#fff",
                            backgroundColor: "#002E5D",
                            fontWeight: "bold",
                            textAlign: "center"
                          }}
                        >
                          {membership.name}
                        </h2>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            flexGrow: 1,
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                            backgroundColor: "#fff"
                          }}
                        >
                          <span
                            style={{
                              paddingTop: "1rem",
                              paddingBottom: "1rem",
                              fontSize: "1rem",
                              fontWeight: 600,
                              textAlign: "center"
                            }}
                          >
                            {membership.annual_price === 0 ? (
                              <p>
                                Complimentary
                                <br />-
                              </p>
                            ) : (
                              <p>
                                $
                                {(
                                  Math.round(membership.annual_price * 100) /
                                  100
                                ).toFixed(2)}{" "}
                                / year <br />
                                (incl. GST)
                              </p>
                            )}
                          </span>
                          <Button
                            style={{ marginBottom: "0.75rem" }}
                            size="small"
                            variant={
                              membership.name === "Platinum"
                                ? "contained"
                                : "outlined"
                            }
                            color="secondary"
                            onClick={() => setSelectedPlan(membership.name)}
                          >
                            {selectedPlan === membership.name
                              ? "Selected"
                              : "Select Plan"}
                          </Button>
                          <hr style={{ width: "100%" }} />
                          <ul
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              backgroundColor: "#fff",
                              paddingBottom: "0.75rem",
                              paddingLeft: "0.95rem"
                            }}
                          >
                            {membership.features?.map((feature) => (
                              <li style={{ fontWeight: 500 }} key={feature.id}>
                                {feature.feature_point}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
                <p
                  style={{
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  <InfoIcon
                    style={{
                      color: "#de1736"
                    }}
                  />{" "}
                  You will need to pay the membership fee after you sign in.
                </p>
                {Object.keys(errors).length !== 0 && (
                  <p style={{ display: "flex", flexDirection: "row" }}>
                    <InfoIcon
                      style={{
                        color: "#de1736"
                      }}
                    />{" "}
                    <span
                      style={{
                        color: "#F44336"
                      }}
                    >
                      Please fill up all mandatory details.
                    </span>
                  </p>
                )}
                <Grid
                  style={{
                    marginTop: "10px",
                    marginBottom: "20px",
                    textAlign: "center"
                  }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    style={{ width: "300px" }}
                    type="submit"
                    disabled={registering}
                  >
                    Submit
                  </Button>
                </Grid>
              </>
            </form>
          </Container>
          {/* Popup Modal */}
          <Modal
            open={isModalVisible}
            onClose={handleClose}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
            disableBackdropClick
          >
            <div className={classes.paper} style={modalStyle}>
              {!loading && (
                <>
                  <div style={{ float: "right" }}>
                    <IconButton onClick={handleClose}>
                      <Close />
                    </IconButton>
                  </div>

                  <h3>Select Your Company</h3>

                  {data && data.length > 0 && (
                    <List style={{ maxHeight: "375px", overflow: "auto" }}>
                      {data.map((org) => (
                        <ListItem key={org.id} button>
                          <ListItemText
                            primary={org.name}
                            secondary={org.Registered_Address}
                            onClick={() => onCompanySelect(org)}
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}

                  {(!data || data.length === 0) && (
                    <div style={{ textAlign: "center" }}>
                      <p>No Results Found!</p>
                    </div>
                  )}

                  <br />
                  <div style={{ textAlign: "center" }}>
                    <Button variant="outlined" onClick={onCompanyNotInList}>
                      My company is not in the list
                    </Button>
                    <br />
                    <small>You may still proceed to sign up as member</small>
                  </div>
                </>
              )}
              {loading && (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress color="secondary" />
                </div>
              )}
            </div>
          </Modal>
          {/* Plan Popup Modal */}
          <Modal
            open={isPlanModalVisible && selectedPlan !== "Associate"}
            onClose={handleClosePlanModal}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
            disableBackdropClick
          >
            <div className={classes.paper} style={modalStyle}>
              {!loading && (
                <>
                  <div style={{ float: "right" }}>
                    <IconButton onClick={handleClosePlanModal}>
                      <Close />
                    </IconButton>
                  </div>

                  <h3>Please note:</h3>

                  <p>{selectedPlan} plan require you to have :</p>
                  <List
                    style={{
                      maxHeight: "375px",
                      overflow: "auto",
                      textAlign: "center"
                    }}
                  >
                    <ListItem key={1} button>
                      <ListItemText
                        primary={
                          "Annual Sales turnover of not more than $100 million"
                        }
                      />
                    </ListItem>
                    OR
                    <ListItem key={2} button>
                      <ListItemText
                        primary={"Employment size of not more than 200 workers"}
                      />
                    </ListItem>
                  </List>

                  <br />
                  <div style={{ textAlign: "center" }}>
                    <small>
                      You may still proceed to sign up with Associate plan
                    </small>
                  </div>
                </>
              )}
              {loading && (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress color="secondary" />
                </div>
              )}
            </div>
          </Modal>
        </>
      )}
      {registerOrgData && !registering && (
        <>
          <Container maxWidth="md" style={{ marginTop: "30px" }}>
            {/* <Header isMobile={isMobile} title="Registration Successful!" /> */}
            <Welcome resetLink={registerOrgData.reset_link} />
          </Container>
        </>
      )}
      <Snackbar
        open={showRegistrationError}
        autoHideDuration={3000}
        onClose={() => setShowRegistrationError(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={() => setShowRegistrationError(false)} severity="error">
          {registrationError && registrationError.response
            ? registrationError.response.data.message
            : "Internal Server Error. Please try again!"}
        </Alert>
      </Snackbar>
      <Backdrop
        className={classes.backdrop}
        open={loadingCompany || registering}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
}

export default Registration;

const Header = ({ isMobile, title }) => (
  <Grid container spacing={4} direction={isMobile ? "column-reverse" : "row"}>
    <Grid
      item
      xs={12}
      sm={8}
      style={{
        textAlign: isMobile ? "center" : "left"
      }}
    >
      <h1>{title}</h1>
    </Grid>
    <Grid
      item
      xs={12}
      sm={4}
      style={{
        textAlign: isMobile ? "center" : "right"
      }}
    >
      <img src="/images/logo.png" alt="" width={"100px"} />
    </Grid>
  </Grid>
);
