import React from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  TextField
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { API_ERROR } from "../../../app/constants";
import { Alert } from "@material-ui/lab";
import { usePublicAxios } from "../../../util/axios";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

function ForgotPassword() {
  const classes = useStyles();

  const history = useHistory();
  const { handleSubmit, register, errors } = useForm();

  const [{ data, loading, error }, forgotPassword] = usePublicAxios(
    "/organizations/reset/sendToken",
    {
      manual: true,
      cache: false
    }
  );

  const onSubmit = (data) => {
    forgotPassword({
      data: {
        email: data.email,
        role: "member"
      },
      method: "POST"
    });
    // history.push("/dashboard/admin");
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: "100vh", background: "#E6E6E6" }}
    >
      <Grid
        item
        xs={12}
        sm={3}
        style={{
          textAlign: "center",
          marginBottom: "10%"
        }}
      >
        <img src="/images/logo.png" alt="ASME Logo" width={"100px"} />
        <br />
        <br />
        <Paper elevation={3} style={{ padding: "10px" }}>
          {error && (
            <>
              <Alert severity="error">
                {error.response &&
                error.isAxiosError &&
                error.response.status === 400 &&
                error.response.data &&
                error.response.data.message &&
                error.response.data.message.error
                  ? error.response.data.message.error
                  : API_ERROR}
              </Alert>
              <br />
            </>
          )}
          {data && (
            <div style={{ textAlign: "center" }}>
              <h3 className="primary">
                We have sent you an email to reset your password. Please follow
                the instructions there to recover your account.
              </h3>
              <br />
            </div>
          )}
          {!data && (
            <>
              <h3 className="primary">Forgot Password?</h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  inputRef={register({
                    required: "This field cannot be empty!",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: "Please enter a valid email address!"
                    }
                  })}
                  name="email"
                  label="Email"
                  error={errors.email && errors.email.message ? true : false}
                  helperText={errors.email ? errors.email.message : null}
                  style={{ width: "90%" }}
                />

                <br />
                <br />
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className="primary"
                >
                  Submit
                </Button>
              </form>

              <br />
              <Button onClick={() => history.push("/auth/login")}>
                Back to Login
              </Button>
            </>
          )}
        </Paper>
        <br />
        <small>
          For any enquiries, please contact{" "}
          <a href="mailto:members@asme.org.sg">members@asme.org.sg</a>
        </small>
        <br />
      </Grid>
      {loading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="secondary" />
        </Backdrop>
      )}
    </Grid>
  );
}

export default ForgotPassword;
