import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: "fit-content",
    textAlign: "center",
    backgroundColor: "green",
    color: "white",
    padding: "5px 10px",
    marginBottom: "10px"
  }
});

const Tag = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default Tag;
