import React from "react";
import { Typography } from "@material-ui/core";
import ResourcesBanner from "./Newsroom/resource-banner";
import ResourcesContent from "./Newsroom/resource-content";

function Newsroom() {
  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Newsroom
      </Typography>
      <ResourcesBanner />
      <ResourcesContent />
    </div>
  );
}

export default Newsroom;
