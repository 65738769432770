import React, { useEffect } from "react";
import { Paper, Grid, CircularProgress } from "@material-ui/core";
import { BASE_URL, useAuthedAxios } from "../../../../util/axios";
import { Alert } from "@material-ui/lab";

function ResourcesBanner() {
  const [{ data, loading, error }, getStaticContent] = useAuthedAxios(
    {
      url: `${BASE_URL}/members-resources-page`,
      method: "GET",
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    getStaticContent();
  }, [getStaticContent]);

  return (
    <div>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        {loading && (
          <div style={{ padding: "20px 0", textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </div>
        )}
        {!loading && error && (
          <Alert severity="error">
            There was some internal server error while fetching the resource
            banner image. Please try again later!
          </Alert>
        )}

        {!loading && !data && (
          <Alert severity="info">Resource banner image not found!</Alert>
        )}

        {!loading && data && (
          <Grid container>
            <a
              href={data.hyperlink}
              style={{
                width: "100%",
              }}
              target="_blank"
            >
              <img
                src={`${BASE_URL}${data.banner_image.url}`}
                alt="Resource Banner"
                width="100%"
              />
            </a>
          </Grid>
        )}
      </Paper>
    </div>
  );
}

export default ResourcesBanner;
