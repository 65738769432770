import { gql } from "@apollo/client";

export const FETCH_FORM = gql`
  query ($slug: String, $endDate: String) {
    form: formBuilders(
      where: { slug: $slug, status: "Publish", end_date_gte: $endDate }
    ) {
      id
      title
      description
      form
      end_date
      available_to
    }
  }
`;
