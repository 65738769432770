import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React from "react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAuthedAxios } from "../../../../util/axios";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 5,
    color: "#fff"
  }
}));

function EditContact({
  isEditingContact,
  member,
  refetch,
  handleClose = () => null
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, register, errors, control } = useForm();

  const [{ loading, data, error }, updateContact] = useAuthedAxios(
    {},
    {
      manual: true
    }
  );

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error.response.data.message, {
        variant: "error",
        autoHideDuration: 2000
      });
    }
  }, [enqueueSnackbar, error]);

  useEffect(() => {
    if (data) {
      refetch();
      handleClose();
      enqueueSnackbar("Contact information updated successfully!", {
        variant: "success",
        autoHideDuration: 2000
      });
    }
  }, [data, enqueueSnackbar, handleClose, refetch]);

  const onSubmit = (values) => {
    updateContact({
      url: "/members/edit",
      method: "POST",
      data: {
        ...values,
        member: member.id,
        organization: member.organization,
        emailToUpdate: values.email,
        email: member.email,
        isUserCreated: member.user ? true : false
      }
    });
  };

  if (!member) return null;

  return (
    <form>
      <Dialog
        open={isEditingContact}
        onClose={handleClose}
        scroll="paper"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Contact</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid lg={12} sm={12} xs={12} item>
              <FormControl component="fieldset">
                <FormLabel component="legend">Salutation</FormLabel>
                <Controller
                  control={control}
                  name="salutation"
                  defaultValue={member ? member.salutation : "Dr"}
                  render={({ onChange, value, name }) => (
                    <RadioGroup
                      row
                      aria-label="position"
                      name={name}
                      onChange={(e, v) => onChange(v)}
                      value={value}
                    >
                      <FormControlLabel
                        value="Dr"
                        control={<Radio color="secondary" size="small" />}
                        label="Dr."
                      />
                      <FormControlLabel
                        value="Mr"
                        control={<Radio color="secondary" size="small" />}
                        label="Mr."
                      />
                      <FormControlLabel
                        value="Ms"
                        control={<Radio color="secondary" size="small" />}
                        label="Ms."
                      />
                      <FormControlLabel
                        value="Mrs"
                        control={<Radio color="secondary" size="small" />}
                        label="Mrs."
                      />
                      <FormControlLabel
                        value="Mdm"
                        control={<Radio color="secondary" size="small" />}
                        label="Mdm."
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid lg={12} sm={12} xs={12} item>
              <TextField
                inputRef={register({
                  required: "This field is required!"
                })}
                name="first_name"
                label="First Name"
                defaultValue={member ? member.first_name : ""}
                error={
                  errors.first_name && errors.first_name.message ? true : false
                }
                helperText={errors.first_name ? errors.first_name.message : ""}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid lg={12} sm={12} xs={12} item>
              <TextField
                inputRef={register({
                  required: "This field is required!"
                })}
                name="last_name"
                label="Last Name"
                defaultValue={member ? member.last_name : ""}
                error={
                  errors.last_name && errors.last_name.message ? true : false
                }
                helperText={errors.last_name ? errors.last_name.message : ""}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid lg={12} sm={12} xs={12} item>
              <TextField
                inputRef={register({
                  required: "This field is required!"
                })}
                name="designation"
                label="Designation"
                defaultValue={member ? member.designation : ""}
                error={
                  errors.designation && errors.designation.message
                    ? true
                    : false
                }
                helperText={
                  errors.designation ? errors.designation.message : ""
                }
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid lg={12} sm={12} xs={12} item>
              <TextField
                inputRef={register({
                  required: "This field is required!",
                  pattern: {
                    value:
                      /^([6|8|9]\d{7}|65[6|8|9]\d{7}|\+65[6|8|9]\d{7}|\+65-[6|8|9]\d{7})$/,
                    message: "Please enter a valid phone number!"
                  }
                })}
                name="mobile_no"
                label="Phone"
                defaultValue={member ? member.mobile_no : ""}
                error={
                  errors.mobile_no && errors.mobile_no.message ? true : false
                }
                helperText={errors.mobile_no ? errors.mobile_no.message : null}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid lg={12} sm={12} xs={12} item>
              <TextField
                inputRef={register({
                  required: "This field is required!"
                })}
                // disabled={member.user ? true : false}
                type="email"
                name="email"
                label="Email"
                defaultValue={member ? member.email : ""}
                error={errors.email && errors.email.message ? true : false}
                helperText={errors.email ? errors.email.message : null}
                style={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="secondary"
          >
            Update Information
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </form>
  );
}

export default React.memo(
  EditContact,
  (prev, next) => prev.isEditingContact === next.isEditingContact
);
