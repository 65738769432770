import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import RewardCard from "./reward-card";
import CustomGridList from "./grid-list";
import RewardModal from "./modals/RewardModal";
import SorryModal from "./modals/SorryModal";
import CongratsModal from "./modals/CongratsModal";
import VoucherCodeModal from "./modals/VoucherCodeModal";
import { fetchAllRewards, fetchClaimedRewards } from "./rewards.api";
import { useAuthedAxios } from "../../../../util/axios";

const useStyles = makeStyles({
  root: {
    marginTop: "15px"
  },
  pagination_container: {
    justifyContent: "center",
    marginTop: "10px"
  },
  filter: {
    marginBottom: "50px"
  }
});

const Rewards = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const allRewards = useSelector((state) => state.rewards.allRewards);
  const claimedRewards = useSelector((state) => state.rewards.claimedRewards);
  const member = useSelector((state) => state.auth.user.member);

  const [{ data: organization }] = useAuthedAxios(
    `/organizations/${member.organization}`
  );

  const [{ data: memberFirstRewardCard }] = useAuthedAxios(
    `/members-first-reward-card`
  );

  useEffect(() => {
    dispatch(fetchClaimedRewards());
    dispatch(fetchAllRewards());
  }, [dispatch]);

  return (
    <div>
      <Typography
        variant="h4"
        color="secondary"
        style={{ fontWeight: "bold", paddingBottom: "2rem" }}
      >
        Members Rewards
      </Typography>
      <Grid item xs={8}>
        <Typography
          style={{
            // fontWeight: "bold",
            paddingBottom: "1.5rem"
          }}
          variant="h6"
        >
          Claimed Rewards
        </Typography>
      </Grid>
      <CustomGridList
        claimedRewards={claimedRewards
          .slice()
          .sort((a, b) =>
            a.listing_builder.title.localeCompare(b.listing_builder.title)
          )}
      />
      <RewardModal />
      <SorryModal />
      <CongratsModal />
      <VoucherCodeModal />

      <Grid item xs={8}>
        <Typography
          style={{
            // fontWeight: "bold",
            paddingTop: "2rem"
          }}
          variant="h6"
        >
          All Rewards
        </Typography>
      </Grid>
      {
        <Grid container className={classes.root} spacing={3}>
          {memberFirstRewardCard?.show && (
            <Grid
              key={memberFirstRewardCard?.id}
              item
              xl={3}
              lg={3}
              md={3}
              xs={12}
              sm={12}
            >
              <RewardCard
                memberFirstRewardCard
                data={memberFirstRewardCard}
                organization={organization}
              />
            </Grid>
          )}
          {allRewards &&
            allRewards.length > 0 &&
            allRewards
              .slice()
              .sort((a, b) => a.title.localeCompare(b.title))
              .map((rewardCard) => (
                <Grid
                  key={rewardCard.id}
                  item
                  xl={3}
                  lg={3}
                  md={3}
                  xs={12}
                  sm={12}
                >
                  <RewardCard
                    disabled={!rewardCard.isVoucherAvailableToClaim}
                    data={rewardCard}
                    organization={organization}
                  />
                </Grid>
              ))}
        </Grid>
      }
      {/* {allpage > 0 && <Grid container className={classes.pagination_container}>
    <Pagination
      count={
        Math.ceil(allpage/12)
      }
      color="secondary"
      onChange={changePage}
      page={currentPage}
    />
  </Grid>}
      {/* {
    allpage === 0 && "No Any Event Available"
  } */}
    </div>
  );
};

export default Rewards;
