import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

ReactGA.initialize("UA-170289821-2", { debug: true });

const GoogleAnalyticsTracker = () => {
  let location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);

  return <></>;
};

export default GoogleAnalyticsTracker;
