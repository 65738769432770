import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ProtectedRoute } from "./util/ProtectedRoute";
import AuthContainer from "./containers/Auth/auth";
import Dashboard from "./containers/Dashboard/dashboard.container";
import Forms from "./containers/Forms/forms";
import Polls from "./containers/Member/Routes/Polls/Polls";
import Poll from "./containers/Member/Routes/Poll/Poll";
import ReviewsContent from "./containers/Member/Routes/Reviews/reviews-content";
import SingleResource from "./containers/Member/Routes/single-resource";
import WorkshopContent from "./containers/Member/Routes/Workshops/workshops-content";

const AppRoutes = () => (
  <Switch>
    <Route exact path="/">
      <Redirect to="/auth" />
    </Route>
    <ProtectedRoute key="dashboard" path="/dashboard">
      <Dashboard />
    </ProtectedRoute>
    <Route key="dashboard" path="/form/:slug">
      <Forms />
    </Route>
    <Route key="dashboard" path="/poll/:slug">
      <Polls />
    </Route>
    <Route key="review" path="/review/:slug">
      <ReviewsContent />
    </Route>
    <Route exact path="/newsroom/:id">
      <SingleResource />
    </Route>
    <Route key="dashboard" path="/workshops/:id">
      <WorkshopContent />
    </Route>
    <Route exact key="dashboard" path="/polls/:slug">
      <Poll />
    </Route>
    <Route path="/auth" key="authentication">
      <AuthContainer />
    </Route>
    {/* Not Found */}
    <Route render={() => <h1>Not Found</h1>} />
  </Switch>
);

export default AppRoutes;
