import React, { useEffect, useState } from "react";
import moment from "moment";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import ImgMediaCard from "../../../../components/Events/ImgMediaCard";
import { ASME_SITE_BASE_URL, axios } from "../../../../util/axios";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "15px"
  },
  pagination_container: {
    justifyContent: "center",
    marginTop: "10px"
  },
  filter: {
    marginBottom: "50px"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

function Events() {
  const classes = useStyles();

  const [title, setTitle] = useState("All Events");
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFilter, setCurrentFilter] = useState("current");

  useEffect(() => {
    switch (currentFilter) {
      case "all":
        setTitle("All Events");
        return getEvents(``);
      case "past":
        setTitle("Past Events");
        return getEvents(`date_lt=${moment().format("YYYY-MM-DD")}`);
      case "current":
        setTitle("Happening Soon");
        return getEvents(`date_gte=${moment().format("YYYY-MM-DD")}`);
      default:
        return getEvents(``);
    }
  }, [currentFilter]);

  const getEvents = (dateFilter) => {
    axios
      .get(
        ASME_SITE_BASE_URL +
          `/events-v-2-s?_sort=date&${dateFilter ? dateFilter : ""}`
      )
      .then(({ data, status }) => {
        if (status === 200) {
          setEvents(data);
        }
      });
  };

  const changePage = (num, iop) => {
    setCurrentPage(iop);
  };

  const handleFilterChange = (event) => {
    setCurrentFilter(event.target.value);
    setCurrentPage(1);
  };

  const renderEvents = (events) => {
    return events
      .slice((currentPage - 1) * 12, (currentPage - 1) * 12 + 12)
      .map((event) => {
        return (
          <Grid item xs={12} md={4} lg={3} sm={6}>
            <ImgMediaCard data={event} url={ASME_SITE_BASE_URL} />
          </Grid>
        );
      });
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h4"
          color="secondary"
          style={{ fontWeight: "bold" }}
        >
          {title}
        </Typography>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={currentFilter}
            onChange={handleFilterChange}
          >
            <MenuItem value={"all"}>All Events</MenuItem>
            <MenuItem value={"current"}>Current</MenuItem>
            <MenuItem value={"past"}>Past</MenuItem>
          </Select>
        </FormControl>
      </div>

      {
        <Grid container className={classes.root} spacing={1}>
          {events && renderEvents(events)}
        </Grid>
      }
      {events.length > 0 && (
        <Grid container className={classes.pagination_container}>
          <Pagination
            count={Math.ceil(events.length / 12)}
            color="secondary"
            onChange={changePage}
            page={currentPage}
          />
        </Grid>
      )}
      {events.length === 0 && "No Event Available"}
    </div>
  );
}

export default Events;
