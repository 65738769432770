import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import Forms from "./Surveys/Forms";


function Surveys() {

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
      Surveys/Forms
      </Typography>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        <Typography
          variant="h6"
          color="secondary"
          style={{ fontWeight: "bold" }}
        >
          Forms
        </Typography>
        <Forms />
      </Paper>
    </div>
  );
}

export default Surveys;
