import { gql } from "@apollo/client";

export const FETCH_FORMS = gql`
  query($endDate: String) {
    forms: formBuilders(where: { status: "Publish", end_date_gte: $endDate }) {
      id
      title
      slug
      start_date
      end_date
    }
  }
`;
