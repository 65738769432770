import {
  CircularProgress,
  Paper,
  Typography,
  makeStyles,
  Grid
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ASME_SITE_BASE_URL, BASE_URL, axios } from "../../../../util/axios";
import FullImage from "../Announcements/fullimage";
import { Helmet } from "react-helmet";

function ProgramsContent() {
  const { id } = useParams();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    getEvents(1);
  }, []);

  const getEvents = (iop) => {
    axios.get(`${BASE_URL}/program-cards/${id}`).then(({ data }) => {
      if (data.content) {
        data["content"] = data.content.replace(
          "/uploads/",
          `${BASE_URL}/uploads/`
        );
      }
      setEvents(data);
    });
  };

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Programs
      </Typography>
      <Helmet>
        <title>{events.seo_title}</title>
        <meta name="description" content={`${events.seo_description}`} />
        <meta property="og:url" content={`${events.Og_url}`} />
        <link rel="canonical" href={`${events.Canonical}`} />
        <link rel="robots" href={`${events.Robots}`} />
        <meta property="og:site_name" content={`${events.Og_site_url}`} />
      </Helmet>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        <FullImage data={events} url={BASE_URL} />
        {events && (
          <div
            className="ck-content"
            dangerouslySetInnerHTML={{
              __html: events.content
            }}
          ></div>
        )}
        {!events && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            This is an error while loading the content. Please try reloading the
            page!
          </Alert>
        )}
      </Paper>
    </div>
  );
}

export default ProgramsContent;
