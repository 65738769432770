import { TextField } from "@material-ui/core";
import React from "react";
import { urlify } from "../../../util";

function TextFieldRenderer({ id, settings, member, organization, register }) {
  const getPrefillValue = () => {
    const ref = settings.prefill.ref;
    const attribute = settings.prefill.attribute;
    switch (ref) {
      case "members":
        return member[attribute];
      case "organizations":
        return organization[attribute];
      default:
        return "";
    }
  };

  const prefillValue = settings.prefill ? getPrefillValue() : null;

  return (
    <>
      <span dangerouslySetInnerHTML={{ __html: urlify(settings.title) }}></span>
      <TextField
        name={id}
        // label={settings.title}
        required={settings.required}
        placeholder={settings.placeholder}
        defaultValue={prefillValue}
        style={{ width: "100%" }}
        inputRef={register}
        helperText={settings.help}
        InputProps={{
          type:
            settings.prefill && settings.prefill.attribute === "email"
              ? "email"
              : "text"
        }}
      />
    </>
  );
}

export default TextFieldRenderer;
