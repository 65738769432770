import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import moment from "moment";
import ReactGA from "react-ga";

import "./ribbon.css";
import {
  setClaimedVoucherOpen,
  setClickedVoucher,
  setCongratsVoucherOpen,
  setRewardVoucherOpen,
  setSorryVoucherOpen
} from "./rewards.slice";
import { BASE_URL, useAuthedAxios } from "../../../../util/axios";
import { fetchClaimedRewards } from "./rewards.api";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    // width: "90%",
    height: "100%",
    maxWidth: 450,
    borderRadius: "8px",
    boxShadow: "0 0 15px rgba(0,0,0,.2)"
  },
  content: { flex: 1 },
  media: {
    height: 155
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "center"
  }
});

export default function RewardCard({
  disabled,
  isModal,
  voucherCode,
  data,
  organization,
  memberFirstRewardCard = false
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [
    {
      data: generateRealTimeVoucherData,
      loading: generateRealTimeVoucherLoading,
      error: generateRealTimeVoucherError
    },
    generateRealTimeVoucher
  ] = useAuthedAxios("/vouchers/generateRealTimeVoucher", { manual: true });

  const [
    {
      data: claimVoucherData,
      loading: claimVoucherLoading,
      error: claimVoucherError
    },
    claimVoucher
  ] = useAuthedAxios("/vouchers/claimVoucher", { manual: true });

  useEffect(() => {
    if (generateRealTimeVoucherData && generateRealTimeVoucherData.code) {
      // Voucher is generated successfully

      // based on response from API
      dispatch(setRewardVoucherOpen({ open: false }));
      dispatch(setClaimedVoucherOpen({ open: false }));
      dispatch(setSorryVoucherOpen({ open: false }));
      dispatch(setCongratsVoucherOpen({ open: true }));

      // also refresh claimed rewards
      dispatch(fetchClaimedRewards());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateRealTimeVoucherData]);

  useEffect(() => {
    if (generateRealTimeVoucherError) {
      return enqueueSnackbar(
        generateRealTimeVoucherError.response.data?.message,
        {
          variant: "error"
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateRealTimeVoucherError]);

  useEffect(() => {
    if (claimVoucherData && claimVoucherData.code) {
      // Voucher is generated successfully

      // based on response from API
      dispatch(setRewardVoucherOpen({ open: false }));
      dispatch(setClaimedVoucherOpen({ open: false }));
      dispatch(setSorryVoucherOpen({ open: false }));
      dispatch(setCongratsVoucherOpen({ open: true }));

      // also refresh claimed rewards
      dispatch(fetchClaimedRewards());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimVoucherData]);

  useEffect(() => {
    if (claimVoucherError) {
      return enqueueSnackbar(claimVoucherError.response.data?.message, {
        variant: "error"
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimVoucherError]);

  if (!data) return null;

  async function handleGenerateRealTimeVoucher() {
    const api_data = {
      listing_builder: data
    };

    generateRealTimeVoucher({
      method: "POST",
      data: api_data
    });
  }

  async function handleClaimVoucher() {
    const api_data = {
      listing_builder: data
    };

    claimVoucher({
      method: "POST",
      data: api_data
    });
  }

  const handleRewardVoucherClick = () => {
    if (memberFirstRewardCard)
      return window
        .open(data?.FindOutMoreURL || "mailto:bd@asme.org.sg", "_blank")
        .focus();

    //check if Member is eligible for Reward
    if (!data?.available_to["Lite"] && organization) {
      if (!data?.available_to[organization.membership_type]) {
        dispatch(setClickedVoucher({ voucher: data }));
        dispatch(setRewardVoucherOpen({ open: false }));
        dispatch(setClaimedVoucherOpen({ open: false }));
        dispatch(setSorryVoucherOpen({ open: true }));
        dispatch(setCongratsVoucherOpen({ open: false }));
        return;
      }
    }
    dispatch(setClickedVoucher({ voucher: data }));
    dispatch(setRewardVoucherOpen({ open: true }));
    dispatch(setClaimedVoucherOpen({ open: false }));
    dispatch(setSorryVoucherOpen({ open: false }));
    dispatch(setCongratsVoucherOpen({ open: false }));
  };

  const handleReceiveVoucherClick = () => {
    if (data?.voucher_generation === "RealTime")
      return handleGenerateRealTimeVoucher();

    if (data?.voucher_generation === "Link" && data?.merchant_link) {
      ReactGA.event({
        category: "Merchant Link",
        action: "Click",
        label: data?.merchant
      });
      return window.open(data?.merchant_link || "", "_blank").focus();
    }
    handleClaimVoucher();
  };

  const onCopyVoucherCode = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy);
    return enqueueSnackbar("Copied Voucher Code To Clipboard", {
      variant: "success"
    });
  };

  return (
    <Card className={classes.root} style={{ opacity: disabled ? 0.7 : 1 }}>
      {disabled && (
        <div className="ribbon ribbon-top-left">
          <span>Fully Redeemed</span>
        </div>
      )}
      <>
        <CardMedia
          className={classes.media}
          style={{
            width: isModal ? 450 : ""
          }}
          image={
            isModal
              ? data?.image
                ? `${BASE_URL}${data?.image?.url}`
                : "/images/reward.jpg"
              : data?.thumbnail
              ? `${BASE_URL}${data?.thumbnail?.url}`
              : "/images/reward.jpg"
          }
          title="Voucher"
        />
        <CardContent className={classes.content}>
          <Typography gutterBottom variant="h5" component="h2">
            {data?.title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            style={{ paddingBottom: "0.35rem" }}
          >
            {isModal
              ? data?.description
              : `${data?.description?.slice(0, 140)}${
                  data?.description?.length > 140 ? "..." : ""
                }`}
          </Typography>
          {!memberFirstRewardCard && (
            <Typography variant="subtitle2" component="p">
              From: {moment(data?.start_date).format("Do MMM 'YY")} -{" "}
              {moment(data?.end_date).format("Do MMM 'YY")}
            </Typography>
          )}
          {!memberFirstRewardCard && (
            <Typography variant="subtitle2" component="p">
              Type:{" "}
              {data?.discount_type ? data?.discount_type : "Discount Code"}
            </Typography>
          )}
        </CardContent>
      </>
      <CardActions className={classes.buttonDiv}>
        {isModal ? (
          voucherCode ? (
            <Button
              size="small"
              // color="secondary"
              style={{
                color: "#fff",
                backgroundImage: "linear-gradient(to right, #4BD543 , #4BB543)"
              }}
              onClick={() => onCopyVoucherCode(voucherCode)}
              disabled={disabled}
              variant="contained"
            >
              VOUCHER CODE: {voucherCode}
            </Button>
          ) : data?.voucher_generation === "Link" ? (
            <Button
              size="small"
              color="secondary"
              disabled={
                disabled ||
                generateRealTimeVoucherLoading ||
                claimVoucherLoading
              }
              variant="contained"
              onClick={handleReceiveVoucherClick}
            >
              Claim Voucher on Merchant Site
            </Button>
          ) : (
            <Button
              size="small"
              color="secondary"
              disabled={
                disabled ||
                generateRealTimeVoucherLoading ||
                claimVoucherLoading
              }
              variant="contained"
              onClick={handleReceiveVoucherClick}
            >
              Receive Voucher
            </Button>
          )
        ) : (
          <Button
            size="small"
            color="primary"
            disabled={disabled}
            variant="outlined"
            onClick={handleRewardVoucherClick}
          >
            Find Out More
          </Button>
        )}
      </CardActions>
      {isModal && (
        <>
          <CardContent>
            <Typography variant="caption" component="p">
              Terms & Conditions:
            </Typography>
            <Typography variant="caption" component="p">
              {data?.tnc}
            </Typography>
          </CardContent>
        </>
      )}
    </Card>
  );
}
