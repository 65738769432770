import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
  Avatar,
  CircularProgress,
  Menu,
  MenuItem,
  Paper,
  Grid,
  Button
} from "@material-ui/core";
import clsx from "clsx";
import { Alert } from "@material-ui/lab";
import { Home, Menu as MenuIcon } from "@material-ui/icons";
import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
  Route,
  Switch,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import EventIcon from "@material-ui/icons/Event";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import EventNoteIcon from "@material-ui/icons/EventNote";
import RedeemIcon from "@material-ui/icons/Redeem";
import PermDeviceInformationIcon from "@material-ui/icons/PermDeviceInformation";

import {
  ASME_SITE_BASE_URL,
  ASME_SITE_URL,
  useAuthedAxios
} from "../../../util/axios";
import { HEADER_COLOR, SECONDARY_COLOR } from "../../../app/constants";
import "../styles/single-resource.css";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    backgroundColor: HEADER_COLOR,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    backgroundColor: HEADER_COLOR,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    paddingTop: "20px",
    paddingBottom: "20px",

    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "#E6E6E6",
    minHeight: "100vh"
  },
  container: {
    border: "1px solid lightgray",
    marginRight: "5px",
    padding: "20px",
    borderRadius: "8px"
  },
  ReadMorePosts: {
    paddingTop: "1rem"
  },
  memberContainer: {
    paddingBottom: "50px"
  },
  overview: {
    listStyleType: "disc !important",
    fontSize: "14px",
    color: "rgba(61, 57, 53, 0.48)",
    padding: "10px"
  },
  articleDetails: {
    display: "flex!important",
    padding: "20px 0px"
  },
  articleAuthor: {
    color: "#C8102E !important",
    paddingRight: "10px"
  },
  articleDate: {
    color: "rgba(0, 46, 93, 0.48) !important",
    paddingLeft: "10px"
  },
  articleImg: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "900px !important"
    // textAlign: 'center !important',
  },
  blockQuote: {
    background: "#f9f9f9",
    borderLeft: "10px solid #ccc",
    margin: "1.5em 0px",
    padding: "0.5em 20px !important",
    maxWidth: "100%"
  },
  articleSection: {
    listStyleType: "disc !important",
    padding: "5px 0px!important"
  },
  img: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "900px !important"
  },
  articleImgDesc: {
    // textAlign: 'center !important',
    margin: "10px",
    color: "#9c9c9c"
  }
}));

function SingleResource() {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const [menu, setMenu] = useState([]);
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const member = useSelector((state) => state?.auth?.user?.member);

  const [{ data: organization }] = useAuthedAxios(
    `/organizations/${member?.organization}`
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let _menu = [
      {
        title: "Home",
        path: "/dashboard",
        icon: <Home />
      },
      {
        title: "Events",
        path: "/dashboard/events",
        icon: <EventIcon />
      },
      {
        title: "Special Promotion",
        path: "/dashboard/special-promotion",
        icon: (
          <img
            src={require("../images/diamond-shape.png")}
            width="18px"
            height="18px"
            alt="Icon"
            style={{ marginLeft: "3px" }}
          />
        )
      },
      {
        title: "Members Rewards",
        path: "/dashboard/rewards",
        icon: <RedeemIcon />
      },
      {
        title: "Newsroom",
        path: "/dashboard/newsroom",
        icon: <BookmarksIcon />
      },
      {
        title: "Surveys/Forms",
        path: "/dashboard/surveys",
        icon: <PermDeviceInformationIcon />
      },
      // {
      //   title: "Programs",
      //   path: "/dashboard/programs",
      //   icon: <EventNoteIcon />
      // },
      // {
      //   title: "Announcements",
      //   path: "/dashboard/announcements",
      //   icon: <BookmarksIcon />
      // },
      {
        title: "Workshops/Training",
        path: "/dashboard/workshops",
        icon: <EventNoteIcon />
      },
      {
        title: "Reviews",
        path: "/dashboard/reviews",
        icon: <EventNoteIcon />
      }
    ];

    if (
      organization &&
      // organization.membership_type &&
      organization?.membership_type !== "Associate" &&
      organization?.membership_type !== "Platinum"
    ) {
      _menu.splice(_menu.length, 0, {
        title: "Upgrade Membership",
        path: "/dashboard/membership",
        icon: (
          <img
            src={require("../images/crown.png")}
            width="18px"
            height="18px"
            alt="Icon"
            style={{ marginLeft: "3px" }}
          />
        )
      });
      setMenu(_menu);
    } else {
      _menu.splice(_menu.length, 0, {
        title: "ASME Membership",
        path: "/auth/register",
        icon: (
          <img
            src={require("../images/crown.png")}
            width="18px"
            height="18px"
            alt="Icon"
            style={{ marginLeft: "3px" }}
          />
        )
      });
      setMenu(_menu);
    }
    setMenu(_menu);
  }, [organization]);

  const [{ data, loading, error }, getNews] = useAxios(
    {
      url: `${ASME_SITE_BASE_URL}/newsrooms`,
      method: "GET"
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    if (data) document.title = data?.title || "Members Portal";

    return () => {
      document.title = "Members Portal";
    };
  }, [data]);

  useEffect(() => {
    if (id) {
      getNews({
        url: `${ASME_SITE_BASE_URL}/newsrooms/${id}`
      });
    }
  }, [getNews, id]);

  const onMenuSelect = (menuItem) => {
    history.push(menuItem?.path);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event?.currentTarget);
    setMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open
          })}
          color="transparent"
          elevation={0}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open
              })}
              size="small"
              style={{
                background: "white",
                color: HEADER_COLOR
              }}
              elevation={4}
            >
              <MenuIcon />
            </IconButton>
            {open && (
              <IconButton
                color="secondary"
                style={{
                  background: "white",
                  color: HEADER_COLOR,
                  marginLeft: "-39px"
                }}
                size="small"
                onClick={handleDrawerClose}
                elevation={4}
              >
                <MenuIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          elevation={3}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
          PaperProps={{ elevation: 10 }}
        >
          <div
            className={classes.toolbar}
            style={
              !open
                ? {
                    marginTop: "40px"
                  }
                : null
            }
          >
            <img
              src="/images/logo.png"
              width={open ? "70px" : "30px"}
              alt="ASME Logo"
            />
          </div>
          <Divider />
          <List
            style={{ paddingLeft: "7px" }}
            dense
            className="mobile-main-menup-padding"
          >
            {menu.map((item) => (
              <ListItem
                button
                key={item.title}
                onClick={() => onMenuSelect(item)}
              >
                <ListItemIcon
                  style={{
                    color: "#fff",
                    minWidth: open ? "32px" : "56px"
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.title}
                  primaryTypographyProps={{
                    style: {
                      fontWeight:
                        pathname === item.path
                          ? "bolder"
                          : pathname.includes("/newsroom") &&
                            item.path === "/dashboard/newsroom"
                          ? "bolder"
                          : 500,
                      color: "#fff"
                    }
                  }}
                />
              </ListItem>
            ))}
            <ListItem
              button
              key={"Merchant Portal"}
              onClick={() =>
                (window.location.href = `${ASME_SITE_URL}/merchant-view`)
              }
            >
              <ListItemIcon
                style={{
                  color: SECONDARY_COLOR,
                  minWidth: open ? "32px" : "56px"
                }}
              >
                <img
                  src={require("../images/diamond-shape.png")}
                  width="18px"
                  height="18px"
                  alt="Icon"
                  style={{ marginLeft: "3px" }}
                />
              </ListItemIcon>
              <ListItemText
                primary={"Merchant Portal"}
                primaryTypographyProps={{
                  style: {
                    fontWeight:
                      pathname === true
                        ? "bolder"
                        : pathname.includes("/newsroom") &&
                          true === "/dashboard/newsroom"
                        ? "bolder"
                        : 500,
                    color: "#fff"
                  }
                }}
              />
            </ListItem>
          </List>
          {/* <Divider /> */}
        </Drawer>
        <main className={classes.content}>
          {member ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "flex-end",
                zIndex: 1202,
                position: "relative"
              }}
              className="mobile-width"
            >
              <Typography variant="h6" style={{ marginRight: "10px" }}>
                Welcome, {member ? member?.first_name : "User"}
              </Typography>
              <div>
                <Avatar
                  style={{ background: "white", color: HEADER_COLOR }}
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                >
                  {member?.first_name[0]?.toUpperCase()}
                </Avatar>

                {member && (
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    open={menuOpen}
                    onClose={handleClose}
                  >
                    {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
<MenuItem onClick={handleClose}>My account</MenuItem>
<Divider /> */}
                    <MenuItem
                      onClick={() => {
                        history.push("/dashboard/organisation");
                        handleClose();
                      }}
                    >
                      Organisation Info
                    </MenuItem>
                    <MenuItem onClick={() => {}}>Logout</MenuItem>
                  </Menu>
                )}
              </div>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "flex-end",
                zIndex: 1202,
                position: "relative"
              }}
              className="mobile-width"
            >
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => history.push("/auth/login")}
              >
                Login
              </Button>
            </div>
          )}

          <div className={classes.toolbar} />

          <Switch>
            <Route exact path={`${path}`}>
              <div>
                <Typography
                  variant="h4"
                  color="secondary"
                  style={{ fontWeight: "bold" }}
                >
                  Newsroom
                </Typography>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginTop: "40px" }}
                >
                  {loading && (
                    <div style={{ padding: "20px 0", textAlign: "center" }}>
                      <CircularProgress color="secondary" />
                    </div>
                  )}
                  {!loading && error && (
                    <Alert severity="error">
                      There was some internal server error while fetching the
                      resource. Please try again later!
                    </Alert>
                  )}

                  {!loading && !data && (
                    <Alert severity="info">No News found!</Alert>
                  )}

                  {data && (
                    <Grid container className={classes.memberContainer}>
                      <Grid item>
                        <h1>{data.title}</h1>
                        <div className={"article-section"}>
                          <ReactMarkdown>{data.tileDescription}</ReactMarkdown>
                        </div>
                        <div className={classes.articleDetails}>
                          <div className={classes.articleAuthor}>
                            {data.author}
                          </div>{" "}
                          |
                          <div className={classes.articleDate}>
                            {" "}
                            Posted on {data.date}
                          </div>
                        </div>
                        <div className={classes.articleImg}>
                          {data.tileImage[0].url && (
                            <img
                              src={`https://admin.asme.org.sg/${data.tileImage[0].url}`}
                              alt="Main"
                              className={classes.img}
                            />
                          )}
                          <Grid item md={9} className="article-section">
                            {data.imageCaption ? (
                              <blockquote className={classes.blockQuote}>
                                <ReactMarkdown source={data.imageCaption}>
                                  {data.imageCaption}
                                </ReactMarkdown>{" "}
                              </blockquote>
                            ) : null}
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item md={9} className="article-section">
                        <ReactMarkdown>
                          {data.ParagraphFirst
                            ? data.ParagraphFirst.replaceAll(
                                "](/",
                                "](https://admin.asme.org.sg/"
                              )
                            : null}
                        </ReactMarkdown>
                        <br />
                        <ReactMarkdown>
                          {data.ParagraphSecond
                            ? data.ParagraphSecond.replaceAll(
                                "](/",
                                "](https://admin.asme.org.sg/"
                              )
                            : null}
                        </ReactMarkdown>
                        <br />
                        <ReactMarkdown>
                          {data.ParagraphThird
                            ? data.ParagraphThird.replaceAll(
                                "](/",
                                "](https://admin.asme.org.sg/"
                              )
                            : null}
                        </ReactMarkdown>
                        {data.disclamier && (
                          <div>
                            <em>{data.disclamier}</em>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Paper>
              </div>
            </Route>
          </Switch>
        </main>
      </div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Newsroom
      </Typography>
    </>
  );
}

export default SingleResource;
