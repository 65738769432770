import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { usePublicAxios } from "../../../util/axios";

const queryParams = require("query-string");

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function ResetPassword() {
  const classes = useStyles();
  const { search } = useLocation();
  const history = useHistory();
  const { handleSubmit, register, errors, watch } = useForm();

  const params = queryParams.parse(search);

  useEffect(() => {
    if (!params.token) {
      history.push("/");
    }
  }, [params]);

  const [{ loading: verifying, error: verifyError }] = usePublicAxios({
    url: "/organizations/reset/verifyToken",
    data: {
      token: params.token,
      role: "member",
    },
    method: "POST",
  });

  useEffect(() => {
    if (verifyError) {
      history.push("/");
    }
  }, [verifyError, history]);

  const [
    { loading: resetting, data: resetData, error: resetError },
    resetPassword,
  ] = usePublicAxios(
    {
      url: "/organizations/reset/password",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const onSubmit = ({ password }) => {
    resetPassword({
      data: {
        token: params.token,
        role: "member",
        password,
      },
    });
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={resetting || verifying}>
        <CircularProgress color="secondary" />
      </Backdrop>
      {!verifying && !verifyError && (
        <>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "100vh", background: "#E6E6E6" }}
          >
            <Grid
              item
              xs={12}
              sm={3}
              style={{
                textAlign: "center",
                marginBottom: "10%",
              }}
            >
              <img src="/images/logo.png" alt="ASME Logo" width={"100px"} />
              <br />
              <br />
              <Paper elevation={3} style={{ padding: "10px" }}>
                {!resetData && (
                  <>
                    <h3 className="primary">Enter Your New Password</h3>
                    {resetError && (
                      <>
                        <Alert severity="error">
                          There was some internal server error while processing
                          your request. Please try again.
                        </Alert>
                        <br />
                      </>
                    )}
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <TextField
                        inputRef={register({
                          required: "This field cannot be empty!",
                          pattern: {
                            value: /^.{6,}$/,
                            message: "Password must be 6 characters long!",
                          },
                        })}
                        name="password"
                        label="Password"
                        type="password"
                        error={
                          errors.password && errors.password.message
                            ? true
                            : false
                        }
                        helperText={
                          errors.password ? errors.password.message : null
                        }
                        style={{ width: "90%" }}
                      />
                      <br />
                      <br />
                      <TextField
                        inputRef={register({
                          validate: (v) => v === watch("password"),
                        })}
                        name="c_password"
                        label="Confirm Password"
                        type="password"
                        error={errors.c_password ? true : false}
                        helperText={
                          errors.c_password
                            ? "Please make sure your passwords match."
                            : null
                        }
                        style={{ width: "90%" }}
                      />

                      <br />
                      <br />
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className="primary"
                      >
                        Submit
                      </Button>
                      <br />
                    </form>
                  </>
                )}
                {resetData && (
                  <div style={{ textAlign: "center" }}>
                    <h3 className="primary">
                      Your Account Password has been set! You can login to the
                      system now.
                    </h3>
                    <br />
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      className="primary"
                      onClick={() => history.push("/auth/login")}
                    >
                      Login
                    </Button>
                    <br />
                  </div>
                )}
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default ResetPassword;
