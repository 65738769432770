import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton, Modal, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";

import {
  setClaimedVoucherOpen,
  setClickedVoucher,
  setCongratsVoucherOpen,
  setRewardVoucherOpen,
  setSorryVoucherOpen
} from "../rewards.slice";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flex: 1,
    backgroundColor: "#E14040"
  },
  modalPaper: {
    position: "absolute",
    textAlign: "center",
    fontWeight: 500,
    fontSize: "1.15rem",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    border: "none"
  }
}));

function getModalStyle() {
  const top = 25;
  // const left = 50;

  return {
    top: `${top}%`,
    margin: "auto",
    // left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
    outline: "none"
  };
}

const SorryModal = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [modalStyle] = React.useState(getModalStyle);

  const clickedVoucherData = useSelector(
    (state) => state.rewards.clickedVoucherData
  );
  const isModalOpen = useSelector(
    (state) => state.rewards.sorryVoucherModalOpen
  );

  const handleCloseModal = () => {
    dispatch(setClickedVoucher({ voucher: null }));
    dispatch(setRewardVoucherOpen({ open: false }));
    dispatch(setClaimedVoucherOpen({ open: false }));
    dispatch(setSorryVoucherOpen({ open: false }));
    dispatch(setCongratsVoucherOpen({ open: false }));
  };

  const handleUpgradeMembership = () => {
    dispatch(setClickedVoucher({ voucher: null }));
    dispatch(setRewardVoucherOpen({ open: false }));
    dispatch(setClaimedVoucherOpen({ open: false }));
    dispatch(setSorryVoucherOpen({ open: false }));
    dispatch(setCongratsVoucherOpen({ open: false }));
    history.push("/dashboard/membership");
  };

  if (!clickedVoucherData || !clickedVoucherData.available_to) return null;

  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        disableBackdropClick
      >
        <div className={classes.modalPaper} style={modalStyle}>
          {
            <>
              <div style={{ float: "right" }}>
                <IconButton
                  style={{ zIndex: 9, color: "#000" }}
                  onClick={handleCloseModal}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <Typography
                gutterBottom
                color="secondary"
                variant="h5"
                component="h2"
              >
                Sorry!
              </Typography>
              <p>
                This reward is exclusive to{" "}
                {clickedVoucherData &&
                  Object.keys(clickedVoucherData.available_to)
                    .map((membership) =>
                      clickedVoucherData.available_to[membership]
                        ? membership
                        : ""
                    )
                    .filter((item) => item)
                    .join(", ")}{" "}
                Members only.
              </p>

              <br />
              <div style={{ textAlign: "center" }}>
                <Button
                  size="small"
                  color="secondary"
                  // disabled={disabled}
                  variant="contained"
                  onClick={handleUpgradeMembership}
                >
                  Upgrade Now
                </Button>
              </div>
            </>
          }
        </div>
      </Modal>
    </div>
  );
};

export default SorryModal;
