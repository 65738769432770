import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup
} from "@material-ui/core";
import React from "react";
import { urlify } from "../../../util";

function RadioButtonsRenderer({ settings, id, setValue, register }) {
  const onChange = (e, value) => {
    setValue(id, value);
  };

  React.useEffect(() => {
    register(id);
  }, [register, id]);

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <span
          dangerouslySetInnerHTML={{ __html: urlify(settings.title) }}
        ></span>
      </FormLabel>
      <RadioGroup aria-label={settings.title} onChange={onChange} name={id}>
        {settings.options.map((option, index) => (
          <FormControlLabel
            key={`${id}${index}`}
            control={
              <Radio required={settings.required ? settings.required : false} />
            }
            label={option.value}
            value={option.value}
          />
        ))}
      </RadioGroup>
      <FormHelperText>{settings.help}</FormHelperText>
    </FormControl>
  );
}

export default RadioButtonsRenderer;
