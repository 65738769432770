import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import Forms from "../Surveys/Forms";
import ImgMediaCard from "../../../../components/Events/ImgMediaCard";
import Programcard from "./cards";

import { makeStyles } from "@material-ui/core/styles";
import { ASME_SITE_BASE_URL, BASE_URL, axios } from "../../../../util/axios";
import { Alert, Pagination } from "@material-ui/lab";

const useStyles = makeStyles({
  root: {
    marginTop: "15px",
  },
  pagination_container: {
    justifyContent: "center",
    marginTop: "10px",
  },
  filter: {
    marginBottom: "50px",
  },
});

function Programs() {
  const classes = useStyles();
  const [events, setEvents] = useState([]);
  const [allData, setAllData] = useState([]);
  const [allpage, setAllpage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    axios.get(`${BASE_URL}/program-cards/count`).then(({ data }) => {
      setTimeout(() => {
        setAllpage(data);
      }, 200);
    });
    getEvents(1);
  }, []);

  const getEvents = (iop) => {
    axios.get(`${BASE_URL}/program-cards`).then(({ data }) => {
      const sorted_data = data.sort((a, b) => b.id - a.id)
      setAllData(sorted_data);
      const page_per_data = sorted_data.slice(
        (currentPage - 1) * 12,
        currentPage * 12
      );
      setEvents(page_per_data);
    });
  };
  const changePage = (num, iop) => {
    setCurrentPage(iop);
    console.log(currentPage, iop);
    const page_per_data = allData.slice((iop - 1) * 12, iop * 12);
    setEvents(page_per_data);
  };

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Programs
      </Typography>
      {
        <Grid container className={classes.root} spacing={1}>
          {events.map((event) => {
            return (
              <Grid
                key={event.id}
                item
                xs={12}
                md={4}
                lg={3}
                sm={6}
                style={{ marginBottom: "1rem" }}
              >
                <Programcard data={event} url={BASE_URL} />
              </Grid>
            );
          })}
        </Grid>
      }
      {allpage > 0 && events && (
        <Grid container className={classes.pagination_container}>
          <Pagination
            count={Math.ceil(allpage / 12)}
            color="secondary"
            onChange={changePage}
            page={currentPage}
          />
        </Grid>
      )}
      {allpage === 0 && "No Any Program Available"}
    </div>
  );
}

export default Programs;
