import React, { useEffect, useState } from "react";
import TextFieldRenderer from "./TextField";
import {
  CHECKBOX,
  FILE_UPLOAD,
  PARAGRAPH,
  RADIO_BUTTONS,
  TEXT_FIELD,
  PLAIN_TEXT,
  CHECKBOX_GROUP,
  API_ERROR
} from "../../../app/constants";
import {
  Button,
  CircularProgress,
  Grid,
  Link,
  makeStyles
} from "@material-ui/core";
import ParagraphRenderer from "./Paragraph";
import CheckboxRenderer from "./Checkbox";
import RadioButtonsRenderer from "./RadioButtons";
import UploadFileRenderer from "./UploadFile";
import { authedAxios, useAuthedAxios } from "../../../util/axios";
import { useSelector } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useForm } from "react-hook-form";
import PlainTextRenderer from "./PlainText";
import CheckboxGroupRenderer from "./CheckboxGroup";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import Modal from "@material-ui/core/Modal";

const queryString = require("query-string");

export const renderItem = (
  { type, id, settings },
  { member, organization },
  { register, setValue, watch, errors }
) => {
  switch (type) {
    case TEXT_FIELD:
      return (
        <TextFieldRenderer
          id={id}
          settings={settings}
          member={member}
          organization={organization}
          register={register}
        />
      );
    case PARAGRAPH:
      return (
        <ParagraphRenderer
          id={id}
          settings={settings}
          member={member}
          organization={organization}
          register={register}
        />
      );
    case CHECKBOX:
      return (
        <CheckboxRenderer id={id} settings={settings} register={register} />
      );
    case CHECKBOX_GROUP:
      return (
        <CheckboxGroupRenderer
          id={id}
          settings={settings}
          setValue={setValue}
          register={register}
          watch={watch}
          errors={errors}
        />
      );
    case RADIO_BUTTONS:
      return (
        <RadioButtonsRenderer
          id={id}
          settings={settings}
          setValue={setValue}
          register={register}
        />
      );
    case FILE_UPLOAD:
      return (
        <UploadFileRenderer
          id={id}
          settings={settings}
          setValue={setValue}
          register={register}
        />
      );
    case PLAIN_TEXT:
      return <PlainTextRenderer id={id} settings={settings} />;
    default:
      return "";
  }
};

const getChangedPrefilledData = (
  newEntries,
  formBuilder,
  { member, organization }
) => {
  const prefilledEntries = formBuilder.filter(
    (item) => item?.settings?.prefill
  );

  const updatedMembersData = {};
  const updatedOrganizationsData = {};
  prefilledEntries.forEach((item) => {
    const attribute = item.settings.prefill.attribute;
    const ref = item.settings.prefill.ref;
    if (ref === "members") {
      if (member[attribute] !== newEntries[item.id]) {
        updatedMembersData[attribute] = newEntries[item.id];
      }
    } else if (ref === "organizations") {
      if (organization[attribute] !== newEntries[item.id]) {
        updatedOrganizationsData[attribute] = newEntries[item.id];
      }
    }
  });

  return { updatedMembersData, updatedOrganizationsData };
};

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flex: 1,
    backgroundColor: "#002E5D"
  },
  modalPaper: {
    position: "absolute",
    textAlign: "center",
    fontWeight: 500,
    fontSize: "1.15rem",
    // width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    border: "none"
  }
}));

function getModalStyle() {
  const top = 25;
  // const left = 50;

  return {
    top: `${top}%`,
    margin: "auto",
    // left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
    outline: "none"
  };
}

function FormRenderer({ form: formBuilder, id, isPoll, allowMultipleSelect }) {
  const { search } = useLocation();
  const classes = useStyles();

  const [modalStyle] = React.useState(getModalStyle);

  const { enqueueSnackbar } = useSnackbar();

  const queryParams = queryString.parse(search);
  const workshopId = queryParams.workshopId;

  const [loadingPayment, setLoadingPayment] = useState(false);
  const [timeLeft, setTimeLeft] = useState(5);

  const member = useSelector((state) => state?.auth?.user?.member);
  const { register, handleSubmit, setValue, watch, errors } = useForm();

  const [
    { loading: submitting, data: submissionData, error: submissionError },
    sendSubmission
  ] = useAuthedAxios(
    {
      url: `/${isPoll ? "poll" : "form"}-submissions`,
      method: "POST"
    },
    {
      manual: true
    }
  );

  const [{ loading, data, error }] = useAuthedAxios(
    `/organizations/${member?.organization}`
  );

  const [{ data: workshop }, getWorkshop] = useAuthedAxios(
    `/workshops/${workshopId}`,
    {
      manual: true
    }
  );

  const [
    { data: paymentData, loading: paymentLoading, error: paymentError },
    pay
  ] = useAuthedAxios("/workshops/paymentForWorkshop", { manual: true });

  useEffect(() => {
    if (workshopId) getWorkshop();
  }, [getWorkshop, workshopId]);

  useEffect(() => {
    // redirect to payment gateway
    if (paymentData && paymentData.status.status === "SUCCESS") {
      localStorage.setItem(
        "isPaymentInitiatedForWorkshop",
        paymentData.data.id
      );
      window.location.href = paymentData.data.redirect_url;
    }
  }, [paymentData]);

  useEffect(() => {
    if (paymentLoading) {
      setLoadingPayment(true);
      countdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentLoading]);

  useEffect(() => {
    if (paymentError) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  }, [paymentError]);

  function countdown() {
    var downloadTimer = setInterval(function () {
      if (timeLeft <= 0) {
        clearInterval(downloadTimer);
      }
      setTimeLeft((time) => (time <= 0 ? 0 : time - 1));
    }, 1000);
  }

  async function handleWorkshopPayment({ formSubmissionId }) {
    if (!workshop) return;

    setLoadingPayment(true);

    const data = {
      complete_payment_url:
        process.env.REACT_APP_COMPLETE_WORKSHOP_PAYMENT_URL ||
        "https://dev.members.asme.org.sg/dashboard/post-workshop-payment",
      complete_checkout_url:
        process.env.REACT_APP_COMPLETE_WORKSHOP_CHECKOUT_URL ||
        "https://dev.members.asme.org.sg/dashboard/post-workshop-payment",
      error_payment_url:
        process.env.REACT_APP_ERROR_WORKSHOP_PAYMENT_URL ||
        "https://dev.members.asme.org.sg/dashboard/post-workshop-payment",
      member,
      workshop,
      formSubmission: formSubmissionId
    };

    pay({
      method: "POST",
      data
    });
  }

  const onSubmit = async (values) => {
    const { updatedMembersData, updatedOrganizationsData } =
      getChangedPrefilledData(values, formBuilder, {
        member,
        organization: data
      });

    if (Object.keys(updatedMembersData).length > 0) {
      try {
        delete updatedMembersData.email; // This ensures, we are not updating user's data.

        await authedAxios.put(`/members/${member?.id}`, updatedMembersData);
        // console.log("Updated Member's Data");
      } catch (e) {
        // console.log("Error while updating member's data", e);
      }
    }

    if (Object.keys(updatedOrganizationsData).length > 0) {
      try {
        await authedAxios.put(
          `/organizations/${data.id}`,
          updatedOrganizationsData
        );
        // console.log("Updated Organization's Data");
      } catch (e) {
        // console.log("Error while updating member's data", e);
      }
    }

    const formData = new FormData();
    const dataObj = {};
    Object.entries(values).forEach(([id, value]) => {
      if (!(value instanceof FileList)) {
        dataObj[id] = value;
      } else {
        if (value.length > 0) {
          formData.append("files.files", value[0], id + "___" + value[0].name);
        }
      }
    });

    const occurances = onlyOneCheckboxTest(Object.values(dataObj), true);
    if (isPoll && !allowMultipleSelect && occurances !== 1)
      return enqueueSnackbar("Please select only one option", {
        variant: "error"
      });

    let formDataToSubmit = {
      submission: dataObj,
      member: member.id,
      organization: data.id,
      first_name: member.first_name,
      last_name: member.last_name,
      mobile_no: member.mobile_no,
      email: member.email
    };
    if (isPoll) {
      formDataToSubmit.poll = id;
    } else {
      formDataToSubmit.form = id;
    }
    formData.append("data", JSON.stringify(formDataToSubmit));
    const res = await sendSubmission({
      data: formData
    });

    if (workshopId) {
      handleWorkshopPayment({ formSubmissionId: res.data.id });
    }
  };

  function onlyOneCheckboxTest(arr, testFor) {
    var counts = {};

    for (var i = 0; i < arr.length; i++) {
      var num = arr[i];
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    }

    return counts[testFor];
  }

  return (
    <>
      <Grid container>
        {paymentError && (
          <div
            style={{
              marginBottom: "2rem",
              width: "100%"
            }}
          >
            <Alert severity="error">
              {paymentError?.response?.data?.message || API_ERROR}
            </Alert>
          </div>
        )}
        <Grid container spacing={3}>
          {loadingPayment && !paymentError && (
            <Modal
              open={loadingPayment}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
              disableBackdropClick
            >
              <div className={classes.modalPaper} style={modalStyle}>
                {
                  <>
                    <p>
                      Redirecting You To The Payment Gateway in{" "}
                      <span>{timeLeft}</span> Seconds
                    </p>

                    <p>Please Do Not Refresh The Page</p>
                    <br />
                    <div style={{ textAlign: "center" }}>
                      <small>
                        Not working?{" "}
                        <button
                          disabled={timeLeft > 0 || paymentLoading}
                          onClick={() =>
                            handleWorkshopPayment({
                              formSubmissionId: submissionData.id
                            })
                          }
                        >
                          Click Here
                        </button>
                      </small>
                    </div>
                  </>
                }
              </div>
            </Modal>
          )}
        </Grid>

        {(loading || submitting) && (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </Grid>
        )}

        {(error || submissionError) && (
          <Alert severity="error">
            There was some error while communiating with server. Please try
            again later!
          </Alert>
        )}

        {data && !data.company_registration_no && (
          <>
            <Alert severity="info">
              <AlertTitle>Missing Organization UEN!</AlertTitle>
              It seems that you have not indicated your <b>UEN Number</b> in
              your organisation information. <br />
              Click{" "}
              <Link component={RouterLink} to="/dashboard/organisation?edit">
                <b>here</b>
              </Link>{" "}
              to do so before proceeding.
            </Alert>
          </>
        )}

        {data &&
          data.company_registration_no &&
          !submitting &&
          !submissionData && (
            <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
              {formBuilder.map((item) => (
                <Grid item xs={12} key={item.id} style={{ padding: "10px 0" }}>
                  {renderItem(
                    item,
                    {
                      member,
                      organization: data
                    },
                    { register, setValue, watch, errors }
                  )}
                </Grid>
              ))}
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "right",
                  marginBottom: isPoll ? "1rem" : "",
                  marginRight: isPoll ? "1rem" : ""
                }}
              >
                <Button variant="contained" type="submit" color="secondary">
                  Submit
                </Button>
              </Grid>
            </form>
          )}
        {submissionData && (
          <Grid
            style={{
              marginRight: isPoll ? "1rem" : "",
              marginBottom: isPoll ? "1rem" : "",
              textAlign: "center"
            }}
            item
            xs={12}
          >
            {isPoll && submitting ? (
              <Grid item xs={12}>
                <CircularProgress color="secondary" />
              </Grid>
            ) : null}
            {!isPoll && (
              <>
                <p>Thank you for your submission!</p>
                <p>
                  <RouterLink to="/dashboard/surveys">Click here</RouterLink> to
                  go back.
                </p>
              </>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default FormRenderer;
