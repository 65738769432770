export const INACTIVE = "INACTIVE";
export const ACTIVE = "ACTIVE";
export const ERROR = "ERROR";

export const MOBILE_BREAKPOINT = 540;
export const SECONDARY_COLOR = "#de1736";
export const HEADER_COLOR = "#002E5D";

export const TEXT_FIELD = "TEXT_FIELD";
export const PLAIN_TEXT = "PLAIN_TEXT";
export const PARAGRAPH = "PARAGRAPH";
export const CHECKBOX = "CHECKBOX";
export const CHECKBOX_GROUP = "CHECKBOX_GROUP";
export const RADIO_BUTTONS = "RADIO_BUTTONS";
export const FILE_UPLOAD = "FILE_UPLOAD";

export const API_ERROR =
  "There was some internal server error while processing your request. Please try later!";
