import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Checkbox,
  TextField
} from "@material-ui/core";
import React from "react";
import { urlify } from "../../../util";

function CheckboxGroupRenderer({
  settings,
  id,
  setValue,
  register,
  watch,
  errors
}) {
  const [hasOther, setHasOther] = React.useState(false);
  const [otherValue, setOtherValue] = React.useState(null);

  const [hasFirstError, setHasFirstError] = React.useState(false);
  const [error, setError] = React.useState(null);

  const onChange = (value, isChecked) => {
    const currentVal = watch(id) || {
      actual: [],
      other: "",
      type: "checkbox_group"
    };
    if (isChecked) {
      setValue(id, {
        ...currentVal,
        actual: [...currentVal.actual, value]
      });
    } else {
      const val = currentVal.actual.filter((v) => v !== value);
      setValue(id, {
        ...currentVal,
        actual: val
      });
    }
  };

  const onOtherCheckboxChange = (isChecked) => {
    setHasOther(isChecked);
    const currentVal = watch(id) || {
      actual: [],
      other: "",
      type: "checkbox_group"
    };
    if (!isChecked) {
      setValue(id, {
        ...currentVal,
        other: undefined
      });
    } else {
      setValue(id, {
        ...currentVal,
        other: otherValue
      });
    }
  };

  const onOtherValueChange = (value) => {
    const currentVal = watch(id) || {
      actual: [],
      other: "",
      type: "checkbox_group"
    };
    setOtherValue(value);
    setValue(id, {
      ...currentVal,
      other: value
    });
  };

  React.useEffect(() => {
    if (errors && errors[id]) {
      setHasFirstError(true);
      if (settings.min) {
        setError(
          `${settings.title} must contain mininum of ${settings.min} and maximum of ${settings.max} selections!`
        );
      } else {
        setError(
          `${settings.title} must contain maximum of ${settings.max} selections!`
        );
      }
    }
  }, [errors, id, settings]);

  React.useEffect(() => {
    if (watch(id) && settings && hasFirstError) {
      const value = watch(id);
      let count = (value && value.actual && value.actual.length) || 0;
      count = hasOther ? count + 1 : count;
      if (count > settings.min && count <= settings.max) {
        setError(null);
      } else {
        if (settings.min) {
          setError(
            `${settings.title} must contain mininum of ${settings.min} and maximum of ${settings.max} selections!`
          );
        } else {
          setError(
            `${settings.title} must contain maximum of ${settings.max} selections!`
          );
        }
      }
    }
  }, [id, settings, watch, hasFirstError, hasOther]);

  React.useEffect(() => {
    if (settings)
      register(id, {
        validate: (value) => {
          let count = (value && value.actual && value.actual.length) || 0;
          count = hasOther ? count + 1 : count;

          return (
            (count >= settings.min && count <= settings.max) ||
            "This field cannot be empty!"
          );
        }
      });
  }, [settings, register, id, hasOther]);

  return (
    <FormControl required component="fieldset" error={error ? true : false}>
      <FormLabel component="legend">
        <span
          dangerouslySetInnerHTML={{ __html: urlify(settings.title) }}
        ></span>
      </FormLabel>
      <FormGroup aria-label={settings.title} name={id}>
        {settings.options.map((option, index) => (
          <FormControlLabel
            key={`${id}${index}`}
            control={
              <Checkbox
                onChange={(e, checked) => onChange(option.value, checked)}
              />
            }
            label={option.value}
            value={option.value}
          />
        ))}
        {settings.showOther && (
          <FormControlLabel
            key={`other_checkbox_answer`}
            control={
              <Checkbox
                onChange={(e, checked) => onOtherCheckboxChange(checked)}
              />
            }
            label={
              <TextField
                disabled={!hasOther}
                size="small"
                id="other_answer"
                label="Other"
                onChange={(e) => onOtherValueChange(e.target.value)}
                required={hasOther}
              />
            }
          />
        )}
      </FormGroup>
      <FormHelperText>{error ? error : settings.help}</FormHelperText>
    </FormControl>
  );
}

export default CheckboxGroupRenderer;
