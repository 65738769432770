import React, { useEffect } from "react";
import { CircularProgress, Paper, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import useSWR from "swr";

import { authedAxios } from "../../../../util/axios";

const PostWorkshopPayment = () => {
  const history = useHistory();

  const { data: payment } = useSWR(
    `/organizations/checkPaymentStatus/${localStorage.getItem(
      "isPaymentInitiatedForWorkshop"
    )}`,
    (url) => authedAxios(url).then((res) => res.data)
  );

  useEffect(() => {
    if (!localStorage.getItem("isPaymentInitiatedForWorkshop"))
      history.push("/dashboard/workshops");

    return () => {
      localStorage.removeItem("isPaymentInitiatedForWorkshop");
      window.location.reload();
    };
  }, [history]);

  function renderStatus(status) {
    switch (status) {
      case "NEW":
        return "Payment has not been made. ";
      case "ACT":
        return "Payment is yet to be completed. ";
      case "CLO":
        return "Thanks for Making Payment. ";
      default:
        return "Your Payment process experienced an interruption, but no charges were made. ";
    }
  }

  function goToHome() {
    localStorage.removeItem("isPaymentInitiatedForWorkshop");
    history.push("/");
    window.location.reload();
  }

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Workshop Payment
      </Typography>
      {!payment ? (
        <div
          style={{
            width: "100%",
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
          <div className="ck-content">
            <p>
              {payment
                ? renderStatus(payment.data.payment.status)
                : "Checking status... "}
              <a onClick={goToHome} href="/">
                Click Here{" "}
              </a>{" "}
              to go Home
            </p>
          </div>
        </Paper>
      )}
    </div>
  );
};

export default PostWorkshopPayment;
