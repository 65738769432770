import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";

import {
  setClickedVoucher,
  setClaimedVoucherOpen,
  setRewardVoucherOpen,
  setSorryVoucherOpen,
  setCongratsVoucherOpen
} from "./rewards.slice";
import { BASE_URL } from "../../../../util/axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    borderRadius: "8px",
    width: "280px",
    height: "140px",
    boxShadow: "0 0 15px rgba(0,0,0,.2)"
  },
  details: {
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },
  content: {
    flex: "1 0 auto",
    textAlign: "left"
  },
  cover: {
    width: 100
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));

export default function ClaimedCard({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  if (!data) return null;

  if (!data.listing_builder) return null;

  const handleClaimedVoucherClick = () => {
    dispatch(setClaimedVoucherOpen({ open: true }));
    dispatch(setRewardVoucherOpen({ open: false }));
    dispatch(setSorryVoucherOpen({ open: false }));
    dispatch(setCongratsVoucherOpen({ open: false }));
    dispatch(setClickedVoucher({ voucher: data }));
  };

  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <span component="subtitle1" variant="subtitle1">
            {data.listing_builder.title.slice(0, 20)}
            {data.listing_builder.title.length > 20 && "..."}
          </span>
          <br />
          <span style={{ fontSize: 10 }} color="textSecondary">
            {data.listing_builder.description
              ? `${data.listing_builder.description.slice(0, 25)}${
                  data.listing_builder.description.length > 25 ? "..." : ""
                }`
              : "No description"}
          </span>
        </CardContent>
        <div className={classes.controls}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={handleClaimedVoucherClick}
          >
            View Voucher
          </Button>
        </div>
      </div>

      <CardMedia
        className={classes.cover}
        image={
          data.listing_builder.thumbnail
            ? `${BASE_URL}${data.listing_builder.thumbnail.url}`
            : "/images/reward.jpg"
        }
        title="Voucher"
      />
    </Card>
  );
}
