import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";

function UENDialog({ open = false, onClose = () => null }) {
  const history = useHistory();

  const onRedirect = () => {
    history.push("/dashboard/organisation?edit");
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Missing Organization UEN!"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          It seems that you have not indicated your <b>UEN Number</b> in your
          organisation information. Please add your Organisation's UEN Number in
          order to proceed.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default">
          Cancel
        </Button>
        <Button
          onClick={onRedirect}
          color="secondary"
          variant="contained"
          autoFocus
        >
          Add UEN
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UENDialog;
