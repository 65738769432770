import React, { useEffect, useState } from "react";
import {
  Divider,
  TextField,
  MenuItem,
  Grid,
  FormControl,
  Select as MSelect,
  InputLabel,
  Button,
  makeStyles,
  Backdrop,
  CircularProgress,
  Snackbar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@material-ui/pickers";
import { MultipleSelect } from "react-select-material-ui";
import IHLData from "../../../../app/IHL.json";
import { components } from "react-select";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { useAuthedAxios } from "../../../../util/axios";
import { Alert } from "@material-ui/lab";
import { Close } from "@material-ui/icons";

const groupedIndustryData = Object.keys(IHLData.industry).map((group) => ({
  label: group,
  options: IHLData.industry[group].map((industry) => ({
    value: group + " | " + industry,
    label: industry
  }))
}));

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },

  modalPaper: {
    position: "absolute",
    // width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    border: "none"
  }
}));

function getModalStyle() {
  const top = 25;
  // const left = 50;

  return {
    top: `${top}%`,
    margin: "auto",
    // left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
    outline: "none"
  };
}

function EditOrganizationInfo({ data: companyData, onDataUpdateSuccess }) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const [hasUENError, setHasUENError] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [orgUpdatedData, setOrgUpdatedData] = useState({});
  const [isPlanModalVisible, setPlanModalVisibility] = useState(false);

  const [yearOfIncorporation, setYearOfIncorporation] = useState(
    companyData.year_of_incorporation ? companyData.year_of_incorporation : null
  );

  const { handleSubmit, register, errors, control } = useForm({
    defaultValues: {
      name: companyData.name ? companyData.name : "",
      company_registration_no: companyData.company_registration_no
        ? companyData.company_registration_no
        : "",
      address: companyData.address ? companyData.address : "",
      phone: companyData.phone ? companyData.phone : "",
      // fax: companyData.phone ? companyData.phone : "",
      staff_strength: companyData.staff_strength
        ? companyData.staff_strength
        : "",
      principal_business_activity: companyData.principal_business_activity
        ? companyData.principal_business_activity
        : "",
      secondary_business_activity: companyData.secondary_business_activity
        ? companyData.secondary_business_activity
        : "",
      annual_turnover: companyData.annual_turnover
        ? companyData.annual_turnover
        : "",
      paid_up_capital: companyData.paid_up_capital
        ? companyData.paid_up_capital
        : "",
      website: companyData.website ? companyData.website : "",
      facebook: companyData.facebook ? companyData.facebook : "",
      // industry_classification: getDefaultOptions(),
      industry_classification:
        companyData.industry_classification &&
        companyData.industry_classification.length > 0
          ? companyData.industry_classification.map((c) => c.title)
          : [],
      legal_structure: companyData.legal_structure
        ? companyData.legal_structure
        : "",
      ownership_type: companyData.ownership_type
        ? companyData.ownership_type
        : "",
      nature_of_business: companyData.nature_of_business
        ? companyData.nature_of_business
        : "",
      company_description: companyData.company_description
        ? companyData.company_description
        : ""
    }
  });

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ? (
            <ArrowDropUpIcon
              style={{ color: "rgba(0, 0, 0, 0.54)", marginRight: "-8px" }}
            />
          ) : (
            <ArrowDropDownOutlinedIcon
              style={{ color: "rgba(0, 0, 0, 0.54)", marginRight: "-8px" }}
            />
          )}
        </components.DropdownIndicator>
      )
    );
  };

  const [{ data, loading, error }, updateOrganization] = useAuthedAxios(
    `/organizations/update/${companyData.id}`,
    {
      manual: true,
      cache: false
    }
  );

  useEffect(() => {
    if (data) {
      setHasData(true);
      onDataUpdateSuccess();
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setHasError(true);
      console.log(error.response);
    }
  }, [error]);

  const [{ data: verifyOrgData, loading: verifying }, verifyOrg] =
    useAuthedAxios(`/organizations`, {
      manual: true,
      cache: false
    });

  useEffect(() => {
    if (verifyOrgData) {
      // setHasData(true);
      // onDataUpdateSuccess();
      if (verifyOrgData) {
        if (verifyOrgData.length > 0) setHasUENError(true);
        else
          updateOrganization({
            method: "PUT",
            data: {
              ...orgUpdatedData
            }
          });
      }
    }
  }, [verifyOrgData]);

  const onSubmit = (data) => {
    const { annual_turnover, paid_up_capital, staff_strength, ...rest } = data;

    const organization = {
      annual_turnover,
      paid_up_capital,
      staff_strength,
      ...rest
    };

    //condition check for staff strength and annual turnover
    if (companyData.membership_type !== "Associate") {
      if (staff_strength > 200) {
        if (annual_turnover > 100000000) return setPlanModalVisibility(true);
      }
      if (annual_turnover > 100000000) {
        if (staff_strength > 200) return setPlanModalVisibility(true);
      }
    }

    if (!annual_turnover) {
      organization.annual_turnover = 0;
    } else {
      organization.annual_turnover = parseFloat(annual_turnover);
    }

    if (!paid_up_capital) {
      organization.paid_up_capital = 0;
    } else {
      organization.paid_up_capital = parseFloat(paid_up_capital);
    }

    if (!staff_strength) {
      organization.staff_strength = 0;
    } else {
      organization.staff_strength = parseInt(staff_strength);
    }

    // delete organization.company_registration_no;

    if (yearOfIncorporation) {
      organization.year_of_incorporation = yearOfIncorporation
        ? new Date(yearOfIncorporation).toISOString()
        : null;
    }

    if (organization.industry_classification)
      organization.industry_classification =
        organization.industry_classification.map((d) => ({ title: d }));

    if (
      companyData.company_registration_no ===
      organization.company_registration_no
    ) {
      updateOrganization({
        method: "PUT",
        data: {
          ...organization
        }
      });
    } else {
      setOrgUpdatedData(organization);
      verifyOrg({
        url: `/organizations?company_registration_no=${organization.company_registration_no}`,
        method: "GET"
      });
    }
  };

  const handleClosePlanModal = () => {
    setPlanModalVisibility(false);
  };

  return (
    <>
      <Divider />
      <Modal
        open={isPlanModalVisible}
        onClose={handleClosePlanModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        disableBackdropClick
      >
        <div className={classes.modalPaper} style={modalStyle}>
          {
            <>
              <div style={{ float: "right" }}>
                <IconButton onClick={handleClosePlanModal}>
                  <Close />
                </IconButton>
              </div>

              <h3>Please note:</h3>

              <p>Corporate and Platinum plan require you to have :</p>
              <List
                style={{
                  maxHeight: "375px",
                  overflow: "auto",
                  textAlign: "center"
                }}
              >
                <ListItem key={1} button>
                  <ListItemText
                    primary={
                      "Annual Sales turnover of not more than $100 million"
                    }
                  />
                </ListItem>
                OR
                <ListItem key={2} button>
                  <ListItemText
                    primary={"Employment size of not more than 200 workers"}
                  />
                </ListItem>
              </List>

              <br />
              <div style={{ textAlign: "center" }}>
                <small>
                  You may still proceed to upgrade with Associate plan
                </small>
              </div>
            </>
          }
        </div>
      </Modal>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: "90%",
          maxWidth: "100%",
          overflow: "auto",
          overflowX: "hidden",
          marginTop: "10px"
        }}
        className="custom-scroll"
      >
        <Grid container spacing={3}>
          <Grid lg={12} sm={12} xs={12} item>
            <TextField
              disabled
              InputLabelProps={{
                className:
                  companyData && companyData.name ? "MuiInputLabel-shrink" : ""
              }}
              inputRef={register({
                required: "This field cannot be empty!"
              })}
              name="name"
              label={
                <span>
                  Organisation Name <span style={{ color: "#de1736" }}>*</span>
                </span>
              }
              error={errors.name && errors.name ? true : false}
              helperText={errors.name ? errors.name.message : null}
              style={{ width: "95%" }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid lg={6} sm={12} xs={12} item>
            <TextField
              disabled
              InputLabelProps={{
                className:
                  companyData && companyData.company_registration_no
                    ? "MuiInputLabel-shrink"
                    : ""
              }}
              inputRef={register({
                required: "UEN cannot be empty!",
                pattern: {
                  value: /^[A-Z0-9]*$/,
                  message: "Please enter a valid company registration number!"
                }
              })}
              name="company_registration_no"
              label={
                <span>
                  Company Registration No. (UEN){" "}
                  <span style={{ color: "#de1736" }}>*</span>
                </span>
              }
              error={
                errors.company_registration_no &&
                errors.company_registration_no.message
                  ? true
                  : false
              }
              helperText={
                errors.company_registration_no
                  ? errors.company_registration_no.message
                  : null
              }
              style={{ width: "90%" }}
            />
          </Grid>
          <Grid lg={6} sm={12} xs={12} item>
            <DatePicker
              onChange={(date) => setYearOfIncorporation(date.toDate())}
              value={yearOfIncorporation}
              label="Year of Incorporation"
              style={{ width: "90%" }}
              format="DD MMMM YYYY"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid lg={6} sm={12} xs={12} item>
            <TextField
              inputRef={register({})}
              InputLabelProps={{
                className:
                  companyData && companyData.address
                    ? "MuiInputLabel-shrink"
                    : ""
              }}
              name="address"
              label="Address"
              multiline
              rows={4}
              rowsMax={4}
              error={errors.address && errors.address.message ? true : false}
              style={{ width: "90%" }}
              //   defaultValue={queryParams.address}
            />
          </Grid>
          <Grid lg={6} sm={12} xs={12} item>
            <TextField
              inputRef={register({
                pattern: {
                  value:
                    /^([6|8|9]\d{7}|65[6|8|9]\d{7}|\+65[6|8|9]\d{7}|\+65-[6|8|9]\d{7})$/,
                  message: "Please enter a valid phone number!"
                }
              })}
              InputLabelProps={{
                className:
                  companyData && companyData.Contact_Number
                    ? "MuiInputLabel-shrink"
                    : ""
              }}
              name="phone"
              label="Phone"
              error={errors.phone && errors.phone.message ? true : false}
              helperText={errors.phone ? errors.phone.message : null}
              style={{ width: "90%" }}
              //   defaultValue={queryParams.phone}
            />

            <Controller
              control={control}
              name="staff_strength"
              rules={{
                min: {
                  value: 1,
                  message: "Please enter a valid Staff Strength"
                }
              }}
              render={({ onChange, onBlur, name }) => (
                <TextField
                  type="number"
                  name={name}
                  label="Staff Strength"
                  error={
                    errors.staff_strength && errors.staff_strength.message
                      ? true
                      : false
                  }
                  helperText={
                    errors.staff_strength ? errors.staff_strength.message : null
                  }
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  style={{ width: "90%", marginTop: "10px" }}
                  defaultValue={
                    companyData.staff_strength ? companyData.staff_strength : ""
                  }
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid lg={6} sm={12} xs={12} item>
            <Controller
              control={control}
              name="annual_turnover"
              rules={{
                pattern: {
                  value: /^$|^[0-9]+(\.[0-9][0-9]){0,1}$/,
                  message: "Please enter a valid SGD amount!"
                },
                min: {
                  value: 1,
                  message: "Please enter a valid SGD amount!"
                }
              }}
              render={({ onChange, onBlur, name }) => (
                <TextField
                  name={name}
                  label="Annual Turn Over (SGD)"
                  error={
                    errors.annual_turnover && errors.annual_turnover
                      ? true
                      : false
                  }
                  helperText={
                    errors.annual_turnover
                      ? errors.annual_turnover.message
                      : null
                  }
                  inputProps={{
                    step: "1"
                  }}
                  type="number"
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  style={{ width: "90%" }}
                  defaultValue={
                    companyData.annual_turnover
                      ? companyData.annual_turnover
                      : ""
                  }
                />
              )}
            />
          </Grid>
          <Grid lg={6} sm={12} xs={12} item>
            <Controller
              control={control}
              name="paid_up_capital"
              defaultValue=""
              rules={{
                pattern: {
                  value: /^$|^[0-9]+(\.[0-9][0-9]){0,1}$/,
                  message: "Please enter a valid SGD amount!"
                },
                min: {
                  value: 1,
                  message: "Please enter a valid SGD amount!"
                }
              }}
              render={({ onChange, onBlur, name }) => (
                <TextField
                  name={name}
                  label="Paid Up Capital (SGD)"
                  error={
                    errors.paid_up_capital && errors.paid_up_capital
                      ? true
                      : false
                  }
                  helperText={
                    errors.paid_up_capital
                      ? errors.paid_up_capital.message
                      : null
                  }
                  inputProps={{
                    step: "1"
                  }}
                  type="number"
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  style={{ width: "90%" }}
                  defaultValue={
                    companyData.paid_up_capital
                      ? companyData.paid_up_capital
                      : ""
                  }
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid lg={6} sm={12} xs={12} item>
            <TextField
              inputRef={register({
                pattern: {
                  value:
                    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                  message: "Please enter a valid website!"
                }
              })}
              InputLabelProps={{
                className:
                  companyData && companyData.website
                    ? "MuiInputLabel-shrink"
                    : ""
              }}
              name="website"
              label="Website"
              error={errors.website && errors.website ? true : false}
              helperText={errors.website ? errors.website.message : null}
              style={{ width: "90%" }}
              //   defaultValue={queryParams.website}
            />
          </Grid>
          <Grid lg={6} sm={12} xs={12} item>
            <TextField
              inputRef={register({
                pattern: {
                  value:
                    /^(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?$/,
                  message: "Please enter a valid facebook url!"
                }
              })}
              InputLabelProps={{
                className:
                  companyData && companyData.facebook
                    ? "MuiInputLabel-shrink"
                    : ""
              }}
              name="facebook"
              label="Facebook"
              error={errors.facebook && errors.facebook.message ? true : false}
              helperText={errors.facebook ? errors.facebook.message : null}
              style={{ width: "90%" }}
              //   defaultValue={queryParams.facebook}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid lg={6} sm={12} xs={12} item>
            <FormControl style={{ width: "90%" }}>
              <InputLabel id="legal_structure_of_company_label">
                Legal Structure of Company
              </InputLabel>
              <Controller
                control={control}
                name="legal_structure"
                render={({ onChange, value, name }) => (
                  <MSelect
                    labelId="legal_structure_of_company_label"
                    name={name}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    // defaultValue={queryParams.legal_structure}
                  >
                    <MenuItem value={"Sole Proprietorship"}>
                      Sole Proprietorship
                    </MenuItem>
                    <MenuItem value={"Partnership"}>Partnership</MenuItem>
                    <MenuItem value={"Limited Liability Partnership"}>
                      Limited Liability Partnership
                    </MenuItem>
                    <MenuItem value={"Private Limited"}>
                      Private Limited
                    </MenuItem>
                  </MSelect>
                )}
              />
            </FormControl>
          </Grid>
          <Grid lg={6} sm={12} xs={12} item>
            <FormControl style={{ width: "90%" }}>
              <InputLabel id="ownership_type_label">Ownership Type</InputLabel>
              <Controller
                control={control}
                name="ownership_type"
                render={({ onChange, value, name }) => (
                  <MSelect
                    name={name}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    // defaultValue={queryParams.ownership_type}
                  >
                    <MenuItem
                      value={"Local (at 30% local shareholding or more)"}
                    >
                      Local (at 30% local shareholding or more)
                    </MenuItem>
                    <MenuItem
                      value={"Foreign (less than 30% local shareholding)"}
                    >
                      Foreign (less than 30% local shareholding)
                    </MenuItem>
                    <MenuItem value={"Joint-Venture"}>Joint-Venture</MenuItem>
                  </MSelect>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid lg={6} sm={12} xs={12} item>
            <FormControl style={{ width: "90%" }}>
              <InputLabel id="nature_of_business_label">
                Nature of Business
              </InputLabel>
              <Controller
                control={control}
                name="nature_of_business"
                render={({ onChange, value, name }) => (
                  <MSelect
                    name={name}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    // defaultValue={queryParams.business_nature}
                  >
                    <MenuItem value={"Business-to-Customer"}>
                      Business-to-Customer
                    </MenuItem>
                    <MenuItem value={"Business-to-Business"}>
                      Business-to-Business
                    </MenuItem>
                    <MenuItem value={"Both"}>Both</MenuItem>
                  </MSelect>
                )}
              />
            </FormControl>
          </Grid>
          <Grid lg={6} sm={12} xs={12} item>
            <FormControl
              className="MuiInput-underline"
              style={{ width: "90%" }}
            >
              <Controller
                control={control}
                name="industry_classification"
                render={({ onChange, value, name }) => (
                  <MultipleSelect
                    label="Industry Classification"
                    options={groupedIndustryData}
                    defaultValues={
                      companyData.industry_classification &&
                      companyData.industry_classification.length > 0
                        ? companyData.industry_classification.map(
                            (c) => c.title
                          )
                        : []
                    }
                    name={name}
                    onChange={onChange}
                    value={value}
                    SelectProps={{
                      msgNoOptionsAvailable: "All Industries are selected",
                      msgNoOptionsMatchFilter:
                        "No industry found with the current search!",
                      components: { DropdownIndicator },
                      styles: {
                        indicatorSeparator: () => {},
                        dropdownIndicator: (defaultStyles) => ({
                          ...defaultStyles
                          // borderBottom: "solid 0.2px rgba(0,0,0,0.54)",
                        }),
                        control: (styles) => ({
                          ...styles,
                          border: "none",
                          boxShadow: "none"
                        })
                      }
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid lg={6} sm={12} xs={12} item>
            <TextField
              InputLabelProps={{
                className:
                  companyData && companyData.principal_business_activity
                    ? "MuiInputLabel-shrink"
                    : ""
              }}
              inputRef={register({})}
              name="principal_business_activity"
              label="Principal Business Activity"
              error={
                errors.principal_business_activity &&
                errors.principal_business_activity
                  ? true
                  : false
              }
              style={{ width: "90%" }}
            />
          </Grid>
          <Grid lg={6} sm={12} xs={12} item>
            <TextField
              InputLabelProps={{
                className:
                  companyData && companyData.secondary_business_activity
                    ? "MuiInputLabel-shrink"
                    : ""
              }}
              inputRef={register({})}
              name="secondary_business_activity"
              label="Secondary Business Activity"
              error={
                errors.secondary_business_activity &&
                errors.secondary_business_activity
                  ? true
                  : false
              }
              style={{ width: "90%" }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid lg={6} sm={12} xs={12} item>
            <TextField
              InputLabelProps={{
                className:
                  companyData && companyData.company_description
                    ? "MuiInputLabel-shrink"
                    : ""
              }}
              inputRef={register({})}
              name="company_description"
              label="Company Description (Profile)"
              multiline
              rows={4}
              rowsMax={4}
              error={
                errors.company_description && errors.company_description.message
                  ? true
                  : false
              }
              style={{ width: "90%" }}
              //   defaultValue={queryParams.company_description}
            />
          </Grid>
          <Grid
            lg={6}
            sm={12}
            xs={12}
            item
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center"
            }}
          >
            <Button
              variant="contained"
              size="large"
              color="secondary"
              style={{ width: "300px" }}
              type="submit"
              //   disabled={registering}
            >
              Update Info
            </Button>
          </Grid>
        </Grid>
      </form>

      <Snackbar
        open={hasData}
        autoHideDuration={3000}
        onClose={() => setHasData(false)}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top"
        }}
      >
        <Alert onClose={() => setHasData(false)} severity="success">
          Data Updated Successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={hasError}
        autoHideDuration={3000}
        onClose={() => setHasError(false)}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top"
        }}
      >
        <Alert onClose={() => setHasError(false)} severity="error">
          There was some internal server error while processing your request.
          Please try again!
        </Alert>
      </Snackbar>

      <Snackbar
        open={hasUENError}
        autoHideDuration={3000}
        onClose={() => setHasUENError(false)}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top"
        }}
      >
        <Alert onClose={() => setHasError(false)} severity="error">
          The Company UEN is already being used!
        </Alert>
      </Snackbar>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </>
  );
}

export default EditOrganizationInfo;
