import React, { useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import "../styles/special-promotion.css";
import { useAuthedAxios } from "../../../util/axios";

function ProfilePending() {
  const history = useHistory();

  const member = useSelector((state) => state.auth.user.member);

  const [{ data: organization }] = useAuthedAxios(
    `/organizations/${member.organization}`
  );

  useEffect(() => {
    if (organization && organization.membership_status !== "Pending") {
      history.push(`/`);
    } else {
      localStorage.removeItem("isPaymentInitiated");
    }
  }, [history, organization]);

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Processing Membership
      </Typography>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        <div className="ck-content">
          <p>
            Thank you. <br />
            Your payment is still being processed by ASME.
          </p>
        </div>
      </Paper>
    </div>
  );
}

export default ProfilePending;
