import React, { useEffect, useState } from "react";
import { Paper, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { Alert } from "@material-ui/lab";
import { Link } from "react-router-dom";
import ItemsCarousel from "react-items-carousel";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import useAxios from "axios-hooks";
import ReactMarkdown from "react-markdown";
import { isMobile } from "react-device-detect";

import {
  ASME_SITE_BASE_URL,
  authedAxios,
  BASE_URL,
  useAuthedAxios,
  usePublicAxios
} from "../../../util/axios";
import "../styles/home-content.css";

import HighlightCard from "../../../util/HighlightCard";
import Polls from "./Polls/Polls";
import PollResults from "./Polls/PollResults";
import ImgMediaCard from "../../../components/Events/ImgMediaCard";

import { FETCH_HIGHTLIGHT_FORMS } from "./new-home.graphql";
import { FETCH_MAIN_POLL } from "./Poll/polls.graphql";
import RichTextRenderer from "../../../util/RichTextRenderer";

function MemberHome() {
  const member = useSelector((state) => state.auth.user.member);

  const [activeItemIndexOfEvents, setActiveItemIndexOfEvents] = useState(0);
  const [activeItemIndexOfHighlights, setActiveItemIndexOfHighlights] =
    useState(0);
  const [isPollFilled, setIsPollFilled] = useState(false);
  const [highlightsData, setHighlightsData] = useState([]);
  const [open, setOpen] = useState(true);

  const chevronWidth = 40;

  const { data } = useQuery(FETCH_MAIN_POLL, {
    variables: {
      main: "true"
    },
    pollInterval: 2000
  });

  const [{ data: organization }] = useAuthedAxios(
    `/organizations/${member.organization}`
  );

  const [{ data: announcement }] = useAuthedAxios(`/members-announcement`);

  const [{ data: eventsData, loading: fetchingEvents }, getEvents] =
    usePublicAxios(
      `${ASME_SITE_BASE_URL}/events-v-2-s?_sort=date&date_gte=${moment().format(
        "YYYY-MM-DD"
      )}`,
      { manual: true }
    );

  const [
    {
      data: highlightEventsData,
      loading: fetchingHighlightEvents,
      error: highlightEventsError
    },
    getHighlightEvents
  ] = usePublicAxios(
    `${ASME_SITE_BASE_URL}/events-v-2-s?_sort=date&isHighlight=true&_limit=${12}&_start=${
      (1 - 1) * 12
    }`,
    { manual: true }
  );

  const { data: highlightFormsData } = useQuery(FETCH_HIGHTLIGHT_FORMS, {
    variables: {
      isHighlight: "true"
    }
  });

  const [
    { data: highlightResourcesData, loading, error },
    getHighlightResourcesData
  ] = useAxios(
    {
      url: `${ASME_SITE_BASE_URL}/newsrooms?_sort=date:DESC&isHighlight=true`,
      method: "GET"
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    getEvents();
    getHighlightEvents();
    getHighlightResourcesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (highlightFormsData && highlightEventsData && highlightResourcesData) {
      (async function () {
        authedAxios
          .get(`/listing-builders/getAllRewards`, {
            withCredentials: true
          })
          .then((res) => {
            const { data } = res;

            setHighlightsData([
              ...highlightFormsData.forms.map((item) => {
                return { ...item, type: "Form" };
              }),
              ...highlightEventsData.map((item) => {
                return { ...item, type: "Event" };
              }),
              ...data
                .filter((item) => item.isHighlight)
                .map((item) => {
                  return { ...item, type: "Reward" };
                }),
              ...highlightResourcesData.map((item) => {
                return { ...item, type: "Newsroom" };
              })
            ]);
          });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightFormsData, highlightEventsData, highlightResourcesData]);

  useEffect(() => {
    if (data && data.poll.length > 0) {
      const { form: formArray } = data.poll[0];

      if (formArray && formArray.length === 0) {
        return;
      } else {
        const { submissions } = data.poll[0];

        if (
          submissions &&
          submissions.length > 0 &&
          submissions.find(
            (submission) => parseInt(submission.member?.id) === member.id
          )
        ) {
          //member has already filled poll
          setIsPollFilled(true);
        } else {
          setIsPollFilled(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isMembershipExpiringSoon = (expiry) => {
    var expiryDate = moment(expiry, "YYYY-MM-DD");
    var currentDate = moment(new Date()).format("YYYY-MM-DD");

    return expiryDate.diff(currentDate, "days") < 90;
  };

  const renderHighlights = (data) => {
    switch (data.type) {
      case "Event":
        return (
          <HighlightCard
            key={data.id}
            title={data.eventname}
            date={data.date}
            image={
              data.tileImage
                ? ASME_SITE_BASE_URL + data.tileImage[0]?.url
                : "/images/logo.png"
            }
            link={`/dashboard/events/${data.id}`}
            type={data.type}
          />
        );
      case "Reward":
        return (
          <HighlightCard
            key={data.id}
            title={data.title}
            date={data.end_date}
            image={
              data.thumbnail
                ? `${BASE_URL}${data.thumbnail.url}`
                : "/images/reward.jpg"
            }
            link={`/dashboard/rewards`}
            type={data.type}
          />
        );
      case "Form":
        return (
          <HighlightCard
            key={data.id}
            title={data.title}
            date={data.end_date}
            image={`/images/logo.png`}
            link={`/form/${data.slug}`}
            type={data.type}
          />
        );
      case "Newsroom":
        return (
          <HighlightCard
            key={data.id}
            title={data.title}
            date={data.date}
            image={
              data.tileImage
                ? ASME_SITE_BASE_URL + data.tileImage[0]?.url
                : "/images/logo.png"
            }
            link={`/newsroom/${data.id}`}
            type={data.type}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Welcome To Your Membership Portal
      </Typography>
      {organization && isMembershipExpiringSoon(organization.expiry_date) && (
        <Collapse in={open}>
          <Alert
            style={{
              marginTop: "40px",
              color: "#DE1736",
              backgroundColor: "#fff"
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            severity="error"
          >
            <Typography
              // variant="h4"
              color="secondary"
              style={{ fontWeight: "500" }}
            >
              <strong>Note</strong>: Your Membership is expiring soon.{" "}
              <strong>
                <Link to="/dashboard/renew-membership">Click here</Link>
              </strong>{" "}
              to Renew Now.
            </Typography>
          </Alert>
        </Collapse>
      )}

      <div
        style={{
          marginTop: "40px"
        }}
      >
        <div>
          <div
            style={{
              flex: 4
            }}
            className="ck-content"
          >
            {announcement?.content && (
              <>
                <Typography
                  variant="h4"
                  color="secondary"
                  style={{
                    paddingBottom: "1rem",
                    fontWeight: "bold"
                  }}
                >
                  Announcements
                </Typography>
                <Paper
                  elevation={3}
                  style={{
                    padding: "10px",
                    marginBottom: "1rem"
                  }}
                >
                  <RichTextRenderer content={announcement?.content} />
                </Paper>
              </>
            )}

            <Typography
              variant="h4"
              color="secondary"
              style={{
                paddingBottom: "1rem",
                fontWeight: "bold"
              }}
            >
              Current Events
            </Typography>
            <div
              style={{
                width: "100%",
                overflow: "auto"
              }}
            >
              {fetchingEvents ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <div
                  style={{
                    padding: `0 ${chevronWidth}px`,
                    width: isMobile
                      ? "unset"
                      : data && data.poll.length > 0
                      ? "750px"
                      : "990px" // width: unset if mobile device
                  }}
                >
                  <ItemsCarousel
                    requestToChangeActive={setActiveItemIndexOfEvents}
                    activeItemIndex={activeItemIndexOfEvents}
                    numberOfCards={isMobile ? 1 : 3} // number of cards = 1 if mobile device
                    gutter={20}
                    leftChevron={<ArrowBackIosIcon color="secondary" />}
                    rightChevron={<ArrowForwardIosIcon color="secondary" />}
                    outsideChevron
                    chevronWidth={chevronWidth}
                  >
                    {(eventsData || []).map((event) => {
                      return (
                        <ImgMediaCard
                          key={event.id}
                          data={event}
                          url={ASME_SITE_BASE_URL}
                        />
                      );
                    })}
                  </ItemsCarousel>
                </div>
              )}
            </div>
          </div>

          <Typography
            variant="h4"
            color="secondary"
            style={{
              paddingBottom: "1rem",
              paddingTop: "1rem",
              fontWeight: "bold"
            }}
          >
            Highlights
          </Typography>
          <div
            style={{
              width: "100%",
              overflow: "auto"
            }}
          >
            {fetchingEvents ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <div
                style={{
                  padding: `0 ${chevronWidth}px`,
                  width: isMobile
                    ? "unset"
                    : data && data.poll.length > 0
                    ? "750px"
                    : "990px" // width: unset if mobile device
                }}
              >
                <ItemsCarousel
                  requestToChangeActive={setActiveItemIndexOfHighlights}
                  activeItemIndex={activeItemIndexOfHighlights}
                  numberOfCards={isMobile ? 1 : 3} // number of cards = 1 if mobile device
                  gutter={20}
                  leftChevron={<ArrowBackIosIcon color="secondary" />}
                  rightChevron={<ArrowForwardIosIcon color="secondary" />}
                  outsideChevron
                  chevronWidth={chevronWidth}
                >
                  {(highlightsData || []).map((highlight) =>
                    renderHighlights(highlight)
                  )}
                </ItemsCarousel>
              </div>
            )}
          </div>

          {data &&
            data.poll.length > 0 &&
            (isPollFilled ? (
              <div style={{ flex: 1 }}>
                <Typography
                  variant="h4"
                  color="secondary"
                  style={{
                    fontWeight: "bold"
                  }}
                >
                  ASME Polls
                </Typography>
                <PollResults data={data} />
              </div>
            ) : (
              <div style={{ flex: 1 }}>
                <Typography
                  variant="h4"
                  color="secondary"
                  style={{
                    fontWeight: "bold"
                  }}
                >
                  ASME Polls
                </Typography>
                <Polls data={data} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default MemberHome;
