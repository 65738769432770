import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../containers/Auth/auth.slice";
import rewardsReducer from "../containers/Member/Routes/Rewards/rewards.slice";

export default configureStore({
  reducer: {
    auth: authReducer,
    rewards: rewardsReducer
  }
});
