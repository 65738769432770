import React from "react";

function ReferralsInfo({ classes, referrals }) {
  return (
    <table
      style={{
        borderCollapse: "collapse",
        wordWrap: "break-word",
        tableLayout: "fixed",
      }}
      cellPadding="10"
    >
      <tbody>
        <tr>
          <td
            className={classes.infoCellStyle}
            style={{
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
            }}
          >
            Total
          </td>
          <td className={classes.infoCellLeftMargin}>{referrals.total}</td>
        </tr>
        <tr>
          <td className={classes.infoCellStyle}>Active</td>
          <td className={classes.infoCellLeftMargin}>{referrals.accepted}</td>
        </tr>
        <tr>
          <td className={classes.infoCellStyle}>Pending</td>
          <td className={classes.infoCellLeftMargin}>{referrals.pending}</td>
        </tr>
      </tbody>
    </table>
  );
}

export default ReferralsInfo;
