import React from "react";
import { useHistory } from "react-router-dom";
import { authedAxios } from "../../util/axios";
import MemberContainer from "../Member/member";

function Dashboard() {
  const history = useHistory();

  const onLogout = () => {
    authedAxios.post("/logout").finally(() => {
      localStorage.removeItem("member_token");
      window.location.reload();
    });
  };

  if (localStorage.getItem("redirect_to")) {
    const redirect_to = localStorage.getItem("redirect_to");
    localStorage.removeItem("redirect_to");
    history.push(redirect_to);
  }

  return <MemberContainer logout={onLogout} />;
}

export default Dashboard;
