import React, { useEffect, useState } from "react";
import { Grid, LinearProgress, Paper, Typography } from "@material-ui/core";

const PollResults = ({ data }, id) => {
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (data && data.poll[0]) setTotalCount(getTotalCount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data.poll[0]]);

  if (!data) return null;

  function getCount(id) {
    let count = 0;

    data &&
      data.poll[0].submissions.length > 0 &&
      data.poll[0].submissions.map((subm) =>
        subm.submission[id] ? count++ : null
      );
    return count;
  }

  function getTotalCount() {
    let totalCount = 0;

    data.poll[0].form.length > 0 &&
      data.poll[0].form.map(({ id }) => (totalCount += getCount(id)));

    return totalCount;
  }

  return (
    <Paper
      elevation={3}
      style={{ paddingLeft: "1rem", paddingBottom: "0.5rem", flex: 1 }}
    >
      {/* <Typography
        variant="h4"
        color="secondary"
        style={{ paddingBottom: "1rem", paddingTop: "1rem" }}
      >
        ASME Polls
      </Typography> */}
      {/* <hr
        style={{
          marginLeft: "2%",
          marginRight: "10%"
        }}
      /> */}
      <h4 style={{ paddingTop: "1rem" }}>{data.poll[0].title}</h4>
      <Grid container>
        <form style={{ width: "100%" }}>
          {data.poll[0].form.map((item) => (
            <Grid item xs={12} key={item.id} style={{ padding: "10px 0" }}>
              {item.settings.title}
              <div
                style={{
                  position: "absolute",
                  // width: "100%",
                  height: "100%",
                  zIndex: 1,
                  maxHeight: "1.25rem", // borderlinearprogress root.height
                  // textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  "& span": {
                    width: "100%"
                  }
                }}
              >
                <span className="poll-percentage">
                  {totalCount
                    ? parseInt((getCount(item.id) * 100) / totalCount)
                    : 0}
                  %
                </span>
              </div>
              <LinearProgress
                variant="determinate"
                style={{ marginRight: "1rem" }}
                value={totalCount ? (getCount(item.id) * 100) / totalCount : 0}
              />
              {/* <LinearProgress
                style={{
                  marginRight: "1rem"
                }}
                variant="determinate"
                value={totalCount ? (getCount(item.id) * 100) / totalCount : 0}
              />
              <span className="poll-percentage">
                {totalCount
                  ? parseInt((getCount(item.id) * 100) / totalCount)
                  : 0}
                %
              </span> */}
            </Grid>
          ))}
        </form>
      </Grid>
    </Paper>
  );
};

export default PollResults;
