import React, { useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import ItemsCarousel from "react-items-carousel";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { useAuthedAxios, BASE_URL } from "../../../../util/axios";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    marginTop: "40px",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    display: "flex",
    justifyContent: "center"
  }
});

const chevronWidth = 40;
// const autoPlayDelay = 2000;
const noOfCards = 1;

function SpecialPromotions() {
  const classes = useStyles();
  const history = useHistory();

  const [
    activeItemIndexOfSpecialPromotion,
    setActiveItemIndexOfSpecialPromotion
  ] = useState(0);

  const [{ data, loading, error }] = useAuthedAxios(`/special-promotions`);

  //   useEffect(() => {
  //     if (!data) return;
  //     const interval = setInterval(
  //       () =>
  //         setActiveItemIndexOfSpecialPromotion(
  //           (prevState) => (prevState + 1) % (data.length - noOfCards + 1)
  //         ),
  //       autoPlayDelay
  //     );
  //     return () => clearInterval(interval);
  //   }, [data]);

  //   const tick = () =>
  //     setActiveItemIndexOfSpecialPromotion(
  //       (prevState) => (prevState + 1) % (data.length - noOfCards + 1)
  //     );

  const goToRoute = (promotion) => {
    history.push(`/dashboard/special-promotion/${promotion.id}`);
  };

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Special Promotion
      </Typography>

      <Grid container className={classes.root} spacing={1}>
        {loading ? (
          <div
            style={{
              width: "100%",
              // height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <div
            style={{
              padding: `0 ${chevronWidth}px`,
              width: "1000px"
            }}
          >
            <ItemsCarousel
              gutter={20}
              requestToChangeActive={setActiveItemIndexOfSpecialPromotion}
              activeItemIndex={activeItemIndexOfSpecialPromotion}
              numberOfCards={noOfCards}
              leftChevron={<ArrowBackIosIcon color="secondary" />}
              rightChevron={<ArrowForwardIosIcon color="secondary" />}
              outsideChevron
              chevronWidth={chevronWidth}
            >
              {(data || []).map((promotion) => (
                <Paper
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "20px",
                    justifyContent: "center"
                  }}
                >
                  <img
                    onClick={() => goToRoute(promotion)}
                    key={promotion.id}
                    width="870"
                    height="450"
                    src={BASE_URL + promotion.image.url}
                    alt="special promotion"
                  />
                </Paper>
              ))}
            </ItemsCarousel>
          </div>
        )}
      </Grid>
    </div>
  );
}

export default SpecialPromotions;
