import React from "react";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { Link as RouterLink, useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";

import { CHECKBOX, CHECKBOX_GROUP } from "../../../../../app/constants";
import CheckboxGroupRenderer from "./CheckboxGroup";
import CheckboxRenderer from "./Checkbox";
import { authedAxios, usePublicAxios } from "../../../../../util/axios";

export const renderItem = (
  { type, id, settings },
  { register, setValue, watch, errors }
) => {
  switch (type) {
    case CHECKBOX:
      return (
        <CheckboxRenderer id={id} settings={settings} register={register} />
      );
    case CHECKBOX_GROUP:
      return (
        <CheckboxGroupRenderer
          id={id}
          settings={settings}
          setValue={setValue}
          register={register}
          watch={watch}
          errors={errors}
        />
      );
    default:
      return "";
  }
};

const getChangedPrefilledData = (
  newEntries,
  formBuilder,
  { member, organization }
) => {
  const prefilledEntries = formBuilder.filter((item) => item.settings.prefill);
  const updatedMembersData = {};
  const updatedOrganizationsData = {};
  prefilledEntries.forEach((item) => {
    const attribute = item.settings.prefill.attribute;
    const ref = item.settings.prefill.ref;
    if (ref === "members") {
      if (member[attribute] !== newEntries[item.id]) {
        updatedMembersData[attribute] = newEntries[item.id];
      }
    } else if (ref === "organizations") {
      if (organization[attribute] !== newEntries[item.id]) {
        updatedOrganizationsData[attribute] = newEntries[item.id];
      }
    }
  });

  return { updatedMembersData, updatedOrganizationsData };
};

function FormRenderer({
  form: formBuilder,
  id,
  isPoll,
  isPublicPoll,
  getPollSubmission,
  allowMultipleSelect
}) {
  const { enqueueSnackbar } = useSnackbar();

  const user = useSelector((state) => state.auth.user);
  let member;
  if (user) member = user.member;
  const { register, handleSubmit, setValue, watch, errors } = useForm();
  const { slug } = useParams();
  const history = useHistory();

  const isPollSubmittedFromLocalSlug = localStorage.getItem(`${slug}`);

  const [
    { loading: submitting, data: submissionData, error: submissionError },
    sendSubmission
  ] = usePublicAxios(
    {
      url: "/poll-submissions",
      method: "POST"
    },
    {
      manual: true
    }
  );

  React.useEffect(() => {
    if (submissionData && isPublicPoll && !user) {
      localStorage.setItem(`${slug}`, true);
      // window.location.reload(); // To Fetch Poll Results
      history.push(`/polls/${slug}`);
    }
    if ((submissionData && user) || (submissionData && user && isPublicPoll)) {
      getPollSubmission();
    }
    if (submissionData && !isPublicPoll && !user) {
      localStorage.setItem(`${slug}`, true);
    }
  }, [isPublicPoll, user, submissionData, history, slug, getPollSubmission]);

  const onSubmit = async (values) => {
    const { updatedMembersData } = getChangedPrefilledData(
      values,
      formBuilder,
      {
        member
        //   organization: data,
      }
    );
    if (Object.keys(updatedMembersData).length > 0) {
      try {
        delete updatedMembersData.email; // This ensures, we are not updating user's data.

        await authedAxios.put(`/members/${member.id}`, updatedMembersData);
        // console.log("Updated Member's Data");
      } catch (e) {
        // console.log("Error while updating member's data", e);
      }
    }

    const formData = new FormData();
    const dataObj = {};
    Object.entries(values).forEach(([id, value]) => {
      if (!(value instanceof FileList)) {
        dataObj[id] = value;
      } else {
        if (value.length > 0) {
          formData.append("files.files", value[0], id + "___" + value[0].name);
        }
      }
    });

    const occurances = onlyOneCheckboxTest(Object.values(dataObj), true);
    if (!allowMultipleSelect && occurances !== 1)
      return enqueueSnackbar("Please select only one option", {
        variant: "error"
      });

    let formDataToSubmit = {
      submission: dataObj,
      member: member && member.id
    };
    formDataToSubmit.poll = id;
    formData.append("data", JSON.stringify(formDataToSubmit));
    sendSubmission({
      data: formData
    });
  };

  function onlyOneCheckboxTest(arr, testFor) {
    var counts = {};

    for (var i = 0; i < arr.length; i++) {
      var num = arr[i];
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    }

    return counts[testFor];
  }

  return (
    <Grid container>
      {submitting ? (
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <CircularProgress color="secondary" />
        </Grid>
      ) : submissionError ? (
        <Alert severity="error">
          There was some error while communiating with server. Please try again
          later!
        </Alert>
      ) : null}

      {!submitting && !submissionData ? (
        !isPollSubmittedFromLocalSlug ? (
          <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
            {formBuilder.map((item) => (
              <Grid item xs={12} key={item.id} style={{ display: "flex" }}>
                {renderItem(item, { register, setValue, watch, errors })}
              </Grid>
            ))}
            <Grid
              item
              xs={12}
              style={{
                textAlign: "right",
                marginBottom: "1rem"
              }}
            >
              <Button variant="contained" type="submit" color="secondary">
                Submit
              </Button>
            </Grid>
          </form>
        ) : (
          <Grid
            style={{
              marginRight: isPoll ? "1rem" : "",
              marginBottom: isPoll ? "1rem" : ""
            }}
            item
            xs={12}
          >
            {!isPublicPoll && !user && (
              <div style={{ textAlign: "left" }}>
                Thank you for your submission!
                <br />
                <br />
                <strong>
                  <RouterLink
                    to="/"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Login
                  </RouterLink>
                </strong>{" "}
                to find out what others answered.
              </div>
            )}
          </Grid>
        )
      ) : null}

      {submissionData && (
        <Grid
          style={{
            marginRight: isPoll ? "1rem" : "",
            marginBottom: isPoll ? "1rem" : ""
          }}
          item
          xs={12}
        >
          {!isPublicPoll && !user && (
            <div style={{ textAlign: "left" }}>
              Thank you for your submission!
              <br />
              <br />
              <strong>
                <RouterLink
                  to="/"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Login
                </RouterLink>
              </strong>{" "}
              to find out what others answered.
            </div>
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default FormRenderer;
