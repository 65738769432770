import { gql } from "@apollo/client";

export const GET_FORM = gql`
  query ($slug: ID!) {
    pollBuilder(slug: $slug) {
      id
      title
      description
      slug
      start_date
      end_date
      form
      status
      main_poll
      available_to {
        public
        members_only
      }
    }
  }
`;

export const FETCH_POLL = gql`
  query ($slug: String) {
    poll: pollBuilders(where: { status: "Publish", slug: $slug }) {
      id
      title
      allow_multiple_select
      slug
      description
      form
      available_to
      image {
        id
        url
        name
      }
      end_date
      submissions {
        submission
        member {
          id
        }
      }
    }
  }
`;

export const FETCH_MAIN_POLL = gql`
  query ($main: String) {
    poll: pollBuilders(where: { status: "Publish", main_poll: $main }) {
      id
      title
      allow_multiple_select
      description
      form
      end_date
      submissions {
        submission
        member {
          id
        }
      }
    }
  }
`;
