import { requestLogin, successLogin, errorLogin } from "./auth.slice";
import { axios } from "../../util/axios";

export const login = (username, password) => (dispatch) => {
  dispatch(requestLogin());

  axios
    .post(
      "/auth/members/local",
      {
        identifier: username,
        password,
      },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      const { data } = res;

      if (!data.user.confirmed) {
        dispatch(
          errorLogin(
            "Your account is not confirmed yet. Please contact the support!"
          )
        );
      } else if (!data.user.role) {
        dispatch(
          errorLogin(
            "Your account is not associated with any role. Please contact the support!"
          )
        );
      } else {
        const user = data.user;
        localStorage.setItem("member_token", data.jwt);
        dispatch(
          successLogin({
            user,
          })
        );
        window.location.reload(); // To Reattach token with Authed Axios
      }
    })
    .catch((err) => {
      console.log(err);
      localStorage.removeItem("member_token");
      dispatch(errorLogin(err?.response?.data));
    });
};
