import React, { useEffect, useState } from "react";
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
  Avatar,
  CircularProgress,
  Menu,
  MenuItem,
  Paper,
  Grid,
  Button
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import DateRangeIcon from "@material-ui/icons/DateRange";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import {
  useHistory,
  useParams,
  Route,
  Switch,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import { useSelector } from "react-redux";
import { Home, Menu as MenuIcon } from "@material-ui/icons";
import clsx from "clsx";
import PermDeviceInformationIcon from "@material-ui/icons/PermDeviceInformation";
import EventIcon from "@material-ui/icons/Event";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import EventNoteIcon from "@material-ui/icons/EventNote";
import RedeemIcon from "@material-ui/icons/Redeem";

import {
  ASME_SITE_URL,
  BASE_URL,
  useAuthedAxios
} from "../../../../util/axios";
import {
  API_ERROR,
  HEADER_COLOR,
  SECONDARY_COLOR
} from "../../../../app/constants";
import { useWorkshop } from "./useWorkshop";
import Tag from "./tag-ui";
import moment from "moment";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    backgroundColor: HEADER_COLOR,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    backgroundColor: HEADER_COLOR,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    paddingTop: "20px",
    paddingBottom: "20px",

    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "#E6E6E6",
    minHeight: "100vh"
  },
  container: {
    border: "1px solid lightgray",
    marginRight: "5px",
    padding: "20px",
    borderRadius: "8px"
  },
  details: {
    padding: "15px",
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  button: {
    marginTop: "auto",
    backgroundColor: "#002e5d",
    color: "white",
    width: "100%",
    borderRadius: "0px",
    "&:hover": {
      backgroundColor: "#002e5d"
    }
  }
}));

const FieldValue = ({ icon, children }) => (
  <div
    style={{
      display: "flex",
      gap: "8px",
      margin: "10px 0px"
    }}
  >
    {icon} {children}
  </div>
);

const WorkshopContent = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const [menu, setMenu] = useState([]);
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const member = useSelector((state) => state?.auth?.user?.member);
  const isLoggedIn = useSelector((state) => state?.auth?.user !== null);

  const { data, loading, error } = useWorkshop(isLoggedIn, id);

  const [{ data: organization }] = useAuthedAxios(
    `/organizations/${member?.organization}`
  );

  const [{ error: paymentError }, pay] = useAuthedAxios(
    "/workshops/checkWorkshopPayment",
    { manual: true }
  );

  useEffect(() => {
    if (paymentError) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  }, [paymentError]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsFetching(true);
  }, [isLoggedIn]);

  useEffect(() => {
    let _menu = [
      {
        title: "Home",
        path: "/dashboard",
        icon: <Home />
      },
      {
        title: "Events",
        path: "/dashboard/events",
        icon: <EventIcon />
      },
      {
        title: "Special Promotion",
        path: "/dashboard/special-promotion",
        icon: (
          <img
            src={require("../../images/diamond-shape.png")}
            width="18px"
            height="18px"
            alt="Icon"
            style={{ marginLeft: "3px" }}
          />
        )
      },
      {
        title: "Members Rewards",
        path: "/dashboard/rewards",
        icon: <RedeemIcon />
      },
      {
        title: "Newsroom",
        path: "/dashboard/newsroom",
        icon: <BookmarksIcon />
      },
      {
        title: "Surveys/Forms",
        path: "/dashboard/surveys",
        icon: <PermDeviceInformationIcon />
      },
      // {
      //   title: "Programs",
      //   path: "/dashboard/programs",
      //   icon: <EventNoteIcon />
      // },
      // {
      //   title: "Announcements",
      //   path: "/dashboard/announcements",
      //   icon: <BookmarksIcon />
      // },
      {
        title: "Workshops/Training",
        path: "/dashboard/workshops",
        icon: <EventNoteIcon />
      },
      {
        title: "Reviews",
        path: "/dashboard/reviews",
        icon: <EventNoteIcon />
      }
    ];

    if (
      organization &&
      // organization.membership_type &&
      organization?.membership_type !== "Associate" &&
      organization?.membership_type !== "Platinum"
    ) {
      _menu.splice(_menu.length, 0, {
        title: "Upgrade Membership",
        path: "/dashboard/membership",
        icon: (
          <img
            src={require("../../images/crown.png")}
            width="18px"
            height="18px"
            alt="Icon"
            style={{ marginLeft: "3px" }}
          />
        )
      });
      setMenu(_menu);
    } else {
      _menu.splice(_menu.length, 0, {
        title: "ASME Membership",
        path: "/auth/register",
        icon: (
          <img
            src={require("../../images/crown.png")}
            width="18px"
            height="18px"
            alt="Icon"
            style={{ marginLeft: "3px" }}
          />
        )
      });
      setMenu(_menu);
    }
    setMenu(_menu);
  }, [organization]);

  useEffect(() => {
    if (data) document.title = data?.title || "Members Portal";

    return () => {
      document.title = "Members Portal";
    };
  }, [data]);

  const handleWorkshopSignup = async () => {
    try {
      if (!isLoggedIn)
        return history.push(
          `/form/${data?.form_builder?.slug}?workshopId=${id}`
        );

      await pay({
        method: "POST",
        data: { workshop: data }
      });

      history.push(`/form/${data?.form_builder?.slug}?workshopId=${id}`);
    } catch (error) {}
  };

  const onMenuSelect = (menuItem) => {
    history.push(menuItem?.path);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event?.currentTarget);
    setMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const renderReviewData = () => {
    if (!isFetching) return "";

    if (loading) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: "100vh" }}
        >
          <CircularProgress color="secondary" />
        </Grid>
      );
    }

    if (!loading && !data) {
      return (
        <div>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error
              ? error?.response?.data?.message
              : "This is an error while loading the content. Please try reloading the page!"}
          </Alert>
        </div>
      );
    }

    if (!loading && error) {
      return (
        <Grid container justify="center" style={{ padding: "10px" }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Grid container className={classes.container}>
              <Grid item xs={12}>
                <Alert severity="error">
                  There was some internal server error while fetching the
                  review. Please try again later!
                </Alert>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <>
        <Grid container spacing={3}>
          {paymentError && (
            <div
              style={{
                marginBottom: "2rem",
                width: "100%"
              }}
            >
              <Alert severity="error">
                {paymentError?.response?.data?.message || API_ERROR}
              </Alert>
            </div>
          )}
          <Grid
            item
            xs={12}
            md={8}
            style={{
              padding: 0
            }}
          >
            <img
              style={{ width: "100%", height: "auto", maxHeight: "700px" }}
              src={
                data?.thumbnail
                  ? `${BASE_URL}${data?.thumbnail?.url}`
                  : "/images/reward.jpg"
              }
              alt={data?.title}
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.details}>
            <div>
              <Tag>{data?.tag}</Tag>
              <Typography gutterBottom variant="h6" component="h4">
                {data?.title}
              </Typography>
              <Typography variant="subtitle2" component="p">
                <FieldValue icon={<DateRangeIcon />}>
                  {moment(data?.date).format("ddd, Do MMM 'YY")}
                </FieldValue>
              </Typography>

              <Typography variant="subtitle2" component="p">
                <FieldValue icon={<LocationOnIcon />}>
                  {data?.location}
                </FieldValue>
              </Typography>

              <Typography
                style={{
                  fontWeight: "bold"
                }}
                variant="subtitle2"
                component="p"
              >
                <FieldValue icon={<MonetizationOnIcon />}>
                  S${data?.price}
                </FieldValue>
              </Typography>

              <Typography
                style={{
                  fontWeight: "bold"
                }}
                variant="subtitle1"
                component="p"
              >
                {data?.description}
              </Typography>
            </div>

            <Button
              size="small"
              className={classes.button}
              variant="contained"
              onClick={() => handleWorkshopSignup(data)}
            >
              Sign Up Now
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
        color="transparent"
        elevation={0}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
            size="small"
            style={{
              background: "white",
              color: HEADER_COLOR
            }}
            elevation={4}
          >
            <MenuIcon />
          </IconButton>
          {open && (
            <IconButton
              color="secondary"
              style={{
                background: "white",
                color: HEADER_COLOR,
                marginLeft: "-39px"
              }}
              size="small"
              onClick={handleDrawerClose}
              elevation={4}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        elevation={3}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
        PaperProps={{ elevation: 10 }}
      >
        <div
          className={classes.toolbar}
          style={
            !open
              ? {
                  marginTop: "40px"
                }
              : null
          }
        >
          <img
            src="/images/logo.png"
            width={open ? "70px" : "30px"}
            alt="ASME Logo"
          />
        </div>
        <Divider />
        <List
          style={{ paddingLeft: "7px" }}
          dense
          className="mobile-main-menup-padding"
        >
          {menu.map((item) => (
            <ListItem
              button
              key={item.title}
              onClick={() => onMenuSelect(item)}
            >
              <ListItemIcon
                style={{
                  color: "#fff",
                  minWidth: open ? "32px" : "56px"
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{
                  style: {
                    fontWeight:
                      pathname === item.path
                        ? "bolder"
                        : pathname.includes("/newsroom") &&
                          item.path === "/dashboard/newsroom"
                        ? "bolder"
                        : 500,
                    color: "#fff"
                  }
                }}
              />
            </ListItem>
          ))}
          <ListItem
            button
            key={"Merchant Portal"}
            onClick={() =>
              (window.location.href = `${ASME_SITE_URL}/merchant-view`)
            }
          >
            <ListItemIcon
              style={{
                color: SECONDARY_COLOR,
                minWidth: open ? "32px" : "56px"
              }}
            >
              <img
                src={require("../../images/diamond-shape.png")}
                width="18px"
                height="18px"
                alt="Icon"
                style={{ marginLeft: "3px" }}
              />
            </ListItemIcon>
            <ListItemText
              primary={"Merchant Portal"}
              primaryTypographyProps={{
                style: {
                  fontWeight:
                    pathname === true
                      ? "bolder"
                      : pathname.includes("/newsroom") &&
                        true === "/dashboard/newsroom"
                      ? "bolder"
                      : 500,
                  color: "#fff"
                }
              }}
            />
          </ListItem>
        </List>
        {/* <Divider /> */}
      </Drawer>
      <main className={classes.content}>
        {member ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "baseline",
              justifyContent: "flex-end",
              zIndex: 1202,
              position: "relative"
            }}
            className="mobile-width"
          >
            <Typography variant="h6" style={{ marginRight: "10px" }}>
              Welcome, {member ? member?.first_name : "User"}
            </Typography>
            <div>
              <Avatar
                style={{ background: "white", color: HEADER_COLOR }}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
              >
                {member?.first_name[0]?.toUpperCase()}
              </Avatar>

              {member && (
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  open={menuOpen}
                  onClose={handleClose}
                >
                  {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
    <MenuItem onClick={handleClose}>My account</MenuItem>
    <Divider /> */}
                  <MenuItem
                    onClick={() => {
                      history.push("/dashboard/organisation");
                      handleClose();
                    }}
                  >
                    Organisation Info
                  </MenuItem>
                  <MenuItem onClick={() => {}}>Logout</MenuItem>
                </Menu>
              )}
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "baseline",
              justifyContent: "flex-end",
              zIndex: 1202,
              position: "relative"
            }}
            className="mobile-width"
          >
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={() => history.push("/auth/login")}
            >
              Login
            </Button>
          </div>
        )}

        <div className={classes.toolbar} />

        <Switch>
          <Route exact path={`${path}`}>
            <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
              {renderReviewData()}
            </Paper>
          </Route>
        </Switch>
      </main>
    </div>
  );
};

export default WorkshopContent;
