import React, { useEffect, useState } from "react";
import { Button, Modal, Paper, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import useSWR from "swr";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";

import "../styles/special-promotion.css";
import { authedAxios, useAuthedAxios } from "../../../util/axios";
import PostPayment from "./post-payment";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flex: 1,
    backgroundColor: "#002E5D"
  },
  modalPaper: {
    position: "absolute",
    textAlign: "center",
    fontWeight: 500,
    fontSize: "1.15rem",
    // width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    border: "none"
  }
}));

function getModalStyle() {
  const top = 25;
  // const left = 50;

  return {
    top: `${top}%`,
    margin: "auto",
    // left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
    outline: "none"
  };
}

function Onboarding() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [modalStyle] = React.useState(getModalStyle);

  const member = useSelector((state) => state.auth.user.member);

  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(5);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [{ data: organization }] = useAuthedAxios(
    `/organizations/${member.organization}`
  );

  const { data: memberships } = useSWR("/memberships", (url) =>
    authedAxios(url).then((res) => res.data)
  );

  const [
    { data: paymentData, loading: paymentLoading, error: paymentError },
    payMemberShip
  ] = useAuthedAxios("/organizations/payment", { manual: true });

  //TODO: Handle Errors

  useEffect(() => {
    if (
      organization &&
      (organization.membership_status === "Active" ||
        organization.membership_status === "Pending")
    )
      history.push(`/`);
  }, [history, organization]);

  useEffect(() => {
    // redirect to payment gateway
    if (paymentData && paymentData.status.status === "SUCCESS") {
      setLoading(true);
      localStorage.setItem("isPaymentInitiated", paymentData.data.id);
      window.location.href = paymentData.data.redirect_url;
    }
  }, [paymentData]);

  useEffect(() => {
    if (paymentLoading) {
      setLoading(true);
      countdown();
    }
  }, [paymentLoading]);

  async function pay(membership) {
    if (!organization.staff_strength || !organization.annual_turnover)
      return enqueueSnackbar(
        "Please update your Organization Info First (Staff Strength and Annual Turnover)",
        {
          variant: "error"
        }
      );

    if (!membership)
      return enqueueSnackbar("Please select a membership plan", {
        variant: "error"
      });

    setSelectedPlan(membership);

    // setLoading(true);

    const data = {
      complete_payment_url:
        process.env.REACT_APP_COMPLETE_PAYMENT_URL ||
        "https://members.asme.org.sg/dashboard/post-payment",
      complete_checkout_url:
        process.env.REACT_APP_COMPLETE_CHECKOUT_URL ||
        "https://members.asme.org.sg/dashboard/post-payment",
      error_payment_url:
        process.env.REACT_APP_ERROR_PAYMENT_URL ||
        "https://members.asme.org.sg/dashboard/post-payment",
      member,
      membership
    };

    payMemberShip({
      method: "POST",
      data
    });
  }

  function countdown() {
    var downloadTimer = setInterval(function () {
      if (timeLeft <= 0) {
        clearInterval(downloadTimer);
      }
      setTimeLeft((time) => (time <= 0 ? 0 : time - 1));
    }, 1000);
  }

  if (localStorage.getItem("isPaymentInitiated") && !loading)
    return (
      <>
        <PostPayment />
      </>
    );

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Please complete your payment to continue
      </Typography>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        {loading && (
          <Modal
            open={loading}
            // onClose={handleClosePlanModal}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
            disableBackdropClick
          >
            <div className={classes.modalPaper} style={modalStyle}>
              {
                <>
                  {/* <div style={{ float: "right" }}>
                      <IconButton onClick={handleClosePlanModal}>
                        <Close />
                      </IconButton>
                    </div> */}

                  <p>
                    Redirecting You To The Payment Gateway in{" "}
                    <span>{timeLeft}</span> Seconds
                  </p>

                  <p>Please Do Not Refresh The Page</p>

                  <br />
                  <div style={{ textAlign: "center" }}>
                    <small>
                      Not working?{" "}
                      <button
                        disabled={timeLeft > 0}
                        onClick={() => pay(selectedPlan)}
                      >
                        Click Here
                      </button>
                    </small>
                  </div>
                </>
              }
            </div>
          </Modal>
        )}
        <div className="ck-content">
          <Grid item xs={12}>
            <Grid container justify="center" spacing={2}>
              {memberships &&
                memberships.length > 0 &&
                memberships
                  .filter((m) => m.name === organization.membership_type)
                  .map((membership) => (
                    <Grid style={{ display: "flex" }} key={membership.id} item>
                      <Paper className={classes.paper}>
                        <h2
                          style={{
                            color: "#fff",
                            backgroundColor: "#002E5D",
                            textDecoration: "underline",
                            fontWeight: "bold",
                            textAlign: "center"
                          }}
                        >
                          {membership.name}
                        </h2>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                            alignItems: "center",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                            backgroundColor: "#fff"
                          }}
                        >
                          <span
                            style={{
                              paddingTop: "1rem",
                              paddingBottom: "1rem",
                              fontSize: "1rem",
                              fontWeight: 600,
                              textAlign: "center"
                            }}
                          >
                            <p>
                              $
                              {(
                                Math.round(membership.annual_price * 100) / 100
                              ).toFixed(2)}
                              / year <br />
                              (incl. GST)
                            </p>
                          </span>
                          <Button
                            style={{ marginBottom: "0.75rem" }}
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => pay(membership)}
                          >
                            Pay Now
                          </Button>
                          <hr style={{ width: "100%" }} />
                          <ul
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              backgroundColor: "#fff",
                              paddingBottom: "0.75rem",
                              paddingLeft: "0.95rem"
                            }}
                          >
                            {membership.features?.map((feature) => (
                              <li style={{ fontWeight: 500 }} key={feature.id}>
                                {feature.feature_point}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Paper>
                    </Grid>
                  ))}
            </Grid>
          </Grid>
        </div>
        {/* {promotionContentError && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            This is an error while loading the content. Please try reloading the
            page!
          </Alert>
        )} */}
      </Paper>
    </div>
  );
}

export default Onboarding;
