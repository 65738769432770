import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton, Modal, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { setCongratsVoucherOpen } from "../rewards.slice";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flex: 1,
    backgroundColor: "#E14040"
  },
  modalPaper: {
    position: "absolute",
    textAlign: "center",
    fontWeight: 500,
    fontSize: "1.15rem",
    // width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    border: "none"
  }
}));

function getModalStyle() {
  const top = 25;
  // const left = 50;

  return {
    top: `${top}%`,
    margin: "auto",
    // left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
    outline: "none"
  };
}

const CongratsModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalStyle] = React.useState(getModalStyle);

  const isModalOpen = useSelector(
    (state) => state.rewards.congratsVoucherModalOpen
  );

  const handleCloseModal = () => {
    dispatch(setCongratsVoucherOpen({ open: false }));
  };

  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        disableBackdropClick
      >
        <div className={classes.modalPaper} style={modalStyle}>
          {
            <>
              <div style={{ float: "right" }}>
                <IconButton
                  style={{ zIndex: 9, color: "#000" }}
                  onClick={handleCloseModal}
                >
                  <Close />
                </IconButton>
              </div>
              <Typography
                gutterBottom
                style={{
                  color: "#4BB543"
                }}
                variant="h5"
                component="h2"
              >
                Congrats!
              </Typography>
              <p>
                You can view your voucher code under <br />
                <strong>Claimed Rewards</strong>
              </p>

              <br />
              <div style={{ textAlign: "center" }}>
                <Button
                  size="small"
                  color="secondary"
                  // disabled={disabled}
                  variant="contained"
                  onClick={handleCloseModal}
                >
                  Okay
                </Button>
              </div>
            </>
          }
        </div>
      </Modal>
    </div>
  );
};

export default CongratsModal;
