import React from "react";
import { Paper, Typography } from "@material-ui/core";
import OrganisationProfile from "../OrganisationInfo/OrganisationProfile";

function MemberOrganisationInfo() {
  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Organisation Information
      </Typography>
      <Paper
        elevation={3}
        style={{
          padding: "20px",
          marginTop: "40px",
          overflowWrap: "break-word",
        }}
      >
        <OrganisationProfile />
      </Paper>
    </div>
  );
}

export default MemberOrganisationInfo;
