import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Modal,
  TextField
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

import {
  CHECKBOX,
  FILE_UPLOAD,
  PARAGRAPH,
  RADIO_BUTTONS,
  TEXT_FIELD,
  PLAIN_TEXT,
  CHECKBOX_GROUP
} from "../../../app/constants";
import { usePublicAxios } from "../../../util/axios";

import TextFieldRenderer from "./TextField";
import ParagraphRenderer from "./Paragraph";
import CheckboxRenderer from "./Checkbox";
import RadioButtonsRenderer from "./RadioButtons";
import UploadFileRenderer from "./UploadFile";
import PlainTextRenderer from "./PlainText";
import CheckboxGroupRenderer from "./CheckboxGroup";
import { useWorkshop } from "../../Member/Routes/Workshops/useWorkshop";

const queryString = require("query-string");

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flex: 1,
    backgroundColor: "#002E5D"
  },
  modalPaper: {
    position: "absolute",
    textAlign: "center",
    fontWeight: 500,
    fontSize: "1.15rem",
    // width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    border: "none"
  }
}));

function getModalStyle() {
  const top = 25;
  // const left = 50;

  return {
    top: `${top}%`,
    margin: "auto",
    // left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
    outline: "none"
  };
}

export const renderItem = (
  { type, id, settings },
  { member, organization },
  { register, setValue, watch, errors }
) => {
  settings = { ...settings, prefill: null };

  switch (type) {
    case TEXT_FIELD:
      return (
        <TextFieldRenderer
          id={id}
          settings={settings}
          member={member}
          organization={organization}
          register={register}
        />
      );
    case PARAGRAPH:
      return (
        <ParagraphRenderer
          id={id}
          settings={settings}
          member={member}
          organization={organization}
          register={register}
        />
      );
    case CHECKBOX:
      return (
        <CheckboxRenderer id={id} settings={settings} register={register} />
      );
    case CHECKBOX_GROUP:
      return (
        <CheckboxGroupRenderer
          id={id}
          settings={settings}
          setValue={setValue}
          register={register}
          watch={watch}
          errors={errors}
        />
      );
    case RADIO_BUTTONS:
      return (
        <RadioButtonsRenderer
          id={id}
          settings={settings}
          setValue={setValue}
          register={register}
        />
      );
    case FILE_UPLOAD:
      return (
        <UploadFileRenderer
          id={id}
          settings={settings}
          setValue={setValue}
          register={register}
        />
      );
    case PLAIN_TEXT:
      return <PlainTextRenderer id={id} settings={settings} />;
    default:
      return "";
  }
};

function PublicFormRenderer({
  form: formBuilder,
  id,
  isPoll,
  allowMultipleSelect
}) {
  const { search } = useLocation();
  const classes = useStyles();

  const [modalStyle] = React.useState(getModalStyle);

  const { enqueueSnackbar } = useSnackbar();

  const queryParams = queryString.parse(search);
  const workshopId = queryParams.workshopId;

  const [loadingPayment, setLoadingPayment] = useState(false);
  const [timeLeft, setTimeLeft] = useState(5);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [email, setEmail] = useState("");

  const { register, handleSubmit, setValue, watch, errors } = useForm();

  const [
    { loading: submitting, data: submissionData, error: submissionError },
    sendSubmission
  ] = usePublicAxios(
    {
      url: `/${isPoll ? "poll" : "form"}-submissions`,
      method: "POST"
    },
    {
      manual: true
    }
  );

  const { data: workshop } = useWorkshop(false, workshopId);

  const [
    { data: paymentData, loading: paymentLoading, error: paymentError },
    pay
  ] = usePublicAxios("/workshops/paymentForWorkshop", { manual: true });

  useEffect(() => {
    // redirect to payment gateway
    if (paymentData && paymentData.status.status === "SUCCESS") {
      localStorage.setItem(
        "isPaymentInitiatedForWorkshop",
        paymentData.data.id
      );
      window.location.href = paymentData.data.redirect_url;
    }
  }, [paymentData]);

  useEffect(() => {
    if (paymentLoading) {
      setLoadingPayment(true);
      countdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentLoading]);

  useEffect(() => {
    if (paymentError) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  }, [paymentError]);

  function countdown() {
    var downloadTimer = setInterval(function () {
      if (timeLeft <= 0) {
        clearInterval(downloadTimer);
      }
      setTimeLeft((time) => (time <= 0 ? 0 : time - 1));
    }, 1000);
  }

  async function handleWorkshopPayment({ formSubmissionId }) {
    if (!workshop) return;

    setLoadingPayment(true);

    const data = {
      complete_payment_url:
        process.env.REACT_APP_COMPLETE_WORKSHOP_PAYMENT_URL ||
        "https://members.asme.org.sg/dashboard/post-workshop-payment",
      complete_checkout_url:
        process.env.REACT_APP_COMPLETE_WORKSHOP_CHECKOUT_URL ||
        "https://members.asme.org.sg/dashboard/post-workshop-payment",
      error_payment_url:
        process.env.REACT_APP_ERROR_WORKSHOP_PAYMENT_URL ||
        "https://members.asme.org.sg/dashboard/post-workshop-payment",
      member: "public",
      workshop,
      formSubmission: formSubmissionId
    };

    pay({
      method: "POST",
      data
    });
  }

  const onSubmit = async (values) => {
    const formData = new FormData();
    const dataObj = {};
    Object.entries(values).forEach(([id, value]) => {
      if (!(value instanceof FileList)) {
        dataObj[id] = value;
      } else {
        if (value.length > 0) {
          formData.append("files.files", value[0], id + "___" + value[0].name);
        }
      }
    });

    const occurances = onlyOneCheckboxTest(Object.values(dataObj), true);
    if (isPoll && !allowMultipleSelect && occurances !== 1)
      return enqueueSnackbar("Please select only one option", {
        variant: "error"
      });

    let formDataToSubmit = {
      submission: dataObj,
      first_name: firstName,
      last_name: lastName,
      mobile_no: mobileNo,
      email
      //   member: member.id
    };
    if (isPoll) {
      formDataToSubmit.poll = id;
    } else {
      formDataToSubmit.form = id;
    }
    formData.append("data", JSON.stringify(formDataToSubmit));
    const res = await sendSubmission({
      data: formData
    });

    if (workshopId) {
      handleWorkshopPayment({ formSubmissionId: res.data.id });
    }
  };

  function onlyOneCheckboxTest(arr, testFor) {
    var counts = {};

    for (var i = 0; i < arr.length; i++) {
      var num = arr[i];
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    }

    return counts[testFor];
  }

  return (
    <>
      <Grid container>
        {submitting && (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </Grid>
        )}

        {submissionError && (
          <Alert severity="error">
            There was some error while communiating with server. Please try
            again later!
          </Alert>
        )}

        <Grid container spacing={3}>
          {loadingPayment && !paymentError && (
            <Modal
              open={loadingPayment}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
              disableBackdropClick
            >
              <div className={classes.modalPaper} style={modalStyle}>
                {
                  <>
                    <p>
                      Redirecting You To The Payment Gateway in{" "}
                      <span>{timeLeft}</span> Seconds
                    </p>

                    <p>Please Do Not Refresh The Page</p>
                    <br />
                    <div style={{ textAlign: "center" }}>
                      <small>
                        Not working?{" "}
                        <button
                          disabled={timeLeft > 0 || paymentLoading}
                          onClick={() =>
                            handleWorkshopPayment({
                              formSubmissionId: submissionData.id
                            })
                          }
                        >
                          Click Here
                        </button>
                      </small>
                    </div>
                  </>
                }
              </div>
            </Modal>
          )}
        </Grid>

        {!submitting && !submissionData && (
          <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
            <p />
            <TextField
              id="first_name"
              name="first_name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              placeholder="First Name"
              style={{ width: "100%" }}
              inputRef={register}
              InputProps={{
                type: "text"
              }}
            />
            <TextField
              id="last_name"
              name="last_name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              placeholder="Last Name"
              style={{ width: "100%" }}
              inputRef={register}
              InputProps={{
                type: "text"
              }}
            />
            <TextField
              id="mobile"
              name="mobile"
              value={mobileNo}
              onChange={(e) => setMobileNo(e.target.value)}
              required
              placeholder="Mobile Number"
              style={{ width: "100%" }}
              inputRef={register}
              InputProps={{
                type: "text"
              }}
            />
            <TextField
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Email"
              style={{ width: "100%" }}
              inputRef={register}
              InputProps={{
                type: "email"
              }}
            />
            <p />
            {formBuilder.map((item) => (
              <Grid item xs={12} key={item.id} style={{ padding: "10px 0" }}>
                {renderItem(
                  item,
                  {
                    member: null,
                    organization: null
                  },
                  { register, setValue, watch, errors }
                )}
              </Grid>
            ))}
            <Grid
              item
              xs={12}
              style={{
                textAlign: "right",
                marginBottom: isPoll ? "1rem" : "",
                marginRight: isPoll ? "1rem" : ""
              }}
            >
              <Button variant="contained" type="submit" color="secondary">
                Submit
              </Button>
            </Grid>
          </form>
        )}
        {submissionData && (
          <Grid
            style={{
              marginRight: isPoll ? "1rem" : "",
              marginBottom: isPoll ? "1rem" : "",
              textAlign: "center"
            }}
            item
            xs={12}
          >
            {isPoll && submitting ? (
              <Grid item xs={12}>
                <CircularProgress color="secondary" />
              </Grid>
            ) : null}
            {!isPoll && (
              <>
                <p>Thank you for your submission!</p>
                <p>
                  <RouterLink to="/dashboard/surveys">Click here</RouterLink> to
                  go back.
                </p>
              </>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default PublicFormRenderer;
