import { useQuery } from "@apollo/client";
import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FETCH_FORM } from "./forms.graphql";
import FormRenderer from "./Renderer/renderer";
import moment from "moment";
import "./styles/renderer.css";
import PublicFormRenderer from "./Renderer/renderer-public";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  container: {
    border: "1px solid lightgray",
    marginRight: "5px",
    padding: "20px",
    borderRadius: "8px",
  },
}));

function Forms() {
  const { slug } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [isFetching, setIsFetching] = useState(false);

  const isLoggedIn = useSelector((state) => state?.auth?.user !== null);

  const { loading, data, error } = useQuery(FETCH_FORM, {
    variables: {
      slug,
      endDate: moment().toISOString().split("T")[0],
    },
  });

  useEffect(() => {
    return () => {
      document.title = "ASME CRM Members";
    };
  }, []);

  useEffect(() => {
    // if (isLoggedIn === false) {
    //   localStorage.setItem("redirect_to", `/form/${slug}`);
    //   history.push("/auth/login");
    // } else if (isLoggedIn) {
    //   setIsFetching(true);
    //   loadForm();
    // }

    setIsFetching(true);
    // loadForm();
  }, [history, isLoggedIn, slug]);

  useEffect(() => {
    if (data) {
      const { form: formArray } = data;
      if (formArray?.length === 0) {
        history.push("/");
      }
      if (isLoggedIn === false && data.form[0].available_to !== "public") {
        localStorage.setItem("redirect_to", `/form/${slug}`);
        history.push("/auth/login");
      }
    }
  }, [data, history, slug]);

  function redirectToLogin() {
    localStorage.setItem("redirect_to", `/form/${slug}`);
    history.push("/auth/login");
  }

  if (!isFetching) return "";

  if (loading || !data || data?.form?.length === 0) {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  if (!loading && error) {
    return (
      <Grid container justify="center" style={{ padding: "10px" }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              <Alert severity="error">
                There was some internal server error while fetching the form.
                Please try again later!
              </Alert>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const { title, description, form, id } = data?.form[0];

  return (
    <Grid container justify="center" style={{ padding: "10px" }}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <FormHeader
              title={title}
              description={description}
              isLoggedIn={isLoggedIn}
              redirectToLogin={redirectToLogin}
            />
          </Grid>
          <Grid item xs={12}>
            {isLoggedIn ? (
              <FormRenderer form={form} id={id} />
            ) : (
              <PublicFormRenderer form={form} id={id} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const FormHeader = ({ title, description, isLoggedIn, redirectToLogin }) => (
  <Grid container className="fb-form-header">
    <Grid item xs={12} sm={12} md={8} lg={9}>
      <h2>{title}</h2>
      {!isLoggedIn && (
        <p>
          Already a member?{" "}
          <u style={{ cursor: "pointer" }} onClick={redirectToLogin}>
            Login
          </u>{" "}
          here to complete the form
        </p>
      )}
    </Grid>
    <Grid item xs={12} sm={12} md={4} lg={3} className="logo-wrapper">
      <img src="/images/logo.jpg" width="120px" alt="ASME Logo" />
    </Grid>
  </Grid>
);

export default Forms;
