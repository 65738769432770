import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import { BASE_URL } from "../../../../util/axios";

const useStyles = makeStyles({
  root: {
    maxWidth: 300,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "10px 5px",
    borderRadius: "6px"
  },
  eventTitle: {
    paddingTop: "1rem",
    fontSize: "1.3em",
    fontWeight: "500"
  },
  ImageClass: {
    position: "relative",
    borderRadius: "6px"
  }
});

const ReadMoreCard = ({ data }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid
      item
      xs={12}
      md={4}
      lg={4}
      sm={6}
      style={{ marginBottom: "1rem", cursor: "pointer" }}
      spacing={2}
    >
      <div className={classes.root} onClick={() => history.push(data?.slug)}>
        <div className={classes.ImageClass}>
          <CardMedia
            component="img"
            alt="Review"
            height="200"
            image={BASE_URL + data?.image?.url}
            title="Review"
            style={{ objectFit: "fill" }}
          />
        </div>
        <Typography variant="p" className={classes.eventTitle} component="p">
          <Link to={data?.slug}>{data?.title}</Link>
        </Typography>
      </div>
    </Grid>
  );
};

export default ReadMoreCard;
