import { authedAxios } from "../../../../util/axios";
import {
  getClaimedRewards,
  getAllRewards,
  setClaimedVoucher
} from "./rewards.slice";

export const fetchClaimedRewards = () => (dispatch) => {
  authedAxios
    .get("/vouchers/getClaimedVouchers", {
      withCredentials: true
    })
    .then((res) => {
      const { data } = res;
      dispatch(
        getClaimedRewards({
          data
        })
      );
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchAllRewards = () => (dispatch) => {
  authedAxios
    .get(`/listing-builders/getAllRewards`, {
      withCredentials: true
    })
    .then((res) => {
      const { data } = res;
      dispatch(
        getAllRewards({
          data
        })
      );
    })
    .catch((err) => {
      console.log(err);
    });
};
