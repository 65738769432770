import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
  Avatar,
  CircularProgress,
  Menu,
  MenuItem
} from "@material-ui/core";
import { Home, Menu as MenuIcon } from "@material-ui/icons";
import clsx from "clsx";
import PermDeviceInformationIcon from "@material-ui/icons/PermDeviceInformation";
import EventIcon from "@material-ui/icons/Event";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import EventNoteIcon from "@material-ui/icons/EventNote";
import RedeemIcon from "@material-ui/icons/Redeem";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from "react-router-dom";

import Newsroom from "./Routes/newsroom";
import UpgradeMembership from "./Routes/membership";
import RenewMembership from "./Routes/renew-membership";
import { ASME_SITE_URL, useAuthedAxios } from "../../util/axios";
import Onboarding from "./Routes/onboarding";
import ProfilePending from "./Routes/profile-pending";
import PostPayment from "./Routes/post-payment";
import Surveys from "./Routes/surveys";
import Announcements from "./Routes/Announcements/announcements";
import Programs from "./Routes/Programs/programs";
import AnnouncementsContent from "./Routes/Announcements/announcements-content";
import ProgramsContent from "./Routes/Programs/programs-content";
import MemberHome from "./Routes/new-home";
import MemberOrganisationInfo from "./Routes/organisation-info";
import MemberActivities from "./Routes/activities";
import { HEADER_COLOR, SECONDARY_COLOR } from "../../app/constants";
import Events from "./Routes/Events/events";
import EventContent from "./Routes/Events/event-content";
import Rewards from "./Routes/Rewards/rewards";
import SpecialPromotions from "./Routes/SpecialPromotion/special-promotions";
import SpecialPromotion from "./Routes/SpecialPromotion/special-promotion-content";
import Reviews from "./Routes/Reviews/reviews";
import Workshops from "./Routes/Workshops/workshops";
import PostWorkshopPayment from "./Routes/Workshops/post-workshop-payment";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    backgroundColor: HEADER_COLOR,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    backgroundColor: HEADER_COLOR,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    paddingTop: "20px",
    paddingBottom: "20px",

    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3), // should be less for mobile devices
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1)
    },
    background: "#E6E6E6",
    minHeight: "100vh",
    overflow: "auto"
  }
}));

function MemberContainer({ logout }) {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const classes = useStyles();
  const history = useHistory();

  const [menu, setMenu] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const role = useSelector((state) => state.auth.user.role.type);
  const member = useSelector((state) => state.auth.user.member);
  const userID = useSelector((state) => state.auth.user.id);

  const [{ data: organization }] = useAuthedAxios(
    `/organizations/${member.organization}`
  );

  useEffect(() => {
    if (isMobile) {
      handleDrawerClose();
    }
  }, []);

  const onMenuSelect = (menuItem) => {
    history.push(menuItem.path);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  useEffect(() => {
    if (role) {
      if (role !== "member") {
        history.push("/dashboard");
      } else {
        setIsLoaded(true);
      }
    }
  }, [history, role]);

  useEffect(() => {
    let _menu = [
      {
        title: "Home",
        path: "/dashboard",
        icon: <Home />
      },
      {
        title: "Events",
        path: "/dashboard/events",
        icon: <EventIcon />
      },
      {
        title: "Special Promotion",
        path: "/dashboard/special-promotion",
        icon: (
          <img
            src={require("./images/diamond-shape.png")}
            width="18px"
            height="18px"
            alt="Icon"
            style={{ marginLeft: "3px" }}
          />
        )
      },
      {
        title: "Members Rewards",
        path: "/dashboard/rewards",
        icon: <RedeemIcon />
      },
      {
        title: "Newsroom",
        path: "/dashboard/newsroom",
        icon: <BookmarksIcon />
      },
      {
        title: "Surveys/Forms",
        path: "/dashboard/surveys",
        icon: <PermDeviceInformationIcon />
      },
      // {
      //   title: "Programs",
      //   path: "/dashboard/programs",
      //   icon: <EventNoteIcon />
      // },
      // {
      //   title: "Announcements",
      //   path: "/dashboard/announcements",
      //   icon: <BookmarksIcon />
      // },
      {
        title: "Reviews",
        path: "/dashboard/reviews",
        icon: <EventNoteIcon />
      },
      {
        title: "Workshops/Training",
        path: "/dashboard/workshops",
        icon: <EventNoteIcon />
      }
    ];

    if (
      organization &&
      // organization.membership_type &&
      organization.membership_type !== "Associate" &&
      organization.membership_type !== "Platinum"
    ) {
      _menu.splice(_menu.length, 0, {
        title: "Upgrade Membership",
        path: "/dashboard/membership",
        icon: (
          <img
            src={require("./images/crown.png")}
            width="18px"
            height="18px"
            alt="Icon"
            style={{ marginLeft: "3px" }}
          />
        )
      });
      setMenu(_menu);
    }
    setMenu(_menu);
  }, [organization]);

  useEffect(() => {
    // payment initiated hence redirect to post payment page
    if (localStorage.getItem("isPaymentInitiated"))
      return history.push(`${path}/post-payment`);

    // make organization page available all times

    if (organization && history.location.pathname === "/dashboard/organisation")
      return;

    // payment not initiated and organization available and paid user
    if (!localStorage.getItem("isPaymentInitiated") && organization) {
      if (organization.membership_status === "Expired")
        return history.push(`${path}/renew-membership`);
      if (organization.membership_status === "Inactive")
        return history.push(`${path}/onboarding`);
    }

    // organization available and membership status is Pending or Renewal
    if (
      organization &&
      (organization.membership_status === "Pending" ||
        organization.membership_status === "Renewal")
    )
      return history.push(`${path}/pending`);
  }, [history, history.location.pathname, organization, path]);

  if (!organization) return null;

  return (
    <>
      {isLoaded ? (
        <>
          <Helmet
            script={[
              {
                type: "text/javascript",
                innerHTML: `dataLayer = [{'userID': '${userID}'}];`
              }
            ]}
          />
          <div className={classes.root}>
            <CssBaseline />
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open
              })}
              color="transparent"
              elevation={0}
            >
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: open
                  })}
                  size="small"
                  style={{
                    background: "white",
                    color: HEADER_COLOR
                  }}
                  elevation={4}
                >
                  <MenuIcon />
                </IconButton>
                {open && (
                  <IconButton
                    color="secondary"
                    style={{
                      background: "white",
                      color: HEADER_COLOR,
                      marginLeft: "-39px"
                    }}
                    size="small"
                    onClick={handleDrawerClose}
                    elevation={4}
                  >
                    <MenuIcon />
                  </IconButton>
                )}
              </Toolbar>
            </AppBar>
            <Drawer
              variant="permanent"
              elevation={3}
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open
                })
              }}
              PaperProps={{ elevation: 10 }}
            >
              <div
                className={classes.toolbar}
                style={
                  !open
                    ? {
                        marginTop: "40px"
                      }
                    : null
                }
              >
                <img
                  src="/images/logo.png"
                  width={open ? "70px" : "30px"}
                  alt="ASME Logo"
                />
              </div>
              <Divider />
              <List
                style={{ paddingLeft: "7px" }}
                dense
                className="mobile-main-menup-padding"
              >
                {menu.map((item) => (
                  <ListItem
                    button
                    key={item.title}
                    onClick={() => onMenuSelect(item)}
                  >
                    <ListItemIcon
                      style={{
                        color: "#fff",
                        minWidth: open ? "32px" : "56px"
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.title}
                      primaryTypographyProps={{
                        style: {
                          fontWeight:
                            pathname === item.path
                              ? "bolder"
                              : pathname.includes("/newsroom") &&
                                item.path === "/dashboard/newsroom"
                              ? "bolder"
                              : 500,
                          color: "#fff"
                        }
                      }}
                    />
                  </ListItem>
                ))}
                <ListItem
                  button
                  key={"Merchant Portal"}
                  onClick={() =>
                    (window.location.href = `${ASME_SITE_URL}/merchant-view`)
                  }
                >
                  <ListItemIcon
                    style={{
                      color: SECONDARY_COLOR,
                      minWidth: open ? "32px" : "56px"
                    }}
                  >
                    <img
                      src={require("./images/diamond-shape.png")}
                      width="18px"
                      height="18px"
                      alt="Icon"
                      style={{ marginLeft: "3px" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Merchant Portal"}
                    primaryTypographyProps={{
                      style: {
                        fontWeight:
                          pathname === true
                            ? "bolder"
                            : pathname.includes("/newsroom") &&
                              true === "/dashboard/newsroom"
                            ? "bolder"
                            : 500,
                        color: "#fff"
                      }
                    }}
                  />
                </ListItem>
              </List>
              {/* <Divider /> */}
            </Drawer>
            <main className={classes.content}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "flex-end",
                  zIndex: 1202,
                  position: "relative"
                }}
                className="mobile-width"
              >
                <Typography variant="h6" style={{ marginRight: "10px" }}>
                  Welcome, {member.first_name}
                </Typography>
                <div>
                  <Avatar
                    style={{ background: "white", color: HEADER_COLOR }}
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                  >
                    {member?.first_name[0]?.toUpperCase()}
                  </Avatar>

                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    open={menuOpen}
                    onClose={handleClose}
                  >
                    {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
                    <MenuItem onClick={handleClose}>My account</MenuItem>
                    <Divider /> */}
                    <MenuItem
                      onClick={() => {
                        history.push("/dashboard/organisation");
                        handleClose();
                      }}
                    >
                      Organisation Info
                    </MenuItem>
                    <MenuItem onClick={logout}>Logout</MenuItem>
                  </Menu>
                </div>
              </div>

              <div className={classes.toolbar} />

              {/* Routes Here */}

              <Switch>
                <Route exact path={`${path}`}>
                  <MemberHome />
                </Route>
                <Route exact path={`${path}/onboarding`}>
                  <Onboarding />
                </Route>
                <Route exact path={`${path}/pending`}>
                  <ProfilePending />
                </Route>
                <Route exact path={`${path}/membership`}>
                  <UpgradeMembership />
                </Route>
                <Route exact path={`${path}/renew-membership`}>
                  <RenewMembership />
                </Route>
                <Route exact path={`${path}/post-payment`}>
                  <PostPayment />
                </Route>
                <Route exact path={`${path}/post-workshop-payment`}>
                  <PostWorkshopPayment />
                </Route>
                <Route exact path={`${path}/organisation`}>
                  <MemberOrganisationInfo />
                </Route>
                {/* <Route exact path={`${path}/announcements/:id`}>
                  <AnnouncementsContent />
                </Route>
                <Route exact path={`${path}/announcements`}>
                  <Announcements />
                </Route>
                <Route exact path={`${path}/programs/:id`}>
                  <ProgramsContent />
                </Route>
                <Route exact path={`${path}/programs`}>
                  <Programs />
                </Route> */}
                <Route exact path={`${path}/workshops`}>
                  <Workshops />
                </Route>
                <Route exact path={`${path}/reviews`}>
                  <Reviews />
                </Route>
                <Route exact path={`${path}/activities`}>
                  <MemberActivities />
                </Route>
                <Route exact path={`${path}/events`}>
                  <Events />
                </Route>
                <Route exact path={`${path}/events/:id`}>
                  <EventContent />
                </Route>
                <Route exact path={`${path}/rewards`}>
                  <Rewards />
                </Route>
                <Route exact path={`${path}/surveys`}>
                  <Surveys />
                </Route>
                <Route exact path={`${path}/special-promotion`}>
                  <SpecialPromotions />
                </Route>
                <Route exact path={`${path}/special-promotion/:id`}>
                  <SpecialPromotion />
                </Route>
                <Route exact path={`${path}/newsroom`}>
                  <Newsroom />
                </Route>
              </Switch>
            </main>
          </div>
        </>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}
    </>
  );
}

export default MemberContainer;
