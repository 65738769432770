import React from "react";
import moment from "moment";

function MemberInfo({ classes, organization }) {
  return (
    <table
      style={{
        borderCollapse: "collapse",
        wordWrap: "break-word",
        tableLayout: "fixed"
      }}
      cellPadding="10"
    >
      <tbody>
        <tr>
          <td
            className={classes.infoCellStyle}
            style={{
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px"
            }}
          >
            Type
          </td>
          <td className={classes.infoCellLeftMargin}>
            {organization.membership_type
              ? organization.membership_type
              : "Lite"}
          </td>
        </tr>
        <tr>
          <td className={classes.infoCellStyle}>Registration Date</td>
          <td className={classes.infoCellLeftMargin}>
            {organization.registration_date
              ? moment(organization.registration_date).format("DD MMMM YYYY")
              : moment(organization.created_at).format("DD MMMM YYYY")}
          </td>
        </tr>
        <tr>
          <td className={classes.infoCellStyle}>Expiry Date</td>
          <td className={classes.infoCellLeftMargin}>
            {organization.expiry_date
              ? moment(organization.expiry_date).format("DD MMMM YYYY")
              : moment(organization.created_at)
                  .add(1, "y")
                  .subtract(1, "d")
                  .format("DD MMMM YYYY")}
          </td>
        </tr>

        <tr>
          <td
            className={classes.infoCellStyle}
            style={{
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px"
            }}
          >
            Status
          </td>
          <td className={classes.infoCellLeftMargin}>
            {organization.membership_status === "Pending"
              ? "Pending Upgrade"
              : organization.membership_status}
            {!organization.membership_status && "Active"}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default MemberInfo;
