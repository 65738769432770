import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import "../../styles/styles.css";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "6px",
  },

  articleImg: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "900px !important",
    textAlign: "center !important",
    marginBottom: "2.5rem",
  },
  img: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "900px !important",
  },
});

export default function FullImage({ data, url }) {
  const classes = useStyles();
  return (
    <div className={classes.articleImg}>
      {data.image && (
        <img
          src={`${url}${data.image.url}`}
          alt="Main"
          className={classes.img}
        />
      )}
    </div>
  );
}
