import React, { useEffect, useState } from "react";
import { Button, Modal, Paper, Typography } from "@material-ui/core";
import useSWR from "swr";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";

import "../styles/special-promotion.css";
import { authedAxios, useAuthedAxios } from "../../../util/axios";

// import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flex: 1,
    backgroundColor: "#002E5D"
  },
  modalPaper: {
    position: "absolute",
    textAlign: "center",
    fontWeight: 500,
    fontSize: "1.15rem",
    // width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    border: "none"
  }
}));

function getModalStyle() {
  const top = 25;
  // const left = 50;

  return {
    top: `${top}%`,
    margin: "auto",
    // left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
    outline: "none"
  };
}

function UpgradeMembership() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [modalStyle] = React.useState(getModalStyle);

  const member = useSelector((state) => state.auth.user.member);

  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(5);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const { data: memberships } = useSWR("/memberships", (url) =>
    authedAxios(url).then((res) => res.data)
  );

  const [{ data: organization }] = useAuthedAxios(
    `/organizations/${member.organization}`
  );

  const [
    { data: paymentData, loading: paymentLoading, error: paymentError },
    payMemberShip
  ] = useAuthedAxios("/organizations/payment", { manual: true });

  useEffect(() => {
    if (organization && organization.membership_status === "Pending")
      return history.push(`/dashboard/pending`);
    // redirect Associate members to home as they cannot upgrade now
    if (organization && organization.membership_type === "Associate")
      history.push(`/`);
  }, [history, organization]);

  useEffect(() => {
    // redirect to payment gateway
    if (paymentData && paymentData.status.status === "SUCCESS") {
      localStorage.setItem("isPaymentInitiated", paymentData.data.id);
      window.location.href = paymentData.data.redirect_url;
    }
  }, [paymentData]);

  useEffect(() => {
    if (paymentLoading) {
      setLoading(true);
      countdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentLoading]);

  async function pay(membership) {
    if (!organization.staff_strength || !organization.annual_turnover)
      return enqueueSnackbar(
        "Please update your Organization Info First (Staff Strength and Annual Turnover)",
        {
          variant: "error"
        }
      );

    if (!membership)
      return enqueueSnackbar("Please select a membership plan", {
        variant: "error"
      });

    setSelectedPlan(membership);

    // setLoading(true);

    const data = {
      complete_payment_url:
        process.env.REACT_APP_COMPLETE_PAYMENT_URL ||
        "https://members.asme.org.sg/dashboard/post-payment",
      complete_checkout_url:
        process.env.REACT_APP_COMPLETE_CHECKOUT_URL ||
        "https://members.asme.org.sg/dashboard/post-payment",
      error_payment_url:
        process.env.REACT_APP_ERROR_PAYMENT_URL ||
        "https://members.asme.org.sg/dashboard/post-payment",
      member,
      membership
    };

    payMemberShip({
      method: "POST",
      data
    });
  }

  function countdown() {
    var downloadTimer = setInterval(function () {
      if (timeLeft <= 0) {
        clearInterval(downloadTimer);
      }
      setTimeLeft((time) => (time <= 0 ? 0 : time - 1));
    }, 1000);
  }

  function getMemberships(memberships) {
    const currentMembership = organization.membership_type
      ? organization.membership_type
      : "Lite";
    const filteredMemberships = memberships
      .filter(
        (m) =>
          m.annual_price >
          memberships.find(
            (membership) => membership.name === currentMembership
          ).annual_price
      )
      .sort((a, b) => a.annual_price - b.annual_price);
    //to remove Associate membership
    filteredMemberships.splice(-1, 1);
    return filteredMemberships;
  }

  const MembershipCard = ({ membership, myMembership }) => {
    if (!membership) return "";

    return (
      <Grid style={{ display: "flex", width: "300px" }} item>
        <Paper className={classes.paper}>
          <h2
            style={{
              color: "#fff",
              backgroundColor: "#002E5D",
              fontWeight: "bold",
              textAlign: "center"
            }}
          >
            {membership.name}
          </h2>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flexGrow: 1,
              paddingLeft: "1rem",
              paddingRight: "1rem",
              backgroundColor: "#fff"
            }}
          >
            <span
              style={{
                paddingTop: "1rem",
                paddingBottom: "1rem",
                fontSize: "1rem",
                fontWeight: 600,
                textAlign: "center"
              }}
            >
              <p>
                ${(Math.round(membership.annual_price * 100) / 100).toFixed(2)}{" "}
                / year <br />
                (incl. GST)
              </p>
            </span>
            <Button
              style={{ marginBottom: "0.75rem" }}
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => pay(membership)}
              disabled={myMembership}
            >
              {myMembership ? "Current Plan" : "Upgrade Now"}
            </Button>
            <hr style={{ width: "100%" }} />
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#fff",
                paddingBottom: "0.75rem",
                paddingLeft: "0.95rem"
              }}
            >
              {membership.features?.map((feature) => (
                <li style={{ fontWeight: 500 }} key={feature.id}>
                  {feature.feature_point}
                </li>
              ))}
            </ul>
          </div>
        </Paper>
      </Grid>
    );
  };

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Upgrade Membership
      </Typography>

      {loading && (
        <Modal
          open={loading}
          // onClose={handleClosePlanModal}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
          disableBackdropClick
        >
          <div className={classes.modalPaper} style={modalStyle}>
            {
              <>
                {/* <div style={{ float: "right" }}>
                      <IconButton onClick={handleClosePlanModal}>
                        <Close />
                      </IconButton>
                    </div> */}

                <p>
                  Redirecting You To The Payment Gateway in{" "}
                  <span>{timeLeft}</span> Seconds
                </p>

                <p>Please Do Not Refresh The Page</p>

                <br />
                <div style={{ textAlign: "center" }}>
                  <small>
                    Not working?{" "}
                    <button
                      disabled={timeLeft > 0 || paymentLoading}
                      onClick={() => pay(selectedPlan)}
                    >
                      Click Here
                    </button>
                  </small>
                </div>
              </>
            }
          </div>
        </Modal>
      )}
      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        <div className="ck-content">
          <Grid item xs={12}>
            <Grid container justify="center" spacing={2}>
              <MembershipCard
                membership={
                  organization.membership_type
                    ? (memberships || []).find(
                        (membership) =>
                          membership.name === organization?.membership_type
                      )
                    : (memberships || []).find(
                        (membership) => membership.name === "Lite"
                      )
                }
                myMembership
              />
              {memberships &&
                memberships.length > 0 &&
                getMemberships(memberships).map((membership) => (
                  <MembershipCard membership={membership} key={membership.id} />
                ))}
            </Grid>
          </Grid>
        </div>

        {/* {promotionContentError && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            This is an error while loading the content. Please try reloading the
            page!
          </Alert>
        )} */}
      </Paper>
    </div>
  );
}

export default UpgradeMembership;
