import React, { useEffect, useState } from "react";
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
  Avatar,
  CircularProgress,
  Menu,
  MenuItem,
  Paper,
  Grid,
  Button,
  Breadcrumbs
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import {
  useHistory,
  useParams,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
  Link
} from "react-router-dom";
import { useSelector } from "react-redux";
import { Home, Menu as MenuIcon, NavigateNext } from "@material-ui/icons";
import clsx from "clsx";
import PermDeviceInformationIcon from "@material-ui/icons/PermDeviceInformation";
import EventIcon from "@material-ui/icons/Event";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import EventNoteIcon from "@material-ui/icons/EventNote";
import RedeemIcon from "@material-ui/icons/Redeem";

import {
  ASME_SITE_URL,
  BASE_URL,
  CLIENT_SITE_URL,
  useAuthedAxios,
  usePublicAxios
} from "../../../../util/axios";
import { HEADER_COLOR, SECONDARY_COLOR } from "../../../../app/constants";

import { useReview } from "./useReview";
import ReadMoreCard from "./ReadMoreCard";
import SocialShare from "./SocialShare";
import FullImage from "../Announcements/fullimage";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    backgroundColor: HEADER_COLOR,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    backgroundColor: HEADER_COLOR,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    paddingTop: "20px",
    paddingBottom: "20px",

    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "#E6E6E6",
    minHeight: "100vh"
  },
  container: {
    border: "1px solid lightgray",
    marginRight: "5px",
    padding: "20px",
    borderRadius: "8px"
  },
  ReadMorePosts: {
    paddingTop: "1rem"
  }
}));

function ReviewsContent() {
  const { slug } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const [menu, setMenu] = useState([]);
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const member = useSelector((state) => state?.auth?.user?.member);
  const isLoggedIn = useSelector((state) => state?.auth?.user !== null);

  const { data, loading, error } = useReview(isLoggedIn, slug);

  const [{ data: organization }] = useAuthedAxios(
    `/organizations/${member?.organization}`
  );

  const [{ data: ads }] = usePublicAxios(`/review-page-ads`);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsFetching(true);
  }, [isLoggedIn]);

  useEffect(() => {
    let _menu = [
      {
        title: "Home",
        path: "/dashboard",
        icon: <Home />
      },
      {
        title: "Events",
        path: "/dashboard/events",
        icon: <EventIcon />
      },
      {
        title: "Special Promotion",
        path: "/dashboard/special-promotion",
        icon: (
          <img
            src={require("../../images/diamond-shape.png")}
            width="18px"
            height="18px"
            alt="Icon"
            style={{ marginLeft: "3px" }}
          />
        )
      },
      {
        title: "Members Rewards",
        path: "/dashboard/rewards",
        icon: <RedeemIcon />
      },
      {
        title: "Newsroom",
        path: "/dashboard/newsroom",
        icon: <BookmarksIcon />
      },
      {
        title: "Surveys/Forms",
        path: "/dashboard/surveys",
        icon: <PermDeviceInformationIcon />
      },
      // {
      //   title: "Programs",
      //   path: "/dashboard/programs",
      //   icon: <EventNoteIcon />
      // },
      // {
      //   title: "Announcements",
      //   path: "/dashboard/announcements",
      //   icon: <BookmarksIcon />
      // },
      {
        title: "Workshops/Training",
        path: "/dashboard/workshops",
        icon: <EventNoteIcon />
      },
      {
        title: "Reviews",
        path: "/dashboard/reviews",
        icon: <EventNoteIcon />
      }
    ];

    if (
      organization &&
      // organization.membership_type &&
      organization?.membership_type !== "Associate" &&
      organization?.membership_type !== "Platinum"
    ) {
      _menu.splice(_menu.length, 0, {
        title: "Upgrade Membership",
        path: "/dashboard/membership",
        icon: (
          <img
            src={require("../../images/crown.png")}
            width="18px"
            height="18px"
            alt="Icon"
            style={{ marginLeft: "3px" }}
          />
        )
      });
      setMenu(_menu);
    } else {
      _menu.splice(_menu.length, 0, {
        title: "ASME Membership",
        path: "/auth/register",
        icon: (
          <img
            src={require("../../images/crown.png")}
            width="18px"
            height="18px"
            alt="Icon"
            style={{ marginLeft: "3px" }}
          />
        )
      });
      setMenu(_menu);
    }
    setMenu(_menu);
  }, [organization]);

  useEffect(() => {
    if (data) document.title = data?.title || "Members Portal";

    return () => {
      document.title = "Members Portal";
    };
  }, [data]);

  const onMenuSelect = (menuItem) => {
    history.push(menuItem?.path);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event?.currentTarget);
    setMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const goToLink = (link) => {
    if (!link) return;
    window.open(link || "", "_blank").focus();
  };

  const renderMembershipMessage = () => {
    // no message for public view
    if (data?.public_view) return "";

    // error message for member or platinum specific view
    if (!member)
      return (
        <Typography
          variant="p"
          color="secondary"
          style={{ fontWeight: "bold" }}
        >
          This Article Is Available To ASME Members Only.{" "}
          <Link to="/auth/register"> Sign Up Now</Link> To Continue Reading.
        </Typography>
      );

    if (data?.members_only_view) return "";

    if (data?.platinum_only_view) {
      if (organization?.membership_type === "Associate") return "";

      if (organization?.membership_type !== "Platinum")
        return (
          <Typography
            variant="p"
            color="secondary"
            style={{ fontWeight: "bold" }}
          >
            This Article Is Exclusive To Platinum Members Only.{" "}
            <Link to="/dashboard/membership"> Upgrade Now</Link> To Continue
            Reading.
          </Typography>
        );
    }
  };

  const ReadMorePosts = () => {
    return (
      <>
        {data?.read_more_reviews?.length > 0 && (
          <div className={classes.ReadMorePosts}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Read More
            </Typography>
            <Grid container className={classes.root} spacing={1}>
              {data?.read_more_reviews?.map((card) => {
                return <ReadMoreCard data={card} key={card.id} />;
              })}
            </Grid>
          </div>
        )}

        {member && data?.curated_reviews?.length > 0 && (
          <div className={classes.ReadMorePosts}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Just For You
            </Typography>
            <Grid container className={classes.root} spacing={1}>
              {data?.curated_reviews.map((card) => {
                return <ReadMoreCard data={card} key={card.id} />;
              })}
            </Grid>
          </div>
        )}
      </>
    );
  };

  const renderReviewData = () => {
    if (!isFetching) return "";

    if (loading) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: "100vh" }}
        >
          <CircularProgress color="secondary" />
        </Grid>
      );
    }

    if (!loading && !data) {
      return (
        <div>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            This is an error while loading the content. Please try reloading the
            page!
          </Alert>
        </div>
      );
    }

    if (!loading && error) {
      return (
        <Grid container justify="center" style={{ padding: "10px" }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Grid container className={classes.container}>
              <Grid item xs={12}>
                <Alert severity="error">
                  There was some internal server error while fetching the
                  review. Please try again later!
                </Alert>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    const renderContentAd = () => {
      return (
        <div style={{ textAlign: "center" }}>
          {ads?.content_ad_image && (
            <img
              src={`${BASE_URL}${ads?.content_ad_image?.url}`}
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "80px",
                maxWidth: "850px"
              }}
              // width="725px"
              // height="80px"
              alt="review"
              onClick={() => goToLink(ads?.content_ad_url)}
            />
          )}
        </div>
      );
    };

    const renderSideAd = () => {
      return (
        <Grid alignItems="center" item xs={0} md={4} lg={3} sm={5}>
          {ads?.side_ad_image && (
            <img
              src={`${BASE_URL}${ads?.side_ad_image?.url}`}
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "355px",
                maxWidth: "255px"
              }}
              // width="255px"
              // height="355px"
              alt="review"
              onClick={() => goToLink(ads?.side_ad_url)}
            />
          )}
        </Grid>
      );
    };

    const breadcrumbs = [
      <Typography
        color="secondary"
        style={{ fontWeight: "bold", cursor: "pointer" }}
        onClick={() => handleHomeClick("/dashboard")}
      >
        Home
      </Typography>,
      <Typography
        key="3"
        color="secondary"
        style={{ cursor: "pointer" }}
        onClick={() => handleHomeClick("/dashboard/reviews")}
      >
        Reviews
      </Typography>
    ];

    function handleHomeClick(path) {
      history.push(path);
    }

    return (
      <>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
        <h1>{data?.title}</h1>
        <div className={"article-section"}>
          <p>{data?.description}</p>
        </div>
        <FullImage data={data} url={BASE_URL} />
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={data?.content_second_half && ads?.side_ad_image ? 8 : 12}
            lg={data?.content_second_half && ads?.side_ad_image ? 9 : 12}
            sm={data?.content_second_half && ads?.side_ad_image ? 7 : 12}
          >
            {data?.content_first_half && (
              <div
                className="ck-content article-section"
                dangerouslySetInnerHTML={{
                  __html: data?.content_first_half?.replaceAll(
                    "/uploads/",
                    `${BASE_URL}/uploads/`
                  )
                }}
              ></div>
            )}
            {data?.content_second_half && renderContentAd()}
            {data?.content_second_half && (
              <div
                className="ck-content article-section"
                dangerouslySetInnerHTML={{
                  __html: data?.content_second_half?.replaceAll(
                    "/uploads/",
                    `${BASE_URL}/uploads/`
                  )
                }}
              ></div>
            )}
          </Grid>
          {data?.content_second_half && renderSideAd()}
        </Grid>

        {renderMembershipMessage()}
        <SocialShare
          shareUrl={`${CLIENT_SITE_URL}\/review/${data?.slug}`}
          title={data?.title}
        />
        <ReadMorePosts />
      </>
    );
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
        color="transparent"
        elevation={0}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
            size="small"
            style={{
              background: "white",
              color: HEADER_COLOR
            }}
            elevation={4}
          >
            <MenuIcon />
          </IconButton>
          {open && (
            <IconButton
              color="secondary"
              style={{
                background: "white",
                color: HEADER_COLOR,
                marginLeft: "-39px"
              }}
              size="small"
              onClick={handleDrawerClose}
              elevation={4}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        elevation={3}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
        PaperProps={{ elevation: 10 }}
      >
        <div
          className={classes.toolbar}
          style={
            !open
              ? {
                  marginTop: "40px"
                }
              : null
          }
        >
          <img
            src="/images/logo.png"
            width={open ? "70px" : "30px"}
            alt="ASME Logo"
          />
        </div>
        <Divider />
        <List
          style={{ paddingLeft: "7px" }}
          dense
          className="mobile-main-menup-padding"
        >
          {menu.map((item) => (
            <ListItem
              button
              key={item.title}
              onClick={() => onMenuSelect(item)}
            >
              <ListItemIcon
                style={{
                  color: "#fff",
                  minWidth: open ? "32px" : "56px"
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{
                  style: {
                    fontWeight:
                      pathname === item.path
                        ? "bolder"
                        : pathname.includes("/newsroom") &&
                          item.path === "/dashboard/newsroom"
                        ? "bolder"
                        : 500,
                    color: "#fff"
                  }
                }}
              />
            </ListItem>
          ))}
          <ListItem
            button
            key={"Merchant Portal"}
            onClick={() =>
              (window.location.href = `${ASME_SITE_URL}/merchant-view`)
            }
          >
            <ListItemIcon
              style={{
                color: SECONDARY_COLOR,
                minWidth: open ? "32px" : "56px"
              }}
            >
              <img
                src={require("../../images/diamond-shape.png")}
                width="18px"
                height="18px"
                alt="Icon"
                style={{ marginLeft: "3px" }}
              />
            </ListItemIcon>
            <ListItemText
              primary={"Merchant Portal"}
              primaryTypographyProps={{
                style: {
                  fontWeight:
                    pathname === true
                      ? "bolder"
                      : pathname.includes("/newsroom") &&
                        true === "/dashboard/newsroom"
                      ? "bolder"
                      : 500,
                  color: "#fff"
                }
              }}
            />
          </ListItem>
        </List>
        {/* <Divider /> */}
      </Drawer>
      <main className={classes.content}>
        {member ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "baseline",
              justifyContent: "flex-end",
              zIndex: 1202,
              position: "relative"
            }}
            className="mobile-width"
          >
            <Typography variant="h6" style={{ marginRight: "10px" }}>
              Welcome, {member ? member?.first_name : "User"}
            </Typography>
            <div>
              <Avatar
                style={{ background: "white", color: HEADER_COLOR }}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
              >
                {member?.first_name[0]?.toUpperCase()}
              </Avatar>

              {member && (
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  open={menuOpen}
                  onClose={handleClose}
                >
                  {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
<MenuItem onClick={handleClose}>My account</MenuItem>
<Divider /> */}
                  <MenuItem
                    onClick={() => {
                      history.push("/dashboard/organisation");
                      handleClose();
                    }}
                  >
                    Organisation Info
                  </MenuItem>
                  <MenuItem onClick={() => {}}>Logout</MenuItem>
                </Menu>
              )}
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "baseline",
              justifyContent: "flex-end",
              zIndex: 1202,
              position: "relative"
            }}
            className="mobile-width"
          >
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={() => history.push("/auth/login")}
            >
              Login
            </Button>
          </div>
        )}

        <div className={classes.toolbar} />

        <Switch>
          <Route exact path={`${path}`}>
            <div>
              <Typography
                variant="h4"
                color="secondary"
                style={{ fontWeight: "bold" }}
              >
                Reviews
              </Typography>
              <Paper
                elevation={3}
                style={{ padding: "20px", marginTop: "40px" }}
              >
                {renderReviewData()}
              </Paper>
            </div>
          </Route>
        </Switch>
      </main>
    </div>
  );
}

export default ReviewsContent;
