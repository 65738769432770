import { useEffect } from "react";

import { useAuthedAxios, usePublicAxios } from "../../../../util/axios";

export function useReview(isLoggedIn, slug) {
  const [{ data, loading, error }, loadReview] = useAuthedAxios(
    `/reviews/viewReview?slug=${slug}`
  );

  const [
    { data: publicData, loading: publicLoading, error: publicError },
    loadPublicReview
  ] = usePublicAxios(`/reviews/viewReview?slug=${slug}`);

  useEffect(() => {
    if (isLoggedIn) {
      loadReview();
    } else {
      loadPublicReview();
    }
  }, [isLoggedIn, loadPublicReview, loadReview]);

  if (isLoggedIn) return { data, loading, error };
  return { data: publicData, loading: publicLoading, error: publicError };
}
