import { useEffect } from "react";

import { useAuthedAxios, usePublicAxios } from "../../../../util/axios";

export function useWorkshop(isLoggedIn, id) {
  const [{ data, loading, error }, loadWorkshop] = useAuthedAxios(
    `/workshops/viewWorkshop/${id}`,
    {
      manual: true
    }
  );

  const [
    { data: publicData, loading: publicLoading, error: publicError },
    loadPublicWorkshop
  ] = usePublicAxios(`/workshops/viewWorkshop/${id}`, {
    manual: true
  });

  useEffect(() => {
    if (isLoggedIn) {
      loadWorkshop();
    } else {
      loadPublicWorkshop();
    }
  }, [isLoggedIn, loadPublicWorkshop, loadWorkshop]);

  if (isLoggedIn) return { data, loading, error };
  return { data: publicData, loading: publicLoading, error: publicError };
}
