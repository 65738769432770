import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { loadCurrentUser } from "./containers/Auth/auth.slice";
import AppRoutes from "./app.routes";
import { authedAxios } from "./util/axios";
import GoogleAnalyticsTracker from "./util/GoogleAnalyticsTracker";

function App() {
  // Load current user

  const dispatch = useDispatch();
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  useEffect(() => {
    const loadUser = async () => {
      try {
        const res = await authedAxios.get(`/auth/members/me`);
        dispatch(loadCurrentUser(res.data));
        setIsUserLoaded(true);
      } catch (e) {
        localStorage.removeItem("member_token");
        authedAxios.post("/logout").finally(() => setIsUserLoaded(true));
      }
    };

    loadUser();
  }, [dispatch]);

  return (
    <>
      <GoogleAnalyticsTracker />
      {isUserLoaded ? (
        <AppRoutes />
      ) : (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}
    </>
  );
}

export default App;
