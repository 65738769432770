import { Typography } from "@material-ui/core";
import React from "react";
import { urlify } from "../../../util";

function PlainTextRenderer({ id, settings }) {
  return (
    <>
      {settings.text.split("\n").map((t, i) => (
        <Typography key={i} variant={settings.variant}>
          <span dangerouslySetInnerHTML={{ __html: urlify(t) }}></span>
        </Typography>
      ))}
    </>
  );
}

export default PlainTextRenderer;
