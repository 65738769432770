import { createSlice } from "@reduxjs/toolkit";

const rewardsSlice = createSlice({
  name: "rewards",
  initialState: {
    claimedRewards: [],
    allRewards: [],
    error: null,
    clickedVoucherData: null,
    claimedVoucherModalOpen: false,
    congratsVoucherModalOpen: false,
    rewardVoucherModalOpen: false,
    sorryVoucherModalOpen: false
  },
  reducers: {
    getClaimedRewards: (state, action) => {
      state.claimedRewards = action.payload.data;
    },
    getAllRewards: (state, action) => {
      state.allRewards = action.payload.data;
    },
    setClickedVoucher: (state, action) => {
      state.clickedVoucherData = action.payload.voucher;
    },
    setClaimedVoucherOpen: (state, action) => {
      state.claimedVoucherModalOpen = action.payload.open;
    },
    setCongratsVoucherOpen: (state, action) => {
      state.congratsVoucherModalOpen = action.payload.open;
    },
    setRewardVoucherOpen: (state, action) => {
      state.rewardVoucherModalOpen = action.payload.open;
    },
    setSorryVoucherOpen: (state, action) => {
      state.sorryVoucherModalOpen = action.payload.open;
    }
  }
});

export const {
  getClaimedRewards,
  getAllRewards,
  setClickedVoucher,
  setClaimedVoucherOpen,
  setSorryVoucherOpen,
  setRewardVoucherOpen,
  setCongratsVoucherOpen
} = rewardsSlice.actions;

export default rewardsSlice.reducer;
