import {
  CircularProgress,
  Paper,
  Typography,
  makeStyles,
  Grid
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useAxios from "axios-hooks";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { ASME_SITE_BASE_URL } from "../../../../util/axios";
import "../../styles/event-content.css";
import { FaRegClock, FaMapMarkerAlt } from "react-icons/fa";
import moment from "moment";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles({
  memberContainer: {
    paddingBottom: "50px"
  },
  overview: {
    listStyleType: "disc !important",
    fontSize: "14px",
    color: "rgba(61, 57, 53, 0.48)",
    padding: "10px"
  },
  articleDetails: {
    display: "flex!important",
    padding: "20px 0px"
  },
  articleAuthor: {
    color: "#C8102E !important",
    paddingRight: "10px"
  },
  articleDate: {
    color: "rgba(0, 46, 93, 0.48) !important",
    paddingLeft: "10px"
  },
  articleImg: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "900px !important",
    textAlign: "center !important"
  },
  blockQuote: {
    background: "#f9f9f9",
    borderLeft: "10px solid #ccc",
    margin: "1.5em 0px",
    padding: "0.5em 20px !important",
    maxWidth: "100%"
  },
  articleSection: {
    listStyleType: "disc !important",
    padding: "5px 0px!important"
  },
  img: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "900px !important"
  },
  articleImgDesc: {
    // textAlign: 'center !important',
    margin: "10px",
    color: "#9c9c9c"
  }
});
function EventContent() {
  const classes = useStyles();
  const { id } = useParams();
  const [{ data, loading, error }, getNews] = useAxios(
    {
      url: `${ASME_SITE_BASE_URL}/events-v-2-s`,
      method: "GET"
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    if (id) {
      getNews({
        url: `${ASME_SITE_BASE_URL}/events-v-2-s/${id}`
      });
    }
  }, [getNews, id]);

  return (
    <>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Events
      </Typography>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        {loading && (
          <div style={{ padding: "20px 0", textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </div>
        )}
        {!loading && error && (
          <Alert severity="error">
            There was some internal server error while fetching the resource.
            Please try again later!
          </Alert>
        )}

        {!loading && !data && <Alert severity="info">No Event found!</Alert>}

        {data && (
          <Grid className={classes.memberContainer}>
            <Grid item>
              <h1>{data.eventname}</h1>
              {data.Eventtype && (
                <div className={classes.overview}>
                  <Chip label={data.Eventtype} />
                </div>
              )}
              {data.date && (
                <Typography variant="body2" component="p">
                  <FaRegClock /> &nbsp;&nbsp;{" "}
                  {moment(data.date, "YYYY-MM-DD").format("MMMM DD, Y")}
                </Typography>
              )}
              {data.address && (
                <Typography variant="body2" component="p">
                  <FaMapMarkerAlt />
                  &nbsp;&nbsp;&nbsp; {data.address}
                </Typography>
              )}

              {data.eventTileDescription && (
                <div className={classes.overview}>
                  <ReactMarkdown>{data.eventTileDescription}</ReactMarkdown>
                </div>
              )}
              <div className={classes.articleDetails}>
                <div className={classes.articleDate}>
                  {" "}
                  Posted on {data.date}
                </div>
              </div>
              <div className={classes.articleImg}>
                {data.tileImage[0].url && (
                  <img
                    src={`https://admin.asme.org.sg/${data.tileImage[0].url}`}
                    alt="Main"
                    className={classes.img}
                  />
                )}
                {data.imageCaption && (
                  <div className={classes.articleImgDesc}>
                    <blockquote className={classes.blockQuote}>
                      <ReactMarkdown source={data.imageCaption}>
                        {data.imageCaption}
                      </ReactMarkdown>{" "}
                    </blockquote>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item md={9} className="article-section">
              <ReactMarkdown>
                {data.ParagraphFirst
                  ? data.ParagraphFirst.replaceAll(
                      "](/",
                      "](https://admin.asme.org.sg/"
                    )
                  : null}
              </ReactMarkdown>
              <br />
              <ReactMarkdown>
                {data.ParagraphSecond
                  ? data.ParagraphSecond.replaceAll(
                      "](/",
                      "](https://admin.asme.org.sg/"
                    )
                  : null}
              </ReactMarkdown>
              <br />
              <ReactMarkdown>
                {data.ParagraphThird
                  ? data.ParagraphThird.replaceAll(
                      "](/",
                      "](https://admin.asme.org.sg/"
                    )
                  : null}
              </ReactMarkdown>
              {data.disclamier && (
                <div>
                  <em>{data.disclamier}</em>
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </Paper>
    </>
  );
}

export default EventContent;
