import React from "react";

import { BASE_URL } from "./axios";

const RichTextRenderer = ({ content }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        // eslint-disable-next-line no-useless-escape
        __html: content?.replaceAll('src="', `src=\"${BASE_URL}`)
      }}
    />
  );
};

export default RichTextRenderer;
