import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { authedAxios } from "./axios";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export function ProtectedRoute({ children, ...rest }) {
  const classes = useStyles();

  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    verifyUser();
  }, []);

  const verifyUser = async () => {
    try {
      await authedAxios.get("/auth/members/me");
      setIsAuthenticated(true);
    } catch (e) {
      setIsAuthenticated(false);
    }
  };

  if (isAuthenticated === null) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="secondary" />
      </Backdrop>
    );
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
