import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Paper, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Alert, AlertTitle } from "@material-ui/lab";

import { BASE_URL, useAuthedAxios } from "../../../../util/axios";
import "../../styles/special-promotion.css";

function SpecialPromotion() {
  const { id } = useParams();

  const [{ data, error: promotionContentError }] = useAuthedAxios(
    `/special-promotions/${id}`
  );

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Special Promotion
      </Typography>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        {data && (
          <div
            dangerouslySetInnerHTML={{
              __html: data?.content?.replace(
                "/uploads/",
                `${BASE_URL}/uploads/`
              )
            }}
            className="ck-content"
          />
        )}
        {!data && !promotionContentError && (
          <>
            {new Array(10).fill("").map((_, index) => (
              <Skeleton key={index} animation="wave" />
            ))}
          </>
        )}
        {promotionContentError && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            This is an error while loading the content. Please try reloading the
            page!
          </Alert>
        )}
      </Paper>
    </div>
  );
}

export default SpecialPromotion;
