import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";

import "../../styles/styles.css";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
    margin: "10px 5px",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "6px"
  },
  moreBtn: {
    width: "100%",
    textTransform: "none",
    fontWeight: "bold",
    color: "#2d5072",
    textDecorationStyle: "unset"
  },
  eventTitle: {
    fontSize: "1.3em",
    fontWeight: "bolder"
  },
  desc: {
    fontSize: "15px",
    fontWeight: "500"
  },
  contentText: {},
  iconColor: {
    color: "#03C0C1"
  },
  ImageClass: {
    position: "relative"
  },
  ImageAltClass: {
    backgroundColor: "#de4647",
    color: "white",
    right: 0,
    top: 0,
    borderBottomLeftRadius: "12px",
    position: "absolute",
    padding: "7px"
  }
});

export default function ReviewCard({ data, url }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div className={classes.ImageClass}>
        <CardMedia
          component="img"
          alt="Review"
          height="200"
          image={url + data?.image?.url}
          title="Review"
          style={{ objectFit: "fill" }}
        />
        {/* {data.Eventtype && (
          <span className={classes.ImageAltClass}>
            &nbsp;&nbsp; {data.Eventtype} &nbsp;&nbsp;
          </span>
        )} */}
      </div>
      <CardContent
        style={{
          height: "inherit"
        }}
      >
        <Typography
          gutterBottom
          variant="h6"
          className={classes.eventTitle}
          component="h6"
        >
          {data?.title}
        </Typography>
        <Typography
          variant="body2"
          className={classes.desc}
          dangerouslySetInnerHTML={{
            __html: data?.description
          }}
        ></Typography>
      </CardContent>
      <Box
        style={{
          paddingBottom: "5%",
          paddingLeft: "5%",
          paddingRight: "5%"
        }}
      >
        <Link to={`/review/${data?.slug}`} style={{ textDecoration: "none" }}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.moreBtn}
          >
            Read More
          </Button>
        </Link>
      </Box>
    </Card>
  );
}
