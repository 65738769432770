import React, { useState } from "react";
import { IconButton, Modal } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import RewardCard from "../reward-card";
import { setClickedVoucher, setRewardVoucherOpen } from "../rewards.slice";
import { useDispatch, useSelector } from "react-redux";

function getModalStyle() {
  const top = 25;
  // const left = 50;

  return {
    top: `${top}%`,
    margin: "auto",
    // left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
    outline: "none"
  };
}

const RewardModal = () => {
  const dispatch = useDispatch();
  const [modalStyle] = React.useState(getModalStyle);

  const clickedVoucherData = useSelector(
    (state) => state.rewards.clickedVoucherData
  );
  const isModalOpen = useSelector(
    (state) => state.rewards.rewardVoucherModalOpen
  );

  const handleCloseModal = () => {
    dispatch(setRewardVoucherOpen({ open: false }));
    dispatch(setClickedVoucher({ voucher: null }));
  };

  if (!clickedVoucherData) return null;

  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        disableBackdropClick
      >
        <div
          // className={classes.modalPaper}
          style={modalStyle}
        >
          {
            <>
              <div style={{ float: "right" }}>
                <IconButton
                  style={{ left: "-45px", zIndex: 9, color: "#fff" }}
                  onClick={handleCloseModal}
                >
                  <Close />
                </IconButton>
              </div>
              <RewardCard isModal={true} data={clickedVoucherData} />
            </>
          }
        </div>
      </Modal>
    </div>
  );
};

export default RewardModal;
