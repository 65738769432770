import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useRouteMatch } from "react-router-dom";

import Registration from "./Register/register";
import Login from "./Login/login";
import NewAccount from "./Register/new-account";
import ForgotPassword from "./ForgotPassword/forgot-password";
import ResetPassword from "./ResetPassword/reset-password";
import RegistrationLite from "./Register/lite-register";

import "./auth.css";

function AuthContainer() {
  const { path } = useRouteMatch();

  const isLoggedIn = useSelector((state) => state.auth.user);

  if (isLoggedIn) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <Route exact path={`${path}`}>
        <Redirect to={`${path}/login`} />
      </Route>
      <Route exact path={`${path}/login`}>
        <Login />
      </Route>
      <Route exact path={`${path}/register`}>
        <Registration />
      </Route>
      <Route exact path={`${path}/register-lite`}>
        <RegistrationLite />
      </Route>
      <Route exact path={`${path}/forgot-password`}>
        <ForgotPassword />
      </Route>
      <Route exact path={`${path}/reset-password`}>
        <ResetPassword />
      </Route>
      <Route exact path={`${path}/account/update/:token`}>
        <NewAccount />
      </Route>
    </>
  );
}

export default AuthContainer;
