import React from "react";
import { Paper, Typography } from "@material-ui/core";

import FormRenderer from "../../../Forms/Renderer/renderer";
import "../../../Forms/styles/renderer.css";

function Polls({ data }) {
  const { form, id, title, allow_multiple_select } = data.poll[0];

  return (
    <Paper
      elevation={3}
      style={{ paddingLeft: "1rem", paddingBottom: "0.5rem", flex: 1 }}
    >
      {/* <Typography
        variant="h4"
        color="secondary"
        style={{ paddingBottom: "1rem", paddingTop: "1rem" }}
      >
        ASME Polls
      </Typography> */}
      {/* <hr
        style={{
          marginLeft: "2%",
          marginRight: "10%"
        }}
      /> */}
      <h4 style={{ paddingTop: "1rem" }}>{title}</h4>
      {form && (
        <FormRenderer
          form={form}
          id={id}
          isPoll={true}
          allowMultipleSelect={allow_multiple_select}
        />
      )}
    </Paper>
  );
}

export default Polls;
