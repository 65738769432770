import React, { useState } from "react";
import {
  CircularProgress,
  Grid,
  makeStyles,
  Button,
  Typography
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { FaTimesCircle } from "react-icons/fa";

import { useAuthedAxios } from "../../../../util/axios";
import WorkshopCard from "./workshop-card";

const useStyles = makeStyles({
  pagination_container: {
    justifyContent: "center",
    marginTop: "10px"
  },
  filter: {
    marginBottom: "50px"
  }
});

const Workshops = () => {
  const classes = useStyles();
  const [filter, setFilter] = useState("");

  const [{ data, loading, error }] = useAuthedAxios(`/workshops`);

  const selectFilter = (value) => {
    window.scrollTo(0, 0);
    setFilter(value);
  };

  const clearFilter = () => {
    setFilter("");
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Workshops
      </Typography>
      <div style={{ marginTop: "20px" }}>
        {loading && (
          <div style={{ padding: "20px 0", textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </div>
        )}
        {!loading && error && (
          <Alert severity="error">
            There was some internal server error while fetching the workshops.
            Please try again later!
          </Alert>
        )}
        {!loading && data && data.length === 0 && (
          <Alert severity="info">No Workshop found!</Alert>
        )}
        {!loading && data && data.length > 0 && (
          <>
            {filter && (
              <Grid container className={classes.filter}>
                <Button variant="outlined" size="small" color="secondary">
                  {filter}{" "}
                  <FaTimesCircle
                    onClick={(e) => clearFilter()}
                    style={{ marginLeft: "0.5rem" }}
                  ></FaTimesCircle>
                </Button>
              </Grid>
            )}
            <Grid container spacing={6}>
              {data.map((workshop) => (
                <Grid item md={4} xs={12} sm={12} key={workshop.id}>
                  <WorkshopCard data={workshop} selectFilter={selectFilter} />
                </Grid>
              ))}
            </Grid>
            {/* {!filter && (
              <Grid container className={classes.pagination_container}>
                <Pagination
                  count={count > PAGE_SIZE ? Math.round(count / PAGE_SIZE) : 0}
                  color="secondary"
                  onChange={onPageChange}
                  page={currentPage}
                />
              </Grid>
            )} */}
          </>
        )}
      </div>
    </div>
  );
};

export default Workshops;
