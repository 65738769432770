import { Button, Grid, Link } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

function Welcome({ resetLink }) {
  const history = useHistory();
  return (
    <div style={{ textAlign: "center" }}>
      <img src="/images/logo.png" alt="ASME Logo" width="100px" />
      <h2>Application Successful!</h2>
      <br />
      <p>
        Thank you for your application for <b>ASME Membership!</b>
      </p>
      <p>An email had been sent to you to activate your membership account.</p>
      <p>Please check your INBOX or SPAM mails.</p>
      <p>
        if you do not receive the email, kindly click{" "}
        <Link href={resetLink} target="_blank" color="secondary">
          here
        </Link>{" "}
        to initiate a re-send.
      </p>
      <br />

      <Grid style={{ marginTop: "20px" }}>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          style={{ width: "300px" }}
          onClick={() => history.push("/auth/login")}
        >
          Login
        </Button>
      </Grid>
    </div>
  );
}

export default Welcome;
