import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAuthedAxios } from "../../util/axios";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 5,
    color: "#fff",
  },
}));

function ReferCompanyDialog({ open = false, onClose = () => null }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, register, errors, reset } = useForm();

  const [{ loading, data, error }, sendInvite] = useAuthedAxios(
    {
      url: "/referrals/invite",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (error) {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 400)
      ) {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(
          "There was some internal server error while sending invite. Please try again later.",
          {
            variant: "error",
            autoHideDuration: 2000,
          }
        );
      }
    }
  }, [enqueueSnackbar, error]);

  useEffect(() => {
    if (data) {
      onClose();
      reset();
      enqueueSnackbar("Referral invite has been sent successfully!", {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
  }, [data]);

  const onSendInvite = (values) => {
    sendInvite({
      data: {
        email: values.email,
      },
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableBackdropClick
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Refer Another Company</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To refer a company, please enter the company's email and click{" "}
            <b>Send Invite</b> button.
          </DialogContentText>
          <TextField
            autoFocus
            inputRef={register({
              required: "An email address is required!",
              pattern: {
                value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: "Please enter a valid email address!",
              },
            })}
            margin="dense"
            id="email"
            name="email"
            label="Email Address"
            type="email"
            error={errors.email && errors.email.message ? true : false}
            helperText={
              errors.email && errors.email.message ? errors.email.message : null
            }
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="default">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(onSendInvite)}
            variant="contained"
            color="secondary"
          >
            Send Invite
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </>
  );
}

export default ReferCompanyDialog;
