import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, makeStyles, Button } from "@material-ui/core";
import useAxios from "axios-hooks";
import { Alert, Pagination } from "@material-ui/lab";
import { FaTimesCircle } from "react-icons/fa";

import { ASME_SITE_BASE_URL } from "../../../../util/axios";
import ResourceCard from "./resource-card";

const PAGE_SIZE = 12;

const useStyles = makeStyles({
  pagination_container: {
    justifyContent: "center",
    marginTop: "10px"
  },
  filter: {
    marginBottom: "50px"
  }
});

function ResourcesContent() {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [start, setStart] = useState(currentPage - 1);

  const [{ data, loading, error }, getNews] = useAxios(
    {
      url: filter
        ? `${ASME_SITE_BASE_URL}/newsrooms?_sort=date:DESC&type=${filter}`
        : `${ASME_SITE_BASE_URL}/newsrooms?_sort=date:DESC&_limit=${PAGE_SIZE}&_start=${start}`,
      method: "GET"
    },
    {
      manual: true
    }
  );

  const [{ loading: countLoading, error: countError, data: count }, getCount] =
    useAxios(
      {
        url: `${ASME_SITE_BASE_URL}/newsrooms/count`,
        method: "GET"
      },
      {
        manual: true
      }
    );

  useEffect(() => {
    getNews();
    getCount();
  }, []);

  useEffect(() => {
    getNews();
    getCount();
  }, [currentPage]);

  useEffect(() => {
    getNews();
  }, [filter]);

  const onPageChange = (_, page) => {
    const startCount = (page - 1) * PAGE_SIZE;
    setStart(startCount);
    setCurrentPage(page);
  };

  const selectFilter = (value) => {
    window.scrollTo(0, 0);
    setFilter(value);
  };

  const clearFilter = () => {
    setFilter("");
    getNews();
    window.scrollTo(0, 0);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      {(loading || countLoading) && (
        <div style={{ padding: "20px 0", textAlign: "center" }}>
          <CircularProgress color="secondary" />
        </div>
      )}
      {!loading && (error || countError) && (
        <Alert severity="error">
          There was some internal server error while fetching the resources.
          Please try again later!
        </Alert>
      )}
      {!loading && data && data.length === 0 && (
        <Alert severity="info">No News found!</Alert>
      )}
      {!loading && data && data.length > 0 && (
        <>
          {filter && (
            <Grid container className={classes.filter}>
              <Button variant="outlined" size="small" color="secondary">
                {filter}{" "}
                <FaTimesCircle
                  onClick={(e) => clearFilter()}
                  style={{ marginLeft: "0.5rem" }}
                ></FaTimesCircle>
              </Button>
            </Grid>
          )}
          <Grid container spacing={6}>
            {data.map((resource) => (
              <Grid item xl={3} lg={3} md={3} xs={12} sm={12} key={resource.id}>
                <ResourceCard resource={resource} selectFilter={selectFilter} />
              </Grid>
            ))}
          </Grid>
          {!filter && (
            <Grid container className={classes.pagination_container}>
              <Pagination
                count={count > PAGE_SIZE ? Math.round(count / PAGE_SIZE) : 0}
                color="secondary"
                onChange={onPageChange}
                page={currentPage}
              />
            </Grid>
          )}
        </>
      )}
    </div>
  );
}

export default ResourcesContent;
