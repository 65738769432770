import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { FaRegClock } from "react-icons/fa";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  moreBtn: {
    width: "100%",
    textTransform: "none",
    fontWeight: "bold",
    color: "#2d5072",
    textDecorationStyle: "unset"
  },
  eventTitle: {
    fontSize: "1.1em",
    fontWeight: "bolder"
  },
  contentText: {},
  iconColor: {
    color: "#002e5d"
  },
  ImageClass: {
    position: "relative"
  },
  ImageAltClass: {
    backgroundColor: "#002e5d",
    color: "white",
    right: 0,
    top: 0,
    borderBottomLeftRadius: "12px",
    position: "absolute",
    padding: "7px"
  }
});

export default function HighlightCard({ title, date, image, link, type }) {
  const classes = useStyles();
  const history = useHistory();

  const openLink = () => {
    history.push(link);
  };

  return (
    <Card className={classes.root}>
      <div className={classes.ImageClass}>
        <CardMedia
          component="img"
          alt="highlights"
          height="200"
          image={image}
          title="highlights"
        />
        {type && (
          <span className={classes.ImageAltClass}>
            &nbsp;&nbsp; {type} &nbsp;&nbsp;
          </span>
        )}
      </div>
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          className={classes.eventTitle}
          component="h6"
        >
          {title.slice(0, 35)}
          {title.length > 35 && "..."}
        </Typography>
        <Typography variant="body2" component="p">
          <FaRegClock className={classes.iconColor} /> &nbsp;&nbsp;{" "}
          {moment(date, "YYYY-MM-DD").format("MMMM DD, Y")}
        </Typography>
      </CardContent>
      <Box
        style={{
          paddingBottom: "5%",
          paddingLeft: "5%",
          paddingRight: "5%"
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          className={classes.moreBtn}
          onClick={openLink}
        >
          More Details
        </Button>
      </Box>
    </Card>
  );
}
