import React, { useState } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  CircularProgress,
  Modal,
  IconButton,
  Snackbar,
  Button
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import { useAuthedAxios } from "../../../util/axios";
import { useSelector } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
import EditOrganizationInfo from "./EditOrganizationInfo/EditOrganizationInfo";
import { Close } from "@material-ui/icons";
import OrgInfo from "./OrganizationDetails/OrgInfo";
import MemberInfo from "./OrganizationDetails/MembersInfo";
import Contact from "./OrganizationDetails/Contact";
import { useHistory, useLocation } from "react-router";
import ReferCompanyDialog from "../../../components/ReferCompanyDialog/ReferCompanyDialog";
import ReferralsInfo from "./OrganizationDetails/Referrals";

const useStyles = makeStyles((theme) => ({
  infoCellStyle: {
    backgroundColor: "#002E5D",
    verticalAlign: "top",
    color: "white",
    width: "220px"
  },
  infoCellLeftMargin: {
    marginLeft: "10px"
  },
  avtarOuter: {
    border: "1px solid #ededed",
    borderRadius: "5px",
    width: "150px",
    height: "170px",
    position: "relative"
  },
  avtarDiv: {
    textAlign: "end",
    position: "absolute",
    right: "10px",
    top: "10px"
  },
  paper: {
    position: "absolute",
    width: "80%",
    height: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    border: "none"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

function getModalStyle() {
  const top = 5;
  // const left = 50;

  return {
    top: `${top}%`,
    margin: "auto",
    // left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
    outline: "none"
  };
}

function OrganisationProfile() {
  const classes = useStyles();
  const history = useHistory();
  const { search } = useLocation();

  const isModalVisible = search && search === "?edit" ? true : false;

  const currentMember = useSelector((state) => state.auth.user.member);

  const [modalStyle] = useState(getModalStyle);
  const [hasData, setHasData] = useState(false);

  const [showReferCompanyDialog, setShowReferCompanyDialog] = useState(false);

  const organizationID = currentMember.organization;

  const [{ loading, data: organization, error }, refetch] = useAuthedAxios(
    `/organizations/${organizationID}`
  );

  const [
    { loading: loadingReferrals, data: referralsCount, error: referralsError }
  ] = useAuthedAxios("/referrals/invited");

  const handleClose = () => {
    history.push({
      pathname: "/dashboard/organisation",
      search: ""
    });
  };

  const onImageUpdateSuccess = () => {
    refetch();
  };

  const onDataUpdateSuccess = () => {
    refetch();
    setHasData(true);
    handleClose();
  };

  const onEditOrgInformation = () => {
    history.push({
      pathname: "/dashboard/organisation",
      search: "?edit"
    });
  };

  const onReferCompany = () => {
    setShowReferCompanyDialog(true);
  };

  return (
    <>
      {(loading || loadingReferrals) && (
        <div style={{ textAlign: "center", padding: "50px" }}>
          <CircularProgress color="secondary" />
        </div>
      )}
      {!loading && !loadingReferrals && organization && referralsCount && (
        <>
          <Grid container spacing={2}>
            <Grid lg={6} md={6} sm={6} item>
              <Typography
                variant="h6"
                color={"secondary"}
                style={{ marginBottom: "20px" }}
              >
                <b>
                  {organization.name}{" "}
                  {organization.company_registration_no ? (
                    <span>({organization.company_registration_no})</span>
                  ) : (
                    ""
                  )}
                </b>
              </Typography>
            </Grid>
            <Grid lg={6} md={6} sm={6} item>
              <Button
                variant="contained"
                color="secondary"
                style={{ float: "right" }}
                startIcon={<AddIcon />}
                onClick={onReferCompany}
                size="small"
              >
                Refer Another Company
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid lg={6} item>
              <Typography variant="subtitle1" color={"secondary"}>
                Organisation Information{" "}
                <EditIcon
                  fontSize="inherit"
                  onClick={onEditOrgInformation}
                  style={{
                    cursor: "pointer"
                  }}
                />
              </Typography>
              <br />
              <OrgInfo classes={classes} organization={organization} />
            </Grid>
            <Grid lg={4} item>
              <Typography variant="subtitle1" color={"secondary"}>
                Membership Information
              </Typography>
              <br />
              <MemberInfo classes={classes} organization={organization} />
              <br />
              <Typography variant="subtitle1" color={"secondary"}>
                Referrals Information
              </Typography>
              <br />
              <ReferralsInfo classes={classes} referrals={referralsCount} />
            </Grid>
            <Grid lg={2} item>
              <Contact
                classes={classes}
                members={organization.members}
                organization={organization}
                onImageUpdateSuccess={onImageUpdateSuccess}
                refetch={refetch}
              />
            </Grid>
          </Grid>

          {/* Popup Model */}
          <Modal
            open={isModalVisible}
            onClose={handleClose}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
            disableBackdropClick
          >
            <div className={classes.paper} style={modalStyle}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                <Typography
                  variant="h6"
                  color={"secondary"}
                  style={{ marginBottom: "20px" }}
                >
                  <b>Edit Organisation Info</b>
                </Typography>
                <div style={{ marginTop: "-8px" }}>
                  <IconButton onClick={handleClose}>
                    <Close />
                  </IconButton>
                </div>
              </div>
              <EditOrganizationInfo
                data={organization}
                onDataUpdateSuccess={onDataUpdateSuccess}
              />
            </div>
          </Modal>
        </>
      )}
      {!loading && !loadingReferrals && (error || referralsError) && (
        <Alert severity="error">
          <AlertTitle>Internal Server Error!</AlertTitle>
          There was some internal server error while fetching organisation info.
          Please try reloading the page.
        </Alert>
      )}

      <Snackbar
        open={hasData}
        autoHideDuration={3000}
        onClose={() => setHasData(false)}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top"
        }}
      >
        <Alert onClose={() => setHasData(false)} severity="success">
          Data Updated Successfully!
        </Alert>
      </Snackbar>

      <ReferCompanyDialog
        open={showReferCompanyDialog}
        onClose={() => setShowReferCompanyDialog(false)}
      />
    </>
  );
}

export default OrganisationProfile;
