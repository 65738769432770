import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import moment from "moment";
import { ASME_SITE_BASE_URL } from "../../../../util/axios";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  image_container: {
    background: "white",
    padding: "10px",
    minHeight: "470px",
    cursor: "pointer"
  },
  date_conatiner: {
    marginTop: "-15px",
    marginLeft: "15px"
  },
  date_text: {
    padding: "10px",
    color: "#fff",
    background: "#002e5d",
    fontWeight: "500"
  },
  text_right: {
    textAlign: "right"
  }
});

function ResourceCard({ resource, selectFilter }) {
  const classes = useStyles();
  const history = useHistory();

  const onReadMore = (id) => {
    history.push(`/newsroom/${id}`);
  };

  return (
    <div className={classes.image_container}>
      <div onClick={() => onReadMore(resource.id)}>
        <img
          src={`${ASME_SITE_BASE_URL}${
            resource.tileImage && resource.tileImage.length > 0
              ? resource.tileImage[0].formats.thumbnail.url
              : "/uploads/Zebra_5_eba67cb019.jpeg"
          }`}
          alt="Card Banner.."
          height="250px"
          width="100%"
        />
        <div className={classes.date_conatiner}>
          <span className={classes.date_text}>
            {moment(resource.date).format("ll")}
          </span>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%"
        }}
      >
        <div
          style={{
            minHeight: "120px"
          }}
          onClick={() => onReadMore(resource.id)}
        >
          <h3>{resource.title ? resource.title : ""}</h3>
        </div>
        <div>
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            onClick={() => selectFilter(resource.type)}
          >
            {resource.type ? resource.type : ""}
          </Button>
          <br />
          <div className={classes.text_right}>
            <Button variant="text" onClick={() => onReadMore(resource.id)}>
              Read More
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResourceCard;
