import React, { useState } from "react";
// import {
//   CarouselProvider,
//   Slider,
//   Slide,
//   ButtonBack,
//   ButtonNext
// } from "pure-react-carousel";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ItemsCarousel from "react-items-carousel";

import ClaimedCard from "./claimed-card";
// import "pure-react-carousel/dist/react-carousel.es.css";
import "./carousel.css";

export default function CustomGridList({ claimedRewards }) {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;

  if (!claimedRewards.length) return "No Voucher Redeemed";

  const items = claimedRewards.map((item, index) => (
    <ClaimedCard data={item} />
  ));
  return (
    <center>
      <div style={{ padding: `0 ${chevronWidth}px`, width: "1000px" }}>
        <ItemsCarousel
          requestToChangeActive={setActiveItemIndex}
          activeItemIndex={activeItemIndex}
          numberOfCards={3}
          gutter={20}
          leftChevron={<ArrowBackIosIcon color="secondary" />}
          rightChevron={<ArrowForwardIosIcon color="secondary" />}
          outsideChevron
          chevronWidth={chevronWidth}
        >
          {items}
        </ItemsCarousel>
      </div>
    </center>
  );

  //   return (
  //     <CarouselProvider
  //       naturalSlideWidth={110}
  //       naturalSlideHeight={60}
  //       totalSlides={claimedRewards.length}
  //       visibleSlides={4}
  //       // dragEnabled={false}
  //       // infinite={true}
  //     >
  //       <Slider>
  //         {claimedRewards.map((item, index) => (
  //           <Slide className="carousel__slide" key={item.id} index={index}>
  //             <ClaimedCard data={item} />
  //           </Slide>
  //         ))}
  //       </Slider>
  //       <div
  //         style={{
  //           // display: "flex",
  //           // flexDirection: "row",
  //           // justifyContent: "space-between"
  //           position: "absolute",
  //           top: "312px"
  //           // left: "10px"
  //         }}
  //       >
  //         <ButtonBack
  //           style={{
  //             marginRight: "12px",
  //             color: "#fff",
  //             backgroundColor: "#de1736"
  //           }}
  //         >
  //           ❮
  //         </ButtonBack>
  //         <ButtonNext
  //           style={{
  //             marginLeft: "1200px",
  //             color: "#fff",
  //             backgroundColor: "#de1736"
  //           }}
  //         >
  //           ❯
  //         </ButtonNext>
  //       </div>
  //     </CarouselProvider>
  //   );
}
