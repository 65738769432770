import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Tag from "./tag-ui";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../../util/axios";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    height: "100%",
    maxWidth: 450,
    borderRadius: "0px",
    boxShadow: "0 0 15px rgba(0,0,0,.2)"
  },
  content: { flex: 1 },
  media: {
    height: 155
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 15px"
  },
  button: {
    backgroundColor: "#002e5d",
    color: "white",
    borderRadius: "0px",
    "&:hover": {
      backgroundColor: "#002e5d"
    }
  }
});

export default function RewardCard({ disabled, data }) {
  const classes = useStyles();

  return (
    <Card className={classes.root} style={{ opacity: disabled ? 0.7 : 1 }}>
      <CardMedia
        className={classes.media}
        image={
          data?.thumbnail
            ? `${BASE_URL}${data?.thumbnail?.url}`
            : "/images/reward.jpg"
        }
        title="Voucher"
      />
      <CardContent className={classes.content}>
        <Tag>{data?.tag}</Tag>

        <Typography gutterBottom variant="h6" component="h4">
          {data?.title}
        </Typography>

        <Typography variant="subtitle2" component="p">
          {moment(data?.date).format("ddd, Do MMM 'YY")}
        </Typography>

        <Typography variant="subtitle2" component="p">
          {data?.location}
        </Typography>
      </CardContent>

      <CardActions className={classes.buttonDiv}>
        <Typography
          style={{
            fontWeight: "bold"
          }}
          variant="subtitle2"
          component="p"
        >
          S${data?.price}
        </Typography>
        <Link
          style={{
            textDecoration: "none"
          }}
          to={`/workshops/${data?.id}`}
        >
          <Button
            size="small"
            className={classes.button}
            disabled={disabled}
            variant="contained"
          >
            Sign Up Now
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
}
