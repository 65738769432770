import React from "react";
import {
    Card,
    CardMedia,
    CardContent,
    makeStyles,
    Divider
} from "@material-ui/core";
import gfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
const useStyles = makeStyles((theme) => ({
    link: {
        wordWrap: "break-word"
    },
}));

function PollContent({ pollData, metaData }) {
    const classes = useStyles();
    return (
        <>
            <ReactMarkdown
                remarkPlugins={[gfm]}
                className={classes.link}
            >
                {pollData.description}
            </ReactMarkdown>
            {
                metaData &&
                <a href={metaData.url} target="__blank" style={{ textDecoration: "none" }}>
                    <Card>
                        <CardMedia
                            component="img"
                            alt="Image"
                            height="100"
                            image={metaData.image ? metaData.image : '/images/logo.png'}
                        />
                        <CardContent style={{ backgroundColor: 'rgb(230, 230, 230)', padding: 0 }}>
                            <div>
                                {metaData.description}
                            </div>
                        </CardContent>
                    </Card>
                </a>
            }
            {pollData && pollData.description && <Divider variant="middle" />}
            <h4 style={{ marginBottom: 10 }}>{pollData.title}</h4>
        </>
    );
}

export default PollContent;
